import { Editor } from '@tinymce/tinymce-react';
import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/autoresize';
import { uploadImageForTinyMCE } from '../tinymceUploadService';

const initTinyMCE = {
  menubar: false,
  plugins: [
    'link', 'image', 'media', 'lists', 'table', 'code', 'codesample', 'autoresize'
  ],
  toolbar: 'undo redo | formatselect | fontselect | forecolor backcolor | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image media | table | hr | code codesample',
  images_upload_handler: uploadImageForTinyMCE,
  automatic_uploads: true,
  file_picker_types: 'file image media',
  content_css: '/src/styles/tinymceContent.css',
  autoresize_bottom_margin: 20,
  autoresize_overflow_padding: 20,
  resize: true,
  media_url_resolver: (data: any, resolve: Function) => {
    if (data.type === 'video' && data.url && data.url.indexOf('.mp4') !== -1) {
      const embedHtml = '<video width="300" height="150" controls><source src="' + data.url + '" type="video/mp4"></video>';
      resolve({ html: embedHtml });
    } else {
      resolve({ html: '' });
    }
  },
  fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
  font_formats: 'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n'
};

export { Editor, initTinyMCE };