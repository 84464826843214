import { storage } from './firebase';
import { ref, uploadString, getDownloadURL, listAll, deleteObject, getMetadata, uploadBytes, updateMetadata, getBlob } from 'firebase/storage';
import { GalleryImage, Folder } from './types';

const GALLERY_FOLDER = 'files/imagegallery';

const sanitizeFolderName = (name: string): string => {
  return name.toLowerCase().replace(/\s+/g, '_').replace(/[^a-z0-9_-]/g, '');
};

export const projectGalleryService = {
  async addProjectGalleryImage(image: Omit<GalleryImage, 'id'>, folderId: string | null): Promise<GalleryImage> {
    const fileName = `${Date.now()}_${image.fileName}`;
    const folderPath = folderId ? `${GALLERY_FOLDER}/${image.projectId}/${folderId}` : `${GALLERY_FOLDER}/${image.projectId}`;
    const storageRef = ref(storage, `${folderPath}/${fileName}`);
    
    await uploadString(storageRef, image.url, 'data_url');
    const downloadURL = await getDownloadURL(storageRef);

    const metadata = {
      customMetadata: {
        fileName: image.fileName,
        fileSize: image.fileSize.toString(),
        dimensions: JSON.stringify(image.dimensions),
        addedBy: image.addedBy,
        addedOn: image.addedOn,
        tags: JSON.stringify(image.tags || []),
      },
    };

    await updateMetadata(storageRef, metadata);

    return { 
      id: fileName, 
      ...image, 
      url: downloadURL, 
      fullSizeUrl: downloadURL
    };
  },

  async getProjectGalleryImages(projectId: string, folderId: string | null): Promise<GalleryImage[]> {
    const folderPath = folderId ? `${GALLERY_FOLDER}/${projectId}/${folderId}` : `${GALLERY_FOLDER}/${projectId}`;
    const listRef = ref(storage, folderPath);
    
    try {
      const res = await listAll(listRef);

      const images = await Promise.all(res.items
        .filter(item => !item.name.startsWith('.')) // Exclude .folderinfo and .foldericon
        .map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          const metadata = await getMetadata(itemRef);
          return {
            id: itemRef.name,
            url,
            fullSizeUrl: url,
            fileName: metadata.customMetadata?.fileName || '',
            fileSize: parseInt(metadata.customMetadata?.fileSize || '0'),
            dimensions: JSON.parse(metadata.customMetadata?.dimensions || '{"width":0,"height":0}'),
            addedBy: metadata.customMetadata?.addedBy || '',
            addedOn: metadata.customMetadata?.addedOn || '',
            projectId,
            tags: JSON.parse(metadata.customMetadata?.tags || '[]'),
          } as GalleryImage;
        }));

      return images;
    } catch (error) {
      console.error('Error getting project gallery images:', error);
      throw error;
    }
  },

  async checkGalleryExists(projectId: string): Promise<boolean> {
    const listRef = ref(storage, `${GALLERY_FOLDER}/${projectId}`);
    try {
      const res = await listAll(listRef);
      return res.prefixes.length > 0;
    } catch (error) {
      console.error('Error checking if gallery exists:', error);
      return false;
    }
  },

  async deleteProjectGalleryImage(projectId: string, imageId: string, folderId: string | null): Promise<void> {
    const folderPath = folderId ? `${GALLERY_FOLDER}/${projectId}/${folderId}` : `${GALLERY_FOLDER}/${projectId}`;
    const imageRef = ref(storage, `${folderPath}/${imageId}`);
    await deleteObject(imageRef);
  },

  async updateProjectGalleryImage(projectId: string, imageId: string, updates: Partial<GalleryImage>, folderId: string | null): Promise<void> {
    const folderPath = folderId ? `${GALLERY_FOLDER}/${projectId}/${folderId}` : `${GALLERY_FOLDER}/${projectId}`;
    const imageRef = ref(storage, `${folderPath}/${imageId}`);
    const currentMetadata = await getMetadata(imageRef);
    const updatedMetadata = {
      customMetadata: {
        ...currentMetadata.customMetadata,
        ...(updates.fileName && { fileName: updates.fileName }),
        ...(updates.tags && { tags: JSON.stringify(updates.tags) }),
      },
    };
    await updateMetadata(imageRef, updatedMetadata);
  },

  async getProjectGalleryTags(projectId: string): Promise<string[]> {
    const listRef = ref(storage, `${GALLERY_FOLDER}/${projectId}`);
    const res = await listAll(listRef);

    const allTags = new Set<string>();
    await Promise.all(res.items.map(async (itemRef) => {
      const metadata = await getMetadata(itemRef);
      const tags = JSON.parse(metadata.customMetadata?.tags || '[]');
      tags.forEach((tag: string) => allTags.add(tag));
    }));

    return Array.from(allTags);
  },

  async getProjectFolders(projectId: string): Promise<Folder[]> {
    const listRef = ref(storage, `${GALLERY_FOLDER}/${projectId}`);
    const res = await listAll(listRef);

    const folders = await Promise.all(res.prefixes.map(async (folderRef) => {
      const folderInfoRef = ref(storage, `${folderRef.fullPath}/.folderinfo`);
      try {
        const folderInfoBlob = await getBlob(folderInfoRef);
        const folderInfo = JSON.parse(await folderInfoBlob.text());
        if (folderInfo.isDeleted) {
          return null;
        }
        return {
          id: folderRef.name,
          name: folderInfo.name || folderRef.name,
          projectId,
          iconUrl: folderInfo.iconUrl || '',
          createdBy: folderInfo.createdBy || '',
          createdOn: folderInfo.createdOn || '',
        } as Folder;
      } catch (error) {
        console.log(`No .folderinfo found for ${folderRef.name}, using default values`);
        return {
          id: folderRef.name,
          name: folderRef.name.replace(/_/g, ' '),
          projectId,
          iconUrl: '',
          createdBy: '',
          createdOn: '',
        } as Folder;
      }
    }));

    return folders.filter((folder): folder is Folder => folder !== null);
  },

  async createFolder(projectId: string, folderName: string, createdBy: string): Promise<Folder> {
    const sanitizedFolderName = sanitizeFolderName(folderName);
    console.log(`Creating folder: ${sanitizedFolderName}`);
    const folderPath = `${GALLERY_FOLDER}/${projectId}/${sanitizedFolderName}`;
    const folderInfoRef = ref(storage, `${folderPath}/.folderinfo`);
    const folderInfo = {
      name: folderName,
      createdBy,
      createdOn: new Date().toISOString(),
      iconUrl: '',
    };
    try {
      await uploadString(folderInfoRef, JSON.stringify(folderInfo));
      console.log(`Folder ${sanitizedFolderName} created successfully`);
      return {
        id: sanitizedFolderName,
        name: folderName,
        projectId,
        iconUrl: '',
        createdBy,
        createdOn: folderInfo.createdOn,
      };
    } catch (error) {
      console.error(`Error creating folder ${sanitizedFolderName}:`, error);
      throw error;
    }
  },

  async updateFolder(projectId: string, folder: Folder): Promise<void> {
    console.log(`Updating folder: ${folder.id}`);
    const folderPath = `${GALLERY_FOLDER}/${projectId}/${folder.id}`;
    const folderInfoRef = ref(storage, `${folderPath}/.folderinfo`);
    
    try {
      const folderInfoMetadata = await getMetadata(folderInfoRef);
      const existingInfo = JSON.parse(folderInfoMetadata.customMetadata?.folderInfo || '{}');

      const updatedInfo = {
        ...existingInfo,
        name: folder.name,
        iconUrl: folder.iconUrl || '',
      };

      await uploadString(folderInfoRef, JSON.stringify(updatedInfo));
      console.log(`Folder ${folder.id} info updated successfully`);
    } catch (error) {
      console.error(`Error updating folder ${folder.id}:`, error);
      throw error;
    }
  },

  async renameFolder(projectId: string, oldFolderName: string, newFolderName: string): Promise<void> {
    console.log(`Renaming folder from ${oldFolderName} to ${newFolderName}`);
    const oldFolderPath = `${GALLERY_FOLDER}/${projectId}/${oldFolderName}`;
    const newFolderPath = `${GALLERY_FOLDER}/${projectId}/${newFolderName}`;

    const oldFolderRef = ref(storage, oldFolderPath);

    try {
      const files = await listAll(oldFolderRef);
      console.log(`Found ${files.items.length} files in the folder`);

      for (const fileRef of files.items) {
        const oldFileRef = ref(storage, fileRef.fullPath);
        const newFileRef = ref(storage, fileRef.fullPath.replace(oldFolderPath, newFolderPath));

        console.log(`Moving file from ${oldFileRef.fullPath} to ${newFileRef.fullPath}`);
        const fileBlob = await getBlob(oldFileRef);
        await uploadBytes(newFileRef, fileBlob);
        await deleteObject(oldFileRef);
      }

      // Update .folderinfo
      const oldFolderInfoRef = ref(storage, `${oldFolderPath}/.folderinfo`);
      const newFolderInfoRef = ref(storage, `${newFolderPath}/.folderinfo`);
      
      try {
        const folderInfoBlob = await getBlob(oldFolderInfoRef);
        const folderInfo = JSON.parse(await folderInfoBlob.text());
        
        folderInfo.name = newFolderName;
        await uploadString(newFolderInfoRef, JSON.stringify(folderInfo));
        await deleteObject(oldFolderInfoRef);
      } catch (error) {
        console.error('Error updating .folderinfo during rename:', error);
        // If .folderinfo doesn't exist, create a new one
        const newFolderInfo = {
          name: newFolderName,
          createdBy: 'Unknown',
          createdOn: new Date().toISOString(),
        };
        await uploadString(newFolderInfoRef, JSON.stringify(newFolderInfo));
      }

      console.log(`Folder renamed successfully from ${oldFolderName} to ${newFolderName}`);
    } catch (error) {
      console.error('Error renaming folder:', error);
      throw error;
    }
  },

  async uploadFolderIcon(projectId: string, folderId: string, file: File): Promise<string> {
    console.log(`Uploading icon for folder: ${folderId}`);
    const iconRef = ref(storage, `${GALLERY_FOLDER}/${projectId}/${folderId}/.foldericon`);
    try {
      await uploadBytes(iconRef, file);
      console.log(`Icon file uploaded successfully for folder ${folderId}`);
      const iconUrl = await getDownloadURL(iconRef);
      console.log(`Icon URL generated: ${iconUrl}`);
      
      // Update .folderinfo with new icon URL
      const folderInfoRef = ref(storage, `${GALLERY_FOLDER}/${projectId}/${folderId}/.folderinfo`);
      console.log(`Updating .folderinfo for folder ${folderId}`);
      const folderInfoBlob = await getBlob(folderInfoRef);
      const folderInfo = JSON.parse(await folderInfoBlob.text());
      
      folderInfo.iconUrl = iconUrl;
      await uploadString(folderInfoRef, JSON.stringify(folderInfo));
      console.log(`.folderinfo updated with new icon URL for folder ${folderId}`);
      
      return iconUrl;
    } catch (error) {
      console.error(`Error uploading icon for folder ${folderId}:`, error);
      throw error;
    }
  },

  async createProjectGallery(projectId: string): Promise<void> {
    const initialFolderRef = ref(storage, `${GALLERY_FOLDER}/${projectId}/.initialfolder`);
    
    try {
      // Create an empty file to initialize the gallery
      await uploadString(initialFolderRef, '');
      console.log(`Image Gallery created for project ${projectId}`);
    } catch (error) {
      console.error(`Error creating Image Gallery for project ${projectId}:`, error);
      throw error;
    }
  },

  async softDeleteFolder(projectId: string, folderId: string): Promise<void> {
    const folderPath = `${GALLERY_FOLDER}/${projectId}/${folderId}`;
    const folderInfoRef = ref(storage, `${folderPath}/.folderinfo`);
    
    try {
      let folderInfo;
      try {
        const folderInfoBlob = await getBlob(folderInfoRef);
        folderInfo = JSON.parse(await folderInfoBlob.text());
      } catch (error) {
        console.log('No existing .folderinfo found, creating new one');
        folderInfo = {};
      }
      
      folderInfo.isDeleted = true;
      folderInfo.deletedOn = new Date().toISOString();

      await uploadString(folderInfoRef, JSON.stringify(folderInfo));
      console.log(`Folder ${folderId} soft deleted successfully`);
    } catch (error) {
      console.error(`Error soft deleting folder ${folderId}:`, error);
      throw error;
    }
  },
};

export default projectGalleryService;