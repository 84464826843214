import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import userService from '../userService';

interface WhatsNewDialogProps {
  open: boolean;
  onClose: () => void;
}

const WhatsNewDialog: React.FC<WhatsNewDialogProps> = ({ open, onClose }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    if (open) {
      userService.getWhatsNewContent().then(setContent);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        What's New
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dangerouslySetInnerHTML={{ __html: content }} />
    </Dialog>
  );
};

export default WhatsNewDialog;