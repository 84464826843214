import React, { useState, useEffect, createContext, useContext } from 'react';
import { Routes, Route, Navigate, useNavigate, Link, useLocation, useSearchParams } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, Avatar, AppBar, Toolbar, Badge } from '@mui/material';
import { AccountCircle as AccountCircleIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { userService } from './userService';
import { User, Project, Role, PendingUserNotification, Notification, IssueUpdate } from './types';
import { notificationsService } from './services/notificationsService';
import { projectService } from './projectService';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import UpdateHistoryLightbox from './components/UpdateHistoryLightbox';
import { issueService } from './issueService';

import LoginPage from './LoginPage';
import IssuePage from './IssuePage';
import UserManagement from './components/UserManagement';
import RoleManagement from './components/RoleManagement';
import UserProfile from './components/UserProfile';
import ProjectSelector from './components/ProjectSelector';
import NotificationIcon from './components/NotificationIcon';
import useLocalStorage from './hooks/useLocalStorage';
import { Whatshot as WhatshotIcon } from '@mui/icons-material';
import WhatsNewDialog from './components/WhatsNewDialog';
import WhatsNewSettings from './components/WhatsNewSettings';
import NotificationCenter from './components/NotificationCenter';

interface AuthContextType {
  user: User | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({ user: null, loading: true });

export const useAuth = () => useContext(AuthContext);

const AppContent: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedProject, setSelectedProject] = useLocalStorage<Project | null>('selectedProject', null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [pendingUserNotifications, setPendingUserNotifications] = useState<PendingUserNotification[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationCenterOpen, setNotificationCenterOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [whatsNewOpen, setWhatsNewOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [projects, setProjects] = useState<Project[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedIssueId, setSelectedIssueId] = useState<string | null>(null);
  const [isGlobalChangelogOpen, setIsGlobalChangelogOpen] = useState(false);
  const [globalUpdates, setGlobalUpdates] = useState<IssueUpdate[]>([]);
  const [isGlobalChangelogLoading, setIsGlobalChangelogLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDoc = await userService.getUserByEmail(firebaseUser.email!);
          if (userDoc) {
            setUser(userDoc);
            if (userDoc.isAdmin) {
              fetchPendingUsers();
            }
          } else {
            console.error("User document not found");
            setUser(null);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
  }, [user]);

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    const projectId = searchParams.get('project');
    const issueId = searchParams.get('issue');

    if (projectId) {
      const project = projects.find(p => p.id === projectId);
      if (project) {
        setSelectedProject(project);
      }
    }

    if (issueId) {
      setSelectedIssueId(issueId);
    } else {
      setSelectedIssueId(null);
    }
  }, [searchParams, projects]);

  const sortProjects = (projects: Project[]): Project[] => {
    return projects.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
  };

  const fetchRoles = async () => {
    try {
      const fetchedRoles = await userService.getAllRoles();
      setRoles(fetchedRoles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const fetchPendingUsers = async () => {
    try {
      const pendingUsers = await userService.getPendingUsers();
      const newPendingUserNotifications: PendingUserNotification[] = pendingUsers.map(user => ({
        id: user.userId!,
        type: 'pending_approval',
        userId: user.userId!,
        timestamp: user.createdOn,
        read: false,
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email
      }));
      setPendingUserNotifications(newPendingUserNotifications);
    } catch (error) {
      console.error('Error fetching pending users:', error);
    }
  };

  const fetchNotifications = async () => {
    if (user && user.userId) {
      const fetchedNotifications = await notificationsService.getNotifications(user.userId);
      setNotifications(fetchedNotifications);
      setNotificationCount(fetchedNotifications.filter(n => n.status === 'current' && !n.isDeleted).length);
    }
  };

  const fetchProjects = async () => {
    const fetchedProjects = await projectService.getProjects();
    setProjects(sortProjects(fetchedProjects));
  };

  const handleApproveUser = async (userId: string, role: string) => {
    try {
      await userService.approveUser(userId, role);
      setPendingUserNotifications(prevNotifications => 
        prevNotifications.filter(notification => notification.userId !== userId)
      );
      fetchPendingUsers(); // Refresh the notifications after approval
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const handleNotificationRead = (notificationId: string) => {
    setPendingUserNotifications(prevNotifications =>
      prevNotifications.map(notification =>
        notification.id === notificationId ? { ...notification, read: true } : notification
      )
    );
  };

  const handleDismissNotification = async (notificationId: string) => {
    if (user && user.userId) {
      const notification = notifications.find(n => n.id === notificationId);
      if (notification && notification.status === 'dismissed') {
        await notificationsService.deleteNotification(user.userId, notificationId);
        setNotifications(prevNotifications =>
          prevNotifications.filter(n => n.id !== notificationId)
        );
      } else {
        await notificationsService.dismissNotification(user.userId, notificationId);
        setNotifications(prevNotifications =>
          prevNotifications.map(n =>
            n.id === notificationId ? { ...n, status: 'dismissed', dismissedAt: new Date().toISOString() } : n
          )
        );
        setNotificationCount(prevCount => prevCount - 1);
      }
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await userService.logout();
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error("Error logging out:", error);
    }
    handleClose();
  };

  const handleUpdateUser = async (updatedUser: User) => {
    if (user && user.userId === updatedUser.userId) {
      setUser(updatedUser);
    }
  };

  const handleProjectSelect = (projectId: string | null) => {
    if (projectId) {
      setSearchParams({ project: projectId });
    } else {
      searchParams.delete('project');
      searchParams.delete('issue');
      setSearchParams(searchParams);
    }
    setSelectedProject(projects.find(p => p.id === projectId) || null);
  };

  const handleNewProjectCreated = (project: Project) => {
    setSelectedProject(project);
  };

  const handleProjectNameUpdate = (projectId: string, newName: string) => {
    setSelectedProject(prevProject => {
      if (prevProject && prevProject.id === projectId) {
        return { ...prevProject, name: newName };
      }
      return prevProject;
    });
  };

  const handleIssueSelect = (issueId: string | null) => {
    if (issueId) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('issue', issueId);
      if (selectedProject) {
        newSearchParams.set('project', selectedProject.id);
      }
      setSearchParams(newSearchParams);
    } else {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('issue');
      setSearchParams(newSearchParams);
    }
    setSelectedIssueId(issueId);
  };

  const handleProjectArchive = (projectId: string) => {
    setProjects(prevProjects => prevProjects.filter(p => p.id !== projectId));
    if (selectedProject && selectedProject.id === projectId) {
      setSelectedProject(null);
      searchParams.delete('project');
      setSearchParams(searchParams);
    }
  };

  const handleLogoClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (selectedProject) {
      navigate(`/issues?project=${selectedProject.id}`);
    } else {
      navigate('/issues');
    }
  };

  const handleOpenGlobalChangelog = async () => {
    setIsGlobalChangelogOpen(true);
    setIsGlobalChangelogLoading(true);
    try {
      const updates = await issueService.getAllIssueUpdates();
      console.log('Fetched global updates:', updates);
      setGlobalUpdates(updates);
    } catch (error) {
      console.error("Error fetching global updates:", error);
      // Optionally, show an error message to the user
    } finally {
      setIsGlobalChangelogLoading(false);
    }
  };

  const showHeader = !['/login', '/signup'].includes(location.pathname);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, loading }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {showHeader && (
          <AppBar position="static">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Link to="/issues" onClick={handleLogoClick} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
                  <img
                    src="/yepex-logo.png"
                    alt="Yepex Logo"
                    style={{ height: '34px', marginRight: '10px' }}
                  />
                </Box>
              </Link>
              {user && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                  <IconButton
                    onClick={handleOpenGlobalChangelog}
                    sx={{ mr: 1 }}
                  >
                    <HistoryOutlinedIcon sx={{ color: 'white' }} />
                  </IconButton>
                  <ProjectSelector
                    onProjectSelect={handleProjectSelect}
                    selectedProject={selectedProject}
                    onNewProjectCreated={handleNewProjectCreated}
                    onProjectNameUpdate={handleProjectNameUpdate}
                    onProjectArchive={handleProjectArchive}
                    canEditProjectSettings={user?.permissions?.canEditProjectSettings || false}
                    projects={projects}
                  />
                </Box>
              )}
              {user && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={() => setWhatsNewOpen(true)}
                    sx={{ mr: 1 }}
                  >
                    <WhatshotIcon sx={{ 
                      color: 'orange',
                      fontSize: '28px'
                    }} />
                  </IconButton>
                  <IconButton onClick={() => setNotificationCenterOpen(true)} sx={{ mr: 1 }}>
                    <Badge badgeContent={notificationCount} color="secondary">
                      <NotificationsIcon sx={{ color: 'white' }} />
                    </Badge>
                  </IconButton>
                  {user.isAdmin && (
                    <NotificationIcon
                      pendingUserNotifications={pendingUserNotifications}
                      onNotificationRead={handleNotificationRead}
                      roles={roles}
                      onUserApprove={handleApproveUser}
                    />
                  )}
                  <Box sx={{ mr: 1 }} />
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    {user.avatarUrl ? (
                      <Avatar src={user.avatarUrl} alt={user.firstName} />
                    ) : (
                      <AccountCircleIcon />
                    )}
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => navigate('/profile')}>My Profile</MenuItem>
                    {user.isAdmin && (
                      <>
                        <MenuItem onClick={() => navigate('/users')}>Manage Users</MenuItem>
                        <MenuItem onClick={() => navigate('/roles')}>Manage Roles</MenuItem>
                        <MenuItem onClick={() => navigate('/whats-new-settings')}>What's New Settings</MenuItem>
                      </>
                    )}
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </Box>
              )}
            </Toolbar>
          </AppBar>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path="/login" element={user ? <Navigate to="/issues" replace /> : <LoginPage />} />
            <Route 
              path="/issues" 
              element={
                user ? (
                  <IssuePage 
                    selectedProject={selectedProject}
                    selectedIssueId={selectedIssueId}
                    onIssueSelect={handleIssueSelect}
                  />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route 
              path="/users" 
              element={
                user && user.isAdmin ? (
                  <UserManagement 
                    roles={roles} 
                    onClose={() => navigate('/issues')}
                    onUserUpdate={handleUpdateUser}
                    onUserApprove={handleApproveUser}
                    currentUser={user}
                  />
                ) : (
                  <Navigate to="/issues" replace />
                )
              } 
            />
            <Route 
              path="/roles" 
              element={
                user && user.isAdmin ? (
                  <RoleManagement onClose={() => navigate('/issues')} />
                ) : (
                  <Navigate to="/issues" replace />
                )
              } 
            />
            <Route 
              path="/profile" 
              element={
                user ? (
                  <UserProfile user={user} onUpdateUser={handleUpdateUser} />
                ) : (
                  <Navigate to="/login" replace />
                )
              } 
            />
            <Route path="/" element={<Navigate to="/issues" replace />} />
            <Route 
              path="/whats-new-settings" 
              element={
                user && user.isAdmin ? (
                  <WhatsNewSettings />
                ) : (
                  <Navigate to="/issues" replace />
                )
              } 
            />
          </Routes>
        </Box>
      </Box>
      <WhatsNewDialog open={whatsNewOpen} onClose={() => setWhatsNewOpen(false)} />
      <NotificationCenter
        open={notificationCenterOpen}
        onClose={() => setNotificationCenterOpen(false)}
        notifications={notifications}
        onDismiss={handleDismissNotification}
        userId={user?.userId || ''}
      />
      <UpdateHistoryLightbox
        open={isGlobalChangelogOpen}
        onClose={() => setIsGlobalChangelogOpen(false)}
        updates={globalUpdates}
        isGlobalView={true}
        projects={projects}
        selectedProject={selectedProject}
        isLoading={isGlobalChangelogLoading}
      />
    </AuthContext.Provider>
  );
};

export default AppContent;
