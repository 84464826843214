import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Editor, initTinyMCE } from '../utils/tinymceSetup';
import userService from '../userService';

const WhatsNewSettings: React.FC = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    userService.getWhatsNewContent().then(setContent);
  }, []);

  const handleEditorChange = (content: string) => {
    setContent(content);
  };

  const handleSave = async () => {
    try {
      await userService.updateWhatsNewContent(content);
      alert('What\'s New content updated successfully');
    } catch (error) {
      console.error('Error updating What\'s New content:', error);
      alert('Failed to update What\'s New content');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>What's New Settings</Typography>
      <Editor
        value={content}
        onEditorChange={handleEditorChange}
        init={initTinyMCE}
      />
      <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
        Save Changes
      </Button>
    </Box>
  );
};

export default WhatsNewSettings;