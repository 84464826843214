import { Notification } from '../types';
import { firestore } from '../firebase';
import { collection, doc, getDocs, updateDoc, addDoc, getDoc, writeBatch } from 'firebase/firestore';

export const notificationsService = {
  async getNotifications(userId: string): Promise<Notification[]> {
    const notificationsRef = collection(firestore, `users/${userId}/notifications`);
    const snapshot = await getDocs(notificationsRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Notification));
  },

  async dismissNotification(userId: string, notificationId: string): Promise<void> {
    const notificationRef = doc(firestore, `users/${userId}/notifications`, notificationId);
    await updateDoc(notificationRef, {
      status: 'dismissed',
      dismissedAt: new Date().toISOString()
    });
  },

  async addNotification(userId: string, notification: Omit<Notification, 'id'>): Promise<void> {
    const notificationsRef = collection(firestore, `users/${userId}/notifications`);
    await addDoc(notificationsRef, notification);
  },

  async createIssueUpdateNotification(
    issueId: string,
    projectId: string,
    updatedByUserId: string,
    updatedByUserName: string,
    issueTitle: string,
    updateMessage: string
  ): Promise<void> {
    const issueRef = doc(firestore, `issues/${issueId}`);
    const issueSnapshot = await getDoc(issueRef);
    
    if (!issueSnapshot.exists()) {
      throw new Error('Issue not found');
    }

    const issueData = issueSnapshot.data();
    const usersToNotify = new Set([
      issueData.createdBy,
      ...(issueData.assignedTo || []),
      ...(issueData.followers || [])
    ]);

    // Remove the user who made the update
    usersToNotify.delete(updatedByUserId);

    const batch = writeBatch(firestore);

    for (const userId of usersToNotify) {
      const notification: Omit<Notification, 'id'> = {
        issueId,
        projectId, // Ensure this is included
        issueTitle,
        message: updateMessage,
        timestamp: new Date().toISOString(),
        status: 'current',
        type: 'issue_update',
        updatedByUserId,
        updatedByUserName,
        isDeleted: false, // Add this line
        deletedAt: null // Add this line
      };

      const userNotificationsRef = collection(firestore, `users/${userId}/notifications`);
      const newNotificationRef = doc(userNotificationsRef);
      batch.set(newNotificationRef, notification);
    }

    await batch.commit();
  },

  async deleteNotification(userId: string, notificationId: string): Promise<void> {
    const notificationRef = doc(firestore, `users/${userId}/notifications`, notificationId);
    await updateDoc(notificationRef, {
      isDeleted: true,
      deletedAt: new Date().toISOString()
    });
  }
};