import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemText, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Snackbar, Alert } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import Note from './Note';
import { noteService } from '../services/noteService';
import { Note as NoteType, User } from '../types';

interface NoteManagerProps {
  projectId: string;
  currentUser: User;
}

const NoteManager: React.FC<NoteManagerProps> = ({ projectId, currentUser }) => {
  const [notes, setNotes] = useState<NoteType[]>([]);
  const [selectedNote, setSelectedNote] = useState<NoteType | null>(null);
  const [isNewNoteDialogOpen, setIsNewNoteDialogOpen] = useState(false);
  const [newNoteTitle, setNewNoteTitle] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null);
  const [editedNoteTitle, setEditedNoteTitle] = useState('');

  useEffect(() => {
    loadNotes();
  }, [projectId]);

  const loadNotes = async () => {
    const projectNotes = await noteService.getProjectNotes(projectId);
    setNotes(projectNotes);
  };

  const handleNoteSelect = (note: NoteType) => {
    setSelectedNote(note);
  };

  const handleUpdateNote = async (updatedNote: NoteType) => {
    try {
      await noteService.updateNote(updatedNote.id, updatedNote);
      setNotes(prevNotes => prevNotes.map(n => n.id === updatedNote.id ? updatedNote : n));
      showSnackbar('Note updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update note:', error);
      showSnackbar('Failed to update note. Please try again.', 'error');
    }
  };

  const handleCreateNewNote = async () => {
    if (newNoteTitle) {
      try {
        const newNote = await noteService.createNote(projectId, newNoteTitle, '', currentUser);
        setNotes([...notes, newNote]);
        setIsNewNoteDialogOpen(false);
        setNewNoteTitle('');
        handleNoteSelect(newNote);
        showSnackbar('Note created successfully', 'success');
      } catch (error) {
        console.error('Failed to create note:', error);
        showSnackbar('Failed to create note. Please try again.', 'error');
      }
    }
  };

  const handleDeleteNote = async (noteId: string) => {
    try {
      await noteService.deleteNote(noteId);
      setNotes(notes.filter(n => n.id !== noteId));
      if (selectedNote?.id === noteId) {
        setSelectedNote(null);
      }
      showSnackbar('Note deleted successfully', 'success');
    } catch (error) {
      console.error('Failed to delete note:', error);
      showSnackbar('Failed to delete note. Please try again.', 'error');
    }
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleEditNoteTitle = (noteId: string, currentTitle: string) => {
    setEditingNoteId(noteId);
    setEditedNoteTitle(currentTitle);
  };

  const handleSaveNoteTitle = async (noteId: string) => {
    if (editedNoteTitle.trim()) {
      try {
        await noteService.updateNote(noteId, { title: editedNoteTitle.trim() });
        setNotes(prevNotes => prevNotes.map(n => n.id === noteId ? { ...n, title: editedNoteTitle.trim() } : n));
        setEditingNoteId(null);
        showSnackbar('Note title updated successfully', 'success');
      } catch (error) {
        console.error('Failed to update note title:', error);
        showSnackbar('Failed to update note title. Please try again.', 'error');
      }
    }
  };

  return (
    <Box display="flex" sx={{ height: '100%', width: '100%' }}>
      <Box width={250} borderRight={1} borderColor="divider" height="100%" display="flex" flexDirection="column">
        <Box p={2}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsNewNoteDialogOpen(true)}
            sx={{ mb: 2 }}
            fullWidth
          >
            Create New Note
          </Button>
        </Box>
        <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {notes.map(note => (
            <ListItem
              button
              key={note.id}
              selected={note.id === selectedNote?.id}
              onClick={() => handleNoteSelect(note)}
              sx={{
                '&:hover .note-actions': {
                  opacity: 1,
                },
              }}
            >
              {editingNoteId === note.id ? (
                <TextField
                  value={editedNoteTitle}
                  onChange={(e) => setEditedNoteTitle(e.target.value)}
                  onBlur={() => handleSaveNoteTitle(note.id)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSaveNoteTitle(note.id);
                    }
                  }}
                  autoFocus
                  fullWidth
                />
              ) : (
                <>
                  <ListItemText primary={note.title} />
                  <Box 
                    className="note-actions" 
                    sx={{ 
                      opacity: 0, 
                      transition: 'opacity 0.2s',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditNoteTitle(note.id, note.title);
                      }}
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteNote(note.id);
                      }} 
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
      <Box flex={1} display="flex" flexDirection="column" height="100%">
        {selectedNote && (
          <Note
            note={selectedNote}
            onUpdateNote={handleUpdateNote}
          />
        )}
      </Box>
      <Dialog 
        open={isNewNoteDialogOpen} 
        onClose={() => setIsNewNoteDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Note Title"
            fullWidth
            value={newNoteTitle}
            onChange={(e) => setNewNoteTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewNoteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateNewNote}>Create</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NoteManager;