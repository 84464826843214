import React, { useState, useEffect, useCallback } from 'react';
import { userService } from '../userService';
import { User, UserUpdate, Role } from '../types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Snackbar,
  IconButton,
  Drawer,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SelectChangeEvent,
  InputAdornment,
  Avatar,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Archive as ArchiveIcon,
  Close as CloseIcon,
  LockReset as LockResetIcon,
  Search as SearchIcon,
  ArrowUpward,
  ArrowDownward,
} from '@mui/icons-material';

interface UserManagementProps {
  roles: Role[];
  onClose: () => void;
  onUserUpdate: (updatedUser: User) => Promise<void>;
  onUserApprove: (userId: string, role: string) => Promise<void>;
  currentUser: User | null;
}

const UserManagement: React.FC<UserManagementProps> = ({ roles, onClose, onUserUpdate, onUserApprove, currentUser }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [newUser, setNewUser] = useState<Omit<User, 'userId'>>({
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    status: 'Pending',
    isAdmin: false,
    newUserNotificationDismissed: false,
    permissions: {
      canEditIssues: false,
      canCreateIssues: false,
      canBeAssignedIssues: false,
      canEditProjectSettings: false,
      canViewPrivateIssues: false,
    },
    createdOn: new Date().toISOString(),
  });
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [userToArchive, setUserToArchive] = useState<User | null>(null);
  const [showArchivedUsers, setShowArchivedUsers] = useState(false);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [userToResetPassword, setUserToResetPassword] = useState<User | null>(null);
  const [newPassword, setNewPassword] = useState('');
  const [activateUserDialogOpen, setActivateUserDialogOpen] = useState(false);
  const [userToActivate, setUserToActivate] = useState<User | null>(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof User>('lastName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const fetchUsers = useCallback(async () => {
    try {
      const fetchedUsers = await userService.getAllUsers(showArchivedUsers);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
      showSnackbar('Failed to fetch users');
    }
  }, [showArchivedUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleUpdateUser = async (userId: string, userData: UserUpdate) => {
    if (!userData.firstName || !userData.lastName || !userData.role) {
      showSnackbar('Please fill in all fields, including role');
      return;
    }
    try {
      const selectedRole = roles.find(role => role.name === userData.role);
      const updatedPermissions = selectedRole ? { ...selectedRole.defaultPermissions } : {
        canEditIssues: false,
        canCreateIssues: false,
        canBeAssignedIssues: false,
        canEditProjectSettings: false,
        canViewPrivateIssues: false,
      };

      if (currentUser?.userId === userId && currentUser?.isAdmin && !userData.isAdmin) {
        const adminCount = users.filter(user => user.isAdmin).length;
        if (adminCount <= 1) {
          showSnackbar('There must be at least one administrator. Please update another user to admin in order to remove this user from being an admin');
          return;
        }
      }

      await userService.updateUserData(userId, {
        ...userData,
        permissions: updatedPermissions,
      });

      fetchUsers();
      setEditingUser(null);
      showSnackbar('User updated successfully');
      if (userId === currentUser?.userId) {
        onUserUpdate({ ...userData, userId, permissions: updatedPermissions } as User);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      showSnackbar('Failed to update user');
    }
  };

  const handleCreateUser = async () => {
    if (!newUser.email || !newUser.firstName || !newUser.lastName || !newUser.role || !password) {
      showSnackbar('Please fill in all fields, including role');
      return;
    }
    try {
      await userService.createUser(newUser, password);
      fetchUsers();
      setIsCreatingUser(false);
      setNewUser({
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        status: 'Pending',
        isAdmin: false,
        newUserNotificationDismissed: false,
        permissions: {
          canEditIssues: false,
          canCreateIssues: false,
          canBeAssignedIssues: false,
          canEditProjectSettings: false,
          canViewPrivateIssues: false,
        },
        createdOn: new Date().toISOString(),
      });
      setPassword('');
      showSnackbar('User created successfully');
    } catch (error) {
      console.error("Error creating user:", error);
      showSnackbar('Failed to create user');
    }
  };

  const handleArchiveUser = async () => {
    if (userToArchive && userToArchive.userId) {
      try {
        await userService.archiveUser(userToArchive.userId);
        fetchUsers();
        setArchiveDialogOpen(false);
        setUserToArchive(null);
        showSnackbar('User archived successfully');
      } catch (error) {
        console.error("Error archiving user:", error);
        showSnackbar('Failed to archive user');
      }
    }
  };

  const handleResetPassword = async () => {
    if (userToResetPassword && userToResetPassword.userId) {
      try {
        await userService.resetUserPassword(userToResetPassword.userId, newPassword);
        setResetPasswordDialogOpen(false);
        setUserToResetPassword(null);
        setNewPassword('');
        showSnackbar('Password reset successfully');
      } catch (error) {
        console.error("Error resetting password:", error);
        showSnackbar('Failed to reset password');
      }
    }
  };

  const showSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleActivateUser = (user: User) => {
    setUserToActivate(user);
    setSelectedRole('');
    setActivateUserDialogOpen(true);
  };

  const handleConfirmActivation = async () => {
    if (userToActivate && userToActivate.userId && selectedRole) {
      try {
        await onUserApprove(userToActivate.userId, selectedRole);
        fetchUsers();
        setActivateUserDialogOpen(false);
        setUserToActivate(null);
        setSelectedRole('');
        showSnackbar('User activated successfully');
      } catch (error) {
        console.error("Error activating user:", error);
        showSnackbar('Failed to activate user');
      }
    } else {
      showSnackbar('Please select a role before activating the user');
    }
  };

  const filteredUsers = users.filter(user =>
    user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (user.userId?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
  );

  const handleSort = (column: keyof User) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortColumn(column);
      setSortDirection('desc');
    }
  };

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];
    
    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return sortDirection === 'asc' ? -1 : 1;
    if (bValue == null) return sortDirection === 'asc' ? 1 : -1;
    
    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const SortLabel: React.FC<{ column: keyof User; label: string }> = ({ column, label }) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={() => handleSort(column)}
    >
      {label}
      {sortColumn === column && (
        sortDirection === 'desc' ? <ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />
      )}
    </Box>
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // This will format the date and time according to the user's locale
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">User Management</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      
      <FormControlLabel
        control={
          <Checkbox
            checked={showArchivedUsers}
            onChange={(e) => setShowArchivedUsers(e.target.checked)}
          />
        }
        label="Show Archived Users"
      />

      <Box display="flex" alignItems="center" mb={2}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsCreatingUser(true)}
          sx={{ marginRight: 2 }}
        >
          Add New User
        </Button>
        <TextField
          placeholder="Search users..."
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><SortLabel column="firstName" label="Avatar" /></TableCell>
              <TableCell><SortLabel column="firstName" label="Name" /></TableCell>
              <TableCell><SortLabel column="email" label="Email" /></TableCell>
              <TableCell><SortLabel column="userId" label="User ID" /></TableCell>
              <TableCell><SortLabel column="createdOn" label="Created On" /></TableCell>
              <TableCell><SortLabel column="role" label="Role" /></TableCell>
              <TableCell><SortLabel column="status" label="Status" /></TableCell>
              <TableCell><SortLabel column="isAdmin" label="Admin" /></TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers.map((user) => (
              <TableRow key={user.userId}>
                <TableCell>
                  <Avatar alt={`${user.firstName} ${user.lastName}`} src={user.avatarUrl}>
                    {user.firstName[0]}
                    {user.lastName[0]}
                  </Avatar>
                </TableCell>
                <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.userId}</TableCell>
                <TableCell>{user.createdOn ? formatDate(user.createdOn) : 'N/A'}</TableCell>
                <TableCell>{user.role || 'Not Assigned'}</TableCell>
                <TableCell>
                  <Chip 
                    label={user.status} 
                    color={user.status === 'Active' ? 'success' : user.status === 'Pending' ? 'warning' : 'default'} 
                  />
                </TableCell>
                <TableCell>{user.isAdmin ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <IconButton onClick={() => setEditingUser(user)}>
                    <EditIcon />
                  </IconButton>
                  {user.status !== 'Archived' && (
                    <IconButton onClick={() => {
                      setUserToArchive(user);
                      setArchiveDialogOpen(true);
                    }}>
                      <ArchiveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={() => {
                    setUserToResetPassword(user);
                    setResetPasswordDialogOpen(true);
                  }}>
                    <LockResetIcon />
                  </IconButton>
                  {user.status === 'Pending' && (
                    <Button onClick={() => handleActivateUser(user)}>
                      Activate
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Drawer
        anchor="right"
        open={editingUser !== null}
        onClose={() => setEditingUser(null)}
      >
        <Box sx={{ width: 400, padding: 3 }}>
          <Typography variant="h6" gutterBottom>
            Edit User
          </Typography>
          {editingUser && editingUser.userId && (
            <form onSubmit={(e) => {
              e.preventDefault();
              if (editingUser.userId) {
                const selectedRole = roles.find(role => role.name === editingUser.role);
                const updatedPermissions = selectedRole ? { ...selectedRole.defaultPermissions } : {
                  canEditIssues: false,
                  canCreateIssues: false,
                  canBeAssignedIssues: false,
                  canEditProjectSettings: false,
                  canViewPrivateIssues: false,
                };
                handleUpdateUser(editingUser.userId, {
                  firstName: editingUser.firstName,
                  lastName: editingUser.lastName,
                  role: editingUser.role,
                  status: editingUser.status,
                  isAdmin: editingUser.isAdmin,
                  permissions: updatedPermissions,
                });
              }
            }}>
              <Box sx={{ marginBottom: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">
                  User ID
                </Typography>
                <Typography variant="body1">
                  {editingUser.userId}
                </Typography>
              </Box>
              <TextField
                fullWidth
                margin="normal"
                label="First Name"
                value={editingUser.firstName}
                onChange={(e) => setEditingUser({...editingUser, firstName: e.target.value})}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Last Name"
                value={editingUser.lastName}
                onChange={(e) => setEditingUser({...editingUser, lastName: e.target.value})}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Role</InputLabel>
                <Select
                  value={editingUser.role}
                  onChange={(e: SelectChangeEvent<string>) => {
                    const selectedRole = roles.find(role => role.name === e.target.value);
                    setEditingUser({
                      ...editingUser,
                      role: e.target.value,
                      permissions: selectedRole ? { ...selectedRole.defaultPermissions } : {
                        canEditIssues: false,
                        canCreateIssues: false,
                        canBeAssignedIssues: false,
                        canEditProjectSettings: false,
                        canViewPrivateIssues: false,
                      },
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>Select a role</em>
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role.roleId} value={role.name}>{role.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  value={editingUser.status}
                  onChange={(e) => setEditingUser({...editingUser, status: e.target.value as User['status']})}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Archived">Archived</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editingUser.isAdmin}
                    onChange={(e) => setEditingUser({...editingUser, isAdmin: e.target.checked})}
                  />
                }
                label="Is Admin"
              />
              <Typography variant="subtitle1" gutterBottom>Permissions:</Typography>
              {editingUser.permissions && Object.entries(editingUser.permissions).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={value}
                      disabled={true}
                    />
                  }
                  label={key}
                />
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editingUser.permissions?.canViewPrivateIssues || false}
                    onChange={(e) => setEditingUser({
                      ...editingUser,
                      permissions: {
                        canEditIssues: editingUser.permissions?.canEditIssues || false,
                        canCreateIssues: editingUser.permissions?.canCreateIssues || false,
                        canBeAssignedIssues: editingUser.permissions?.canBeAssignedIssues || false,
                        canEditProjectSettings: editingUser.permissions?.canEditProjectSettings || false,
                        canViewPrivateIssues: e.target.checked
                      }
                    })}
                  />
                }
                label="Can view private issues"
              />
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
                Update User
              </Button>
            </form>
          )}
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={isCreatingUser}
        onClose={() => setIsCreatingUser(false)}
      >
        <Box sx={{ width: 400, padding: 3 }}>
          <Typography variant="h6" gutterBottom>
            Create New User
          </Typography>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleCreateUser();
          }}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              value={newUser.email}
              onChange={(e) => setNewUser({...newUser, email: e.target.value})}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="First Name"
              value={newUser.firstName}
              onChange={(e) => setNewUser({...newUser, firstName: e.target.value})}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Last Name"
              value={newUser.lastName}
              onChange={(e) => setNewUser({...newUser, lastName: e.target.value})}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Role</InputLabel>
              <Select
                value={newUser.role}
                onChange={(e: SelectChangeEvent<string>) => {
                  const selectedRole = roles.find(role => role.name === e.target.value);
                  setNewUser({
                    ...newUser,
                    role: e.target.value,
                    permissions: selectedRole ? { ...selectedRole.defaultPermissions } : {
                      canEditIssues: false,
                      canCreateIssues: false,
                      canBeAssignedIssues: false,
                      canEditProjectSettings: false,
                      canViewPrivateIssues: false,
                    },
                  });
                }}
              >
                <MenuItem value="">
                  <em>Select a role</em>
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role.roleId} value={role.name}>{role.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newUser.isAdmin}
                  onChange={(e) => setNewUser({...newUser, isAdmin: e.target.checked})}
                />
              }
              label="Is Admin"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
              Create User
            </Button>
          </form>
        </Box>
      </Drawer>

      <Dialog
        open={archiveDialogOpen}
        onClose={() => setArchiveDialogOpen(false)}
      >
        <DialogTitle>Confirm Archive</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will Archive and deactivate the user. Would you like to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setArchiveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleArchiveUser} color="primary">Archive</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={resetPasswordDialogOpen}
        onClose={() => setResetPasswordDialogOpen(false)}
      >
        <DialogTitle>Reset User Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a new password for {userToResetPassword?.firstName} {userToResetPassword?.lastName}:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResetPasswordDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleResetPassword} color="primary">Reset Password</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={activateUserDialogOpen}
        onClose={() => setActivateUserDialogOpen(false)}
      >
        <DialogTitle>Activate User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select a role for {userToActivate?.firstName} {userToActivate?.lastName}:
          </DialogContentText>
          <FormControl fullWidth margin="normal">
            <InputLabel>Role</InputLabel>
            <Select
              value={selectedRole}
              onChange={(e: SelectChangeEvent<string>) => setSelectedRole(e.target.value)}
            >
              <MenuItem value="">
                <em>Select a role</em>
              </MenuItem>
              {roles.map((role) => (
                <MenuItem key={role.roleId} value={role.name}>{role.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setActivateUserDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmActivation} color="primary" disabled={!selectedRole}>
            Activate User
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default UserManagement;