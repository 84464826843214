import React, { useEffect, useRef, useCallback, useState } from 'react';
import { TabulatorFull as Tabulator, CellComponent, ColumnComponent } from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';
import { TableData, SortConfig } from '../types';
import '../styles/Table.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel } from '@mui/material';

interface TableProps {
  tableData: TableData;
  onUpdateTable: (updatedData: TableData) => void;
  sortConfig: SortConfig;
}

const TableComponent: React.FC<TableProps> = ({ tableData, onUpdateTable, sortConfig }) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const tabulatorInstance = useRef<Tabulator | null>(null);
  const [isTableReady, setIsTableReady] = useState(false);
  const prevDataRef = useRef<any[]>([]);
  const [editingColumn, setEditingColumn] = useState<string | null>(null);
  const [newColumnName, setNewColumnName] = useState('');
  const [editingColumnType, setEditingColumnType] = useState<'Text' | 'Formula'>('Text');
  const [formulaInput, setFormulaInput] = useState('');
  const [allowTextWrap, setAllowTextWrap] = useState(false);

  const initializeTable = useCallback(() => {
    if (tableRef.current && !tabulatorInstance.current) {
      console.log('Initializing table with columns:', tableData.columnOrder);
      
      const columns = tableData.columnOrder.map(columnId => ({
        title: columnId,
        field: columnId,
        editor: tableData.columnTypes[columnId] === 'Formula' ? false : 'input',
        resizable: true,
        headerSort: true,
        formatter: (cell: any) => {
          if (tableData.columnTypes[columnId] === 'Formula') {
            return cell.getValue();
          }
          return tableData.columnTextWrap?.[columnId] 
            ? `<div style="white-space: normal;">${cell.getValue()}</div>`
            : cell.getValue();
        },
        width: tableData.columnWidths[columnId] || 150,
        headerHozAlign: 'left',
        cellClick: (_e: MouseEvent, _cell: CellComponent) => {
          if (tableData.columnTypes[columnId] === 'Formula') {
            const formula = tableData.columnFormulas[columnId];
            if (formula) {
              alert(`This column uses the formula: ${formula}`);
            }
          }
        },
        titleFormatter: (cell: any) => {
          const columnId = cell.getValue();
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.justifyContent = 'space-between';
          container.style.width = '100%';

          const titleSpan = document.createElement('span');
          titleSpan.textContent = columnId;
          container.appendChild(titleSpan);

          const editButton = document.createElement('button');
          editButton.innerHTML = '✎';
          editButton.style.visibility = 'hidden';
          editButton.style.border = 'none';
          editButton.style.background = 'none';
          editButton.style.cursor = 'pointer';
          editButton.onclick = (e: MouseEvent) => {
            e.stopPropagation();
            setEditingColumn(columnId);
            setNewColumnName(columnId);
            setEditingColumnType(tableData.columnTypes?.[columnId] || 'Text');
            setFormulaInput(typeof tableData.columnFormulas?.[columnId] === 'string' 
              ? tableData.columnFormulas[columnId] as string 
              : '');
            setAllowTextWrap(!!tableData.columnTextWrap?.[columnId]);
          };

          container.appendChild(editButton);

          container.onmouseenter = () => {
            editButton.style.visibility = 'visible';
          };
          container.onmouseleave = () => {
            editButton.style.visibility = 'hidden';
          };

          return container;
        },
      }));

      console.log('Created column configurations:', columns);

      tabulatorInstance.current = new Tabulator(tableRef.current, {
        data: tableData.data,
        columns: columns,
        layout: 'fitDataTable',
        responsiveLayout: 'hide',
        height: '100%',
        movableColumns: true,
        history: true,
        resizableColumns: true,
        resizableColumnFit: false,
        scrollToColumnPosition: 'left',
        scrollToColumnIfVisible: false,
        autoResize: false,
        layoutColumnsOnNewData: true,
      });

      tabulatorInstance.current.on('tableBuilt', () => {
        console.log('Table built');
        setIsTableReady(true);
        prevDataRef.current = [...tableData.data];
        setTableWidth();
        
        // Debug: Log table dimensions
        const tableElement = tabulatorInstance.current?.element as HTMLElement | undefined;
        if (tableElement) {
          console.log('Table dimensions:', {
            width: tableElement.offsetWidth,
            height: tableElement.offsetHeight,
            scrollWidth: tableElement.scrollWidth,
            scrollHeight: tableElement.scrollHeight,
          });

          // Debug: Check if horizontal scroll is needed
          if (tableElement.scrollWidth > tableElement.offsetWidth) {
            console.log('Horizontal scroll is needed');
          } else {
            console.log('No horizontal scroll needed');
          }
        } else {
          console.log('Table element not found');
        }
      });

      tabulatorInstance.current.on('cellEdited', (cell: CellComponent) => {
        const rowIndex = cell.getRow().getPosition() - 1;
        const field = cell.getColumn().getField();
        const value = cell.getValue();

        const updatedData = { ...tableData };
        updatedData.data = updatedData.data.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, [field]: value };
          }
          return row;
        });

        // Update formula columns
        Object.entries(updatedData.columnFormulas || {}).forEach(([formulaColumn, formula]) => {
          updatedData.data = updatedData.data.map(row => ({
            ...row,
            [formulaColumn]: applyFormula(formula, row)
          }));
        });

        onUpdateTable(updatedData);
      });

      tabulatorInstance.current.on('columnResized', (column: ColumnComponent) => {
        const updatedData = { ...tableData };
        updatedData.columnWidths = {
          ...updatedData.columnWidths,
          [column.getField()]: column.getWidth(),
        };
        onUpdateTable(updatedData);
      });

      tabulatorInstance.current.on('columnMoved', (_column: ColumnComponent, columns: ColumnComponent[]) => {
        const updatedData = { ...tableData };
        updatedData.columnOrder = columns.map((col) => col.getField());
        onUpdateTable(updatedData);
      });
    }
  }, [tableData, onUpdateTable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      initializeTable();
    }, 0);

    return () => {
      clearTimeout(timer);
      if (tabulatorInstance.current) {
        tabulatorInstance.current.destroy();
        tabulatorInstance.current = null;
      }
      setIsTableReady(false);
    };
  }, [initializeTable]);

  useEffect(() => {
    if (isTableReady && tabulatorInstance.current) {
      // Only update if the data has actually changed
      if (JSON.stringify(prevDataRef.current) !== JSON.stringify(tableData.data)) {
        tabulatorInstance.current.setData(tableData.data);
        prevDataRef.current = [...tableData.data];
      }
    }
  }, [isTableReady, tableData.data]);

  useEffect(() => {
    if (isTableReady && tabulatorInstance.current) {
      const updatedColumns = tableData.columnOrder.map(columnId => ({
        title: columnId,
        field: columnId,
        editor: tableData.columnTypes[columnId] === 'Formula' ? false : 'input',
        resizable: true,
        headerSort: true,
        formatter: (cell: any) => {
          if (tableData.columnTypes[columnId] === 'Formula') {
            return cell.getValue();
          }
          return tableData.columnTextWrap?.[columnId] 
            ? `<div style="white-space: normal;">${cell.getValue()}</div>`
            : cell.getValue();
        },
        width: tableData.columnWidths[columnId] || 150,
        headerHozAlign: 'left',
        cellClick: (_e: MouseEvent, _cell: CellComponent) => {
          if (tableData.columnTypes[columnId] === 'Formula') {
            const formula = tableData.columnFormulas[columnId];
            if (formula) {
              alert(`This column uses the formula: ${formula}`);
            }
          }
        },
        titleFormatter: (cell: any) => {
          const columnId = cell.getValue();
          const container = document.createElement('div');
          container.style.display = 'flex';
          container.style.alignItems = 'center';
          container.style.justifyContent = 'space-between';
          container.style.width = '100%';

          const titleSpan = document.createElement('span');
          titleSpan.textContent = columnId;
          container.appendChild(titleSpan);

          const editButton = document.createElement('button');
          editButton.innerHTML = '✎';
          editButton.style.visibility = 'hidden';
          editButton.style.border = 'none';
          editButton.style.background = 'none';
          editButton.style.cursor = 'pointer';
          editButton.onclick = (e: MouseEvent) => {
            e.stopPropagation();
            setEditingColumn(columnId);
            setNewColumnName(columnId);
            setEditingColumnType(tableData.columnTypes?.[columnId] || 'Text');
            setFormulaInput(typeof tableData.columnFormulas?.[columnId] === 'string' 
              ? tableData.columnFormulas[columnId] as string 
              : '');
            setAllowTextWrap(!!tableData.columnTextWrap?.[columnId]);
          };

          container.appendChild(editButton);

          container.onmouseenter = () => {
            editButton.style.visibility = 'visible';
          };
          container.onmouseleave = () => {
            editButton.style.visibility = 'hidden';
          };

          return container;
        },
      }));

      tabulatorInstance.current.setColumns(updatedColumns);
      setTableWidth();
    }
  }, [isTableReady, tableData.columnOrder, tableData.columnWidths, tableData.columnTypes, tableData.columnFormulas, tableData.columnTextWrap]);

  useEffect(() => {
    if (isTableReady && tabulatorInstance.current && sortConfig && sortConfig.key) {
      tabulatorInstance.current.setSort(sortConfig.key, sortConfig.direction);
    }
  }, [isTableReady, sortConfig]);

  const handleColumnNameChange = () => {
    console.log('Editing column:', editingColumn);
    console.log('New column name:', newColumnName);
    console.log('Editing column type:', editingColumnType);
    console.log('Formula input:', formulaInput);
    console.log('Current table data:', tableData);

    if (editingColumn && newColumnName && tabulatorInstance.current) {
      const updatedData = { ...tableData };
      const columnIndex = updatedData.columnOrder.indexOf(editingColumn);
      if (columnIndex !== -1) {
        const oldColumnName = editingColumn;

        // Update column order
        updatedData.columnOrder[columnIndex] = newColumnName;

        // Update column types
        updatedData.columnTypes = updatedData.columnTypes || {};
        updatedData.columnTypes[newColumnName] = editingColumnType;
        if (oldColumnName !== newColumnName) {
          delete updatedData.columnTypes[oldColumnName];
        }

        // Update column formulas
        updatedData.columnFormulas = updatedData.columnFormulas || {};
        if (editingColumnType === 'Formula') {
          updatedData.columnFormulas[newColumnName] = formulaInput;
          // Apply formula to all rows
          updatedData.data = updatedData.data.map((row, index) => {
            try {
              const formulaResult = applyFormula(formulaInput, row);
              const newRow = { ...row, [newColumnName]: formulaResult };
              if (oldColumnName !== newColumnName) {
                delete newRow[oldColumnName];
              }
              return newRow;
            } catch (error) {
              console.error(`Error applying formula to row ${index}:`, error);
              const newRow = { ...row, [newColumnName]: 'Error' };
              if (oldColumnName !== newColumnName) {
                delete newRow[oldColumnName];
              }
              return newRow;
            }
          });
        } else {
          // If it's not a formula, just rename the column in the data
          updatedData.data = updatedData.data.map(row => {
            const newRow = { ...row };
            if (oldColumnName !== newColumnName) {
              newRow[newColumnName] = row[oldColumnName];
              delete newRow[oldColumnName];
            }
            return newRow;
          });
          delete updatedData.columnFormulas[newColumnName];
        }
        if (oldColumnName !== newColumnName) {
          delete updatedData.columnFormulas[oldColumnName];
        }

        // Update column widths
        updatedData.columnWidths = updatedData.columnWidths || {};
        updatedData.columnWidths[newColumnName] = updatedData.columnWidths[oldColumnName] || 150;
        if (oldColumnName !== newColumnName) {
          delete updatedData.columnWidths[oldColumnName];
        }

        // Update text wrap setting
        updatedData.columnTextWrap = updatedData.columnTextWrap || {};
        if (editingColumnType === 'Text') {
          updatedData.columnTextWrap[newColumnName] = allowTextWrap;
        } else {
          delete updatedData.columnTextWrap[newColumnName];
        }

        // Update the Tabulator instance
        const updatedColumns = updatedData.columnOrder.map(columnId => ({
          title: columnId,
          field: columnId,
          editor: updatedData.columnTypes[columnId] === 'Formula' ? false : 'input',
          resizable: true,
          headerSort: true,
          formatter: (cell: any) => {
            if (updatedData.columnTypes[columnId] === 'Formula') {
              return cell.getValue();
            }
            return updatedData.columnTextWrap?.[columnId] 
              ? `<div style="white-space: normal;">${cell.getValue()}</div>`
              : cell.getValue();
          },
          width: updatedData.columnWidths[columnId] || 150,
          headerHozAlign: 'left',
          cellClick: (_e: MouseEvent, _cell: CellComponent) => {
            if (updatedData.columnTypes[columnId] === 'Formula') {
              const formula = updatedData.columnFormulas[columnId];
              if (formula) {
                alert(`This column uses the formula: ${formula}`);
              }
            }
          },
          titleFormatter: (cell: any) => {
            const columnId = cell.getValue();
            const container = document.createElement('div');
            container.style.display = 'flex';
            container.style.alignItems = 'center';
            container.style.justifyContent = 'space-between';
            container.style.width = '100%';

            const titleSpan = document.createElement('span');
            titleSpan.textContent = columnId;
            container.appendChild(titleSpan);

            const editButton = document.createElement('button');
            editButton.innerHTML = '✎';
            editButton.style.visibility = 'hidden';
            editButton.style.border = 'none';
            editButton.style.background = 'none';
            editButton.style.cursor = 'pointer';
            editButton.onclick = (e: MouseEvent) => {
              e.stopPropagation();
              setEditingColumn(columnId);
              setNewColumnName(columnId);
              setEditingColumnType(updatedData.columnTypes?.[columnId] || 'Text');
              setFormulaInput(typeof updatedData.columnFormulas?.[columnId] === 'string' 
                ? updatedData.columnFormulas[columnId] as string 
                : '');
              setAllowTextWrap(!!updatedData.columnTextWrap?.[columnId]);
            };

            container.appendChild(editButton);

            container.onmouseenter = () => {
              editButton.style.visibility = 'visible';
            };
            container.onmouseleave = () => {
              editButton.style.visibility = 'hidden';
            };

            return container;
          },
        }));
        tabulatorInstance.current.setColumns(updatedColumns);

        onUpdateTable(updatedData);
      }
      setEditingColumn(null);
      setEditingColumnType('Text');
      setFormulaInput('');
      setAllowTextWrap(false);
    }
  };

  // Function to apply formula to a row
  const applyFormula = (formula: string, row: any) => {
    console.log('Applying formula:', formula);
    console.log('Row data:', row);

    const match = formula.match(/length\(prop\('(.+)'\)\)/);
    if (match) {
      const propName = match[1];
      console.log('Property name:', propName);

      // Check if the row is defined and is an object
      if (row && typeof row === 'object') {
        // Find the property case-insensitively
        const actualPropName = Object.keys(row).find(key => key.toLowerCase() === propName.toLowerCase());
        
        if (actualPropName) {
          const value = row[actualPropName];
          console.log('Property value:', value);
          return value ? value.length.toString() : '0';
        } else {
          console.warn(`Property '${propName}' not found in row`);
          return 'N/A';
        }
      } else {
        console.warn('Row is not a valid object:', row);
        return 'Invalid Row';
      }
    }
    console.warn('Invalid formula:', formula);
    return 'Invalid Formula';
  };

  const setTableWidth = () => {
    if (tabulatorInstance.current && tableRef.current) {
      const totalWidth = tableData.columnOrder.reduce((sum, columnId) => sum + (tableData.columnWidths[columnId] || 150), 0);
      tableRef.current.style.width = `${totalWidth}px`;
      tabulatorInstance.current.redraw(true);
    }
  };

  if (!tableData || !tableData.data || tableData.data.length === 0 || !tableData.columnOrder || tableData.columnOrder.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="table-container" style={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <div ref={tableRef} style={{ width: '100%', height: '100%' }}></div>
      <Dialog open={!!editingColumn} onClose={() => setEditingColumn(null)}>
        <DialogTitle>Edit Column</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Column Name"
            fullWidth
            value={newColumnName}
            onChange={(e) => setNewColumnName(e.target.value)}
          />
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel id="column-type-label">Column Type</InputLabel>
            <Select
              labelId="column-type-label"
              value={editingColumnType}
              onChange={(e) => setEditingColumnType(e.target.value as 'Text' | 'Formula')}
              label="Column Type"
            >
              <MenuItem value="Text">Text</MenuItem>
              <MenuItem value="Formula">Formula</MenuItem>
            </Select>
          </FormControl>
          {editingColumnType === 'Formula' && (
            <TextField
              margin="dense"
              label="Formula"
              fullWidth
              value={formulaInput}
              onChange={(e) => setFormulaInput(e.target.value)}
              helperText="Example: length(prop('Headline'))"
            />
          )}
          {editingColumnType === 'Text' && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowTextWrap}
                  onChange={(e) => setAllowTextWrap(e.target.checked)}
                />
              }
              label="Allow Text Wrap"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingColumn(null)}>Cancel</Button>
          <Button onClick={handleColumnNameChange}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TableComponent;