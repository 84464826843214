import { Issue, ChipGroup } from '../types';

export interface SortConfig {
  key: string;
  direction: 'asc' | 'desc';
}

export const sortIssues = (issues: Issue[], sortConfig: SortConfig, chipGroups: ChipGroup[]): Issue[] => {
  if (!sortConfig || !sortConfig.key) return issues;

  return issues.slice().sort((a, b) => {
    let aValue: any;
    let bValue: any;

    // Check if the sort key is a chip group
    const chipGroup = chipGroups.find(group => group.name === sortConfig.key);
    if (chipGroup) {
      aValue = a.chipGroups?.[chipGroup.id];
      bValue = b.chipGroups?.[chipGroup.id];

      // Find the corresponding chip names
      const aChip = chipGroup.chips.find(chip => chip.id === aValue);
      const bChip = chipGroup.chips.find(chip => chip.id === bValue);

      aValue = aChip?.name || '';
      bValue = bChip?.name || '';
    } else {
      aValue = sortConfig.key in a ? a[sortConfig.key as keyof Issue] : a.chipGroups?.[sortConfig.key];
      bValue = sortConfig.key in b ? b[sortConfig.key as keyof Issue] : b.chipGroups?.[sortConfig.key];
    }
    
    if (aValue === undefined || bValue === undefined) return 0;
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortConfig.direction === 'asc' 
        ? aValue.localeCompare(bValue) 
        : bValue.localeCompare(aValue);
    }
    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });
};
