import React from 'react';
import NoteManager from './NoteManager';
import { User } from '../types';
import { Dialog, DialogContent, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface NotesLightboxProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
  currentUser: User;
}

const NotesLightbox: React.FC<NotesLightboxProps> = ({ open, onClose, projectId, currentUser }) => {
  if (!open) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth={false} 
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: 'white',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent sx={{ padding: 0, height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}>
          <IconButton onClick={onClose} size="large" sx={{ color: 'text.primary' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <NoteManager 
            projectId={projectId} 
            currentUser={currentUser}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NotesLightbox;