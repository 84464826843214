import React, { useState, useCallback, useRef } from 'react';
import { Select, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Button, SelectChangeEvent, Drawer, Typography, CircularProgress } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, Edit as EditIcon, Add as AddIcon, Inventory2Outlined as Inventory2OutlinedIcon, AssignmentOutlined as AssignmentOutlinedIcon } from '@mui/icons-material';
import { Project } from '../types';
import { projectService } from '../projectService';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../AppContent';

// Define ProjectSelectorProps interface
interface ProjectSelectorProps {
  onProjectSelect: (projectId: string | null) => void;
  onNewProjectCreated: (project: Project) => void;
  onProjectNameUpdate: (projectId: string, newName: string) => void;
  onProjectArchive: (projectId: string) => void;
  selectedProject: Project | null;
  canEditProjectSettings: boolean;
  projects: Project[];
}

// Update the Project interface to include serviceProvider
interface ExtendedProject extends Project {
  serviceProvider?: string;
  icon?: string;
}

const ProjectSelector: React.FC<ProjectSelectorProps> = ({
  onProjectSelect,
  onNewProjectCreated,
  onProjectNameUpdate,
  onProjectArchive,
  selectedProject,
  canEditProjectSettings,
  projects
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [editProjectName, setEditProjectName] = useState('');
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);
  const [serviceProvider, setServiceProvider] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const [projectIcon, setProjectIcon] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleProjectChange = useCallback((event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (value === 'default-project-id') {
      searchParams.delete('project');
      searchParams.delete('issue');
    } else {
      searchParams.set('project', value);
      searchParams.delete('issue');
    }
    setSearchParams(searchParams);
    onProjectSelect(value === 'default-project-id' ? null : value);
  }, [onProjectSelect, setSearchParams]);

  const handleCreateProject = async () => {
    if (newProjectName.trim()) {
      const newProject = await projectService.addProject({
        name: newProjectName.trim(),
        createdBy: `${user?.firstName} ${user?.lastName}`,
        createdById: user?.userId || '',
        createdOn: new Date().toISOString(),
        isArchived: false,
      });
      onProjectSelect(newProject.id);
      onNewProjectCreated(newProject);
      setIsDialogOpen(false);
      setNewProjectName('');
    }
  };

  const handleEditProjectClick = (project: ExtendedProject) => {
    if (canEditProjectSettings) {
      setEditProjectName(project.name);
      setServiceProvider(project.serviceProvider || '');
      setProjectIcon(project.icon || null);
      setIsSettingsDrawerOpen(true);
      // Close the project selector dropdown
      const selectElement = document.querySelector('.MuiSelect-select') as HTMLElement;
      if (selectElement) {
        selectElement.click();
      }
    } else {
      alert("You do not have permission to edit project settings");
    }
  };

  const handleUpdateProjectName = async () => {
    if (selectedProject && editProjectName.trim() && selectedProject.id !== 'default-project-id') {
      await projectService.updateProjectName(selectedProject.id, editProjectName.trim());
      onProjectNameUpdate(selectedProject.id, editProjectName.trim());
    }
  };

  const handleIconUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);
      try {
        const iconUrl = await projectService.uploadProjectIcon(selectedProject!.id, file);
        setProjectIcon(iconUrl);
      } catch (error) {
        console.error('Error uploading project icon:', error);
        alert('Failed to upload project icon. Please try again.');
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleSaveProjectSettings = async () => {
    if (selectedProject && selectedProject.id !== 'default-project-id') {
      setIsUploading(true);
      try {
        await handleUpdateProjectName();
        await projectService.updateProjectServiceProvider(selectedProject.id, serviceProvider);
        if (projectIcon) {
          await projectService.updateProjectIcon(selectedProject.id, projectIcon);
        }
      } catch (error) {
        console.error('Error saving project settings:', error);
        alert('Failed to save project settings. Please try again.');
      } finally {
        setIsUploading(false);
        handleCloseSettingsDrawer();
      }
    }
  };

  const handleCloseSettingsDrawer = () => {
    setIsSettingsDrawerOpen(false);
  };

  const handleServiceProviderChange = (event: SelectChangeEvent<string>) => {
    setServiceProvider(event.target.value);
  };

  const handleAddNewProject = () => {
    if (canEditProjectSettings) {
      setIsDialogOpen(true);
    } else {
      alert("You do not have permission to create new projects");
    }
  };

  const handleArchiveClick = (event: React.MouseEvent, project: Project) => {
    event.stopPropagation();
    setProjectToArchive(project);
    setArchiveDialogOpen(true);
  };

  const handleArchiveConfirm = async () => {
    if (projectToArchive && user) {
      await projectService.archiveProject(projectToArchive.id, user.userId!, `${user.firstName} ${user.lastName}`);
      onProjectArchive(projectToArchive.id);
      setArchiveDialogOpen(false);
      setProjectToArchive(null);
    }
  };

  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [projectToArchive, setProjectToArchive] = useState<Project | null>(null);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Select
        value={selectedProject?.id || 'default-project-id'}
        onChange={handleProjectChange}
        displayEmpty
        renderValue={(value) => {
          if (value === 'default-project-id') return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AssignmentOutlinedIcon sx={{ width: '20px', height: '20px', marginRight: '10px' }} />
              <span>Default (No Project)</span>
            </Box>
          );
          const project = projects.find((p: Project) => p.id === value);
          return project ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {project.icon ? (
                <img src={project.icon} alt={project.name} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
              ) : (
                <AssignmentOutlinedIcon sx={{ width: '20px', height: '20px', marginRight: '10px' }} />
              )}
              <span>{project.name}</span>
            </Box>
          ) : 'Select Project';
        }}
        sx={{
          '& .MuiSelect-select': {
            py: 1,
            px: 2,
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
          '& .MuiSvgIcon-root': { color: 'white' },
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
          '&.Mui-focused': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        }}
        IconComponent={KeyboardArrowDownIcon}
      >
        <MenuItem value="default-project-id">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AssignmentOutlinedIcon sx={{ width: '20px', height: '20px', marginRight: '10px' }} />
            <span>Default (No Project)</span>
          </Box>
        </MenuItem>
        {projects
          .filter((project: Project) => project.id !== 'default-project-id' && !project.isArchived)
          .map((project: Project) => (
          <MenuItem 
            key={project.id} 
            value={project.id} 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              '&:hover .project-actions': {
                opacity: 1,
              },
            }}
          >
            {project.icon ? (
              <img src={project.icon} alt={project.name} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
            ) : (
              <AssignmentOutlinedIcon sx={{ width: '20px', height: '20px', marginRight: '10px' }} />
            )}
            <span>{project.name}</span>
            {canEditProjectSettings && (
              <Box 
                className="project-actions"
                sx={{ 
                  opacity: 0,
                  transition: 'opacity 0.2s',
                  display: 'flex',
                  alignItems: 'center',
                  ml: 'auto', // This pushes the icons to the right
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditProjectClick(project);
                  }}
                  size="small"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={(e) => handleArchiveClick(e, project)}
                >
                  <Inventory2OutlinedIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </MenuItem>
        ))}
      </Select>
      {canEditProjectSettings && (
        <IconButton 
          onClick={handleAddNewProject}
          size="small"
          sx={{ ml: 1, color: 'white' }}
        >
          <AddIcon />
        </IconButton>
      )}

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Create New Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Project Name"
            fullWidth
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateProject} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Drawer
        anchor="right"
        open={isSettingsDrawerOpen}
        onClose={handleCloseSettingsDrawer}
      >
        <Box sx={{ width: 750, p: 2 }}>
          <DialogTitle>Project Settings</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Project Name"
              fullWidth
              value={editProjectName}
              onChange={(e) => setEditProjectName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Project Icon
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {projectIcon ? (
                  <img src={projectIcon} alt="Project Icon" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
                ) : (
                  <AssignmentOutlinedIcon sx={{ width: '50px', height: '50px', marginRight: '10px' }} />
                )}
                <Button
                  variant="contained"
                  component="label"
                  disabled={isUploading}
                >
                  {isUploading ? <CircularProgress size={24} /> : 'Upload Icon'}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleIconUpload}
                    ref={fileInputRef}
                  />
                </Button>
              </Box>
              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                Minimum size: 50x50 pixels. Square image is best.
              </Typography>
            </Box>
            <Select
              fullWidth
              value={serviceProvider}
              onChange={handleServiceProviderChange}
              displayEmpty
              renderValue={(value) => value || "Select Service Provider"}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="Github">Github</MenuItem>
              <MenuItem value="Zoho">Zoho</MenuItem>
              <MenuItem value="ClickUp">ClickUp</MenuItem>
              <MenuItem value="Jira">Jira</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSettingsDrawer}>Cancel</Button>
            <Button onClick={handleSaveProjectSettings} color="primary" disabled={isUploading}>
              {isUploading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </DialogActions>
        </Box>
      </Drawer>

      <Dialog open={archiveDialogOpen} onClose={() => setArchiveDialogOpen(false)}>
        <DialogTitle>Archive Project</DialogTitle>
        <DialogContent>
          Are you sure you want to archive this project?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setArchiveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleArchiveConfirm} color="primary">Archive</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProjectSelector;