import { db } from '../firebaseConfig';
import { collection, doc, setDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { Note, User } from '../types';
import { v4 as uuidv4 } from 'uuid';

const NOTES_COLLECTION = 'notes';

export const noteService = {
  async getProjectNotes(projectId: string): Promise<Note[]> {
    const notesQuery = query(
      collection(db, NOTES_COLLECTION),
      where('projectId', '==', projectId),
      where('isDeleted', '==', false)
    );
    const querySnapshot = await getDocs(notesQuery);
    return querySnapshot.docs.map(doc => doc.data() as Note);
  },

  async createNote(projectId: string, title: string, content: string, currentUser: User): Promise<Note> {
    const noteId = uuidv4();
    const newNote: Note = {
      id: noteId,
      title,
      content,
      projectId,
      createdBy: currentUser.userId || '',
      createdByName: `${currentUser.firstName} ${currentUser.lastName}`,
      createdOn: new Date().toISOString(),
      lastUpdated: new Date().toISOString(),
      isDeleted: false,
    };
    await setDoc(doc(collection(db, NOTES_COLLECTION), noteId), newNote);
    return newNote;
  },

  async updateNote(noteId: string, updatedData: Partial<Note>): Promise<void> {
    const noteDoc = doc(collection(db, NOTES_COLLECTION), noteId);
    await updateDoc(noteDoc, {
      ...updatedData,
      lastUpdated: new Date().toISOString(),
    });
  },

  async deleteNote(noteId: string): Promise<void> {
    const noteDoc = doc(collection(db, NOTES_COLLECTION), noteId);
    await updateDoc(noteDoc, { 
      isDeleted: true, 
      deletedAt: new Date().toISOString() 
    });
  },
};