import React, { useState, useEffect } from 'react';
import { Badge, IconButton, Popover, List, ListItem, ListItemText, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, SelectChangeEvent, Snackbar, Alert } from '@mui/material';
import { People as PeopleIcon } from '@mui/icons-material';
import { User, Role, PendingUserNotification } from '../types';
import { userService } from '../userService';

interface NotificationIconProps {
  pendingUserNotifications: PendingUserNotification[];
  onNotificationRead: (notificationId: string) => void;
  roles: Role[];
  onUserApprove: (userId: string, role: string) => void;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({
  pendingUserNotifications,
  onNotificationRead,
  roles,
  onUserApprove
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [activateDialogOpen, setActivateDialogOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [userToActivate, setUserToActivate] = useState<User | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    setUnreadCount(pendingUserNotifications.filter(n => !n.read).length);
  }, [pendingUserNotifications]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification: PendingUserNotification) => {
    if (notification.type === 'pending_approval') {
      const user = await userService.getUserById(notification.userId);
      if (user) {
        setUserToActivate(user);
        setSelectedRole('');
        setActivateDialogOpen(true);
      }
    }
    onNotificationRead(notification.id);
  };

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    setSelectedRole(event.target.value);
  };

  const handleActivateUser = async () => {
    if (userToActivate && selectedRole) {
      try {
        await onUserApprove(userToActivate.userId!, selectedRole);
        setActivateDialogOpen(false);
        setSnackbarMessage(`User ${userToActivate.firstName} ${userToActivate.lastName} activated with role ${selectedRole}`);
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error activating user:', error);
        setSnackbarMessage('Failed to activate user');
        setSnackbarOpen(true);
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge badgeContent={unreadCount} color="secondary">
          <PeopleIcon sx={{ color: 'white' }} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { width: '500px' },
        }}
      >
        <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
          {pendingUserNotifications.length === 0 ? (
            <ListItem>
              <ListItemText primary="No notifications" />
            </ListItem>
          ) : (
            pendingUserNotifications.map((notification) => (
              <ListItem
                key={notification.id}
                button
                onClick={() => handleNotificationClick(notification)}
                sx={{ backgroundColor: notification.read ? 'inherit' : 'action.hover' }}
              >
                <ListItemText
                  primary={
                    notification.type === 'pending_approval'
                      ? `Pending approval: ${notification.userName || 'Unknown User'}`
                      : notification.type === 'mention'
                      ? 'You were mentioned in an issue'
                      : 'New comment on your issue'
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="text.secondary">
                        {notification.type === 'pending_approval' && `Email: ${notification.userEmail || 'Unknown Email'}`}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {new Date(notification.timestamp).toLocaleString()}
                      </Typography>
                      {notification.type === 'pending_approval' && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNotificationClick(notification);
                          }}
                        >
                          Activate
                        </Button>
                      )}
                    </>
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </Popover>
      <Dialog open={activateDialogOpen} onClose={() => setActivateDialogOpen(false)}>
        <DialogTitle>Activate User</DialogTitle>
        <DialogContent>
          <Typography>
            Activate user: {userToActivate?.firstName} {userToActivate?.lastName}
          </Typography>
          <Select
            fullWidth
            value={selectedRole}
            onChange={handleRoleChange}
            sx={{ mt: 2 }}
          >
            {roles.map((role) => (
              <MenuItem key={role.roleId} value={role.name}>{role.name}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setActivateDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleActivateUser} disabled={!selectedRole}>Activate</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotificationIcon;