import React from 'react';
import { Popover, List, ListItem, ListItemIcon, Checkbox, ListItemText, Typography, Box } from '@mui/material';
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided, DropResult } from 'react-beautiful-dnd';
import { ChipGroup } from '../types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface ColumnSelectorProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  visibleColumns: string[];
  viewMode: 'list' | 'card' | 'kanban';
  columnOrder: string[];
  onReorder: (newOrder: string[]) => void;
  onToggleVisibility: (column: string) => void;
  chipGroups: ChipGroup[];
  allKanbanColumns: string[];
}

const ColumnSelector: React.FC<ColumnSelectorProps> = ({
  open,
  anchorEl,
  onClose,
  visibleColumns,
  viewMode,
  columnOrder,
  onReorder,
  onToggleVisibility,
  chipGroups,
  allKanbanColumns,
}) => {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newOrder = Array.from(columnOrder);
    const [reorderedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, reorderedItem);

    onReorder(newOrder);
  };

  const handleToggle = (column: string) => {
    if (viewMode === 'kanban') {
      onToggleVisibility(column);
    } else {
      onToggleVisibility(column);
    }
  };

  const getColumnLabel = (column: string) => {
    if (viewMode === 'kanban') {
      const statusGroup = chipGroups.find(group => group.id === 'Status');
      const chip = statusGroup?.chips.find(c => c.id === column);
      return chip ? chip.name : column;
    } else {
      const chipGroup = chipGroups.find(group => group.name === column);
      return chipGroup ? chipGroup.name : column;
    }
  };

  const columnsToShow = viewMode === 'kanban' ? allKanbanColumns : columnOrder;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Typography variant="h6" sx={{ p: 2 }}>
        {viewMode === 'kanban' ? 'Kanban Columns' : 'Table Columns'}
      </Typography>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="column-list">
          {(provided: DroppableProvided) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {columnsToShow.map((column, index) => (
                <Draggable key={column} draggableId={column} index={index}>
                  {(provided: DraggableProvided) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box
                        {...provided.dragHandleProps}
                        sx={{
                          mr: 1,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <DragIndicatorIcon />
                      </Box>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={visibleColumns.includes(column)}
                          tabIndex={-1}
                          disableRipple
                          onChange={() => handleToggle(column)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={getColumnLabel(column)} />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Popover>
  );
};

export default ColumnSelector;