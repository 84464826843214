import { Issue, ChipGroup } from './types';

export const getChipCounts = (issues: Issue[], chipGroups: ChipGroup[]): { [groupId: string]: { [chipId: string]: number } } => {
  const counts: { [groupId: string]: { [chipId: string]: number } } = {};

  chipGroups.forEach(group => {
    counts[group.id] = {};
    group.chips.forEach(chip => {
      counts[group.id][chip.id] = 0;
    });
  });

  issues.forEach(issue => {
    if (issue.chipGroups) {
      Object.entries(issue.chipGroups).forEach(([groupId, chipId]) => {
        if (counts[groupId] && counts[groupId][chipId] !== undefined) {
          counts[groupId][chipId]++;
        }
      });
    }
  });

  return counts;
};

// Remove the following functions as they are no longer needed:
// getStatusCounts
// getLevelCounts
// getTypeCounts

// Add any other utility functions here that don't rely on type, level, or status