import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/LoginPage.css';
import './styles/WhatsNewLightbox.css';
import './styles/index.css';
import 'tinymce/skins/ui/oxide/skin.min.css';
import tinymce from 'tinymce/tinymce';

import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/codesample';

tinymce.baseURL = '/tinymce';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Fatal Error: Root element with id 'root' not found in the DOM. Please check your index.html file.");
  document.body.innerHTML = '<div style="color: red; font-size: 20px;">Fatal Error: Application could not be mounted. Please check the console for more information.</div>';
}
