import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { User, ChipGroup } from '../types';
import { TextFieldProps } from '@mui/material/TextField';

interface FilterLightboxProps {
  open: boolean;
  onClose: () => void;
  onFilterChange: (key: string, value: any) => void;
  onClearAllFilters: () => void;
  filters: { [key: string]: any };
  users: User[];
  allTags: string[];
  chipGroups: ChipGroup[];
}

const FilterLightbox: React.FC<FilterLightboxProps> = ({
  open,
  onClose,
  onFilterChange,
  onClearAllFilters,
  filters,
  users,
  allTags,
  chipGroups,
}) => {
  const [localFilters, setLocalFilters] = useState(filters);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleFilterChange = (key: string, value: any) => {
    setLocalFilters(prev => ({ ...prev, [key]: value }));
  };

  const handleApplyFilters = () => {
    Object.entries(localFilters).forEach(([key, value]) => {
      onFilterChange(key, value);
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Filters</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            label="ID"
            variant="outlined"
            fullWidth
            value={localFilters.id || ''}
            onChange={(e) => handleFilterChange('id', e.target.value)}
          />
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={localFilters.title || ''}
            onChange={(e) => handleFilterChange('title', e.target.value)}
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            value={localFilters.description || ''}
            onChange={(e) => handleFilterChange('description', e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Created On (From)"
              value={localFilters.createdOnFrom || null}
              onChange={(date) => handleFilterChange('createdOnFrom', date)}
              slotProps={{ textField: { fullWidth: true } as TextFieldProps }}
            />
            <DatePicker
              label="Created On (To)"
              value={localFilters.createdOnTo || null}
              onChange={(date) => handleFilterChange('createdOnTo', date)}
              slotProps={{ textField: { fullWidth: true } as TextFieldProps }}
            />
            <DatePicker
              label="Last Updated (From)"
              value={localFilters.lastUpdatedFrom || null}
              onChange={(date) => handleFilterChange('lastUpdatedFrom', date)}
              slotProps={{ textField: { fullWidth: true } as TextFieldProps }}
            />
            <DatePicker
              label="Last Updated (To)"
              value={localFilters.lastUpdatedTo || null}
              onChange={(date) => handleFilterChange('lastUpdatedTo', date)}
              slotProps={{ textField: { fullWidth: true } as TextFieldProps }}
            />
          </LocalizationProvider>
          <FormControl fullWidth>
            <InputLabel>Tags</InputLabel>
            <Select
              multiple
              value={localFilters.tags || []}
              onChange={(e) => handleFilterChange('tags', e.target.value)}
              input={<OutlinedInput label="Tags" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {allTags.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  <Checkbox checked={(localFilters.tags || []).indexOf(tag) > -1} />
                  <ListItemText primary={tag} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Assigned To</InputLabel>
            <Select
              multiple
              value={localFilters.assignedTo || []}
              onChange={(e) => handleFilterChange('assignedTo', e.target.value)}
              input={<OutlinedInput label="Assigned To" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={users.find(u => u.userId === value)?.firstName || value} />
                  ))}
                </Box>
              )}
            >
              {users.map((user) => (
                <MenuItem key={user.userId} value={user.userId}>
                  <Checkbox checked={(localFilters.assignedTo || []).indexOf(user.userId) > -1} />
                  <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Created By</InputLabel>
            <Select
              value={localFilters.createdBy || ''}
              onChange={(e) => handleFilterChange('createdBy', e.target.value)}
              input={<OutlinedInput label="Created By" />}
            >
              {users.map((user) => (
                <MenuItem key={user.userId} value={user.userId}>
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {chipGroups.map((group) => (
            <FormControl key={group.id} fullWidth>
              <InputLabel>{group.name}</InputLabel>
              <Select
                value={localFilters[group.id] || ''}
                onChange={(e) => handleFilterChange(group.id, e.target.value)}
                input={<OutlinedInput label={group.name} />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {group.chips && group.chips.map((chip) => (
                  <MenuItem key={chip.id} value={chip.id}>
                    {chip.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClearAllFilters} color="secondary">
          Clear All Filters
        </Button>
        <Button onClick={handleApplyFilters} color="primary">
          Apply Filters
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterLightbox;