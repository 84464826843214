import { User, Permissions, Issue } from '../types';

export const checkPermission = (user: User, permission: keyof Permissions): boolean => {
  if (user.isAdmin) {
    return true;
  }
  return user.permissions?.[permission] || false;
};

export const updateUserPermissions = (user: User, newPermissions: Permissions): User => {
  return {
    ...user,
    permissions: newPermissions,
  };
};

export const mergePermissions = (existingPermissions: Permissions, newPermissions: Partial<Permissions>): Permissions => {
  return {
    ...existingPermissions,
    ...newPermissions,
  };
};

// Add a new function to check if a user can view a private issue
export const canViewPrivateIssue = (user: User | null, issue: Issue): boolean => {
  if (!user) return false;
  return user.isAdmin || user.userId === issue.createdBy || checkPermission(user, 'canViewPrivateIssues');
};
