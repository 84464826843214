import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject, getMetadata, updateMetadata } from 'firebase/storage';

const ATTACHMENTS_FOLDER = 'files/issues';

export const fileService = {
  async uploadFile(file: File, issueId: string): Promise<string> {
    try {
      const safeFileName = file.name.replace(/\s+/g, '_');
      const fileName = `${Date.now()}_${safeFileName}`;
      const storageRef = ref(storage, `${ATTACHMENTS_FOLDER}/${issueId}/${fileName}`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      const metadata = {
        customMetadata: {
          fileName: safeFileName,
          fileSize: file.size.toString(),
          contentType: file.type,
        },
      };

      await updateMetadata(storageRef, metadata);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  },

  async deleteFile(fileUrl: string): Promise<void> {
    try {
      const fileRef = ref(storage, fileUrl);
      await deleteObject(fileRef);
    } catch (error) {
      console.error("Error deleting file:", error);
      throw error;
    }
  },

  async getFileMetadata(fileUrl: string): Promise<{ fileName: string, fileSize: number, contentType: string }> {
    try {
      const fileRef = ref(storage, fileUrl);
      const metadata = await getMetadata(fileRef);
      return {
        fileName: metadata.customMetadata?.fileName || '',
        fileSize: parseInt(metadata.customMetadata?.fileSize || '0'),
        contentType: metadata.customMetadata?.contentType || '',
      };
    } catch (error) {
      console.error("Error getting file metadata:", error);
      throw error;
    }
  },

  async moveFile(oldPath: string, newPath: string): Promise<string> {
    try {
      const oldRef = ref(storage, oldPath);
      const newRef = ref(storage, newPath);

      // Download the file data
      const response = await fetch(await getDownloadURL(oldRef));
      const blob = await response.blob();

      // Upload to the new location
      await uploadBytes(newRef, blob);

      // Delete from the old location
      await deleteObject(oldRef);

      // Return the new download URL
      return await getDownloadURL(newRef);
    } catch (error) {
      console.error("Error moving file:", error);
      throw error;
    }
  },
};

// Server-side code (if needed)
if (typeof window === 'undefined') {
  // You can add server-side file operations here if needed
  // But don't export anything from this block
}