import { firestore } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { ChipGroup, Chip } from '../types';

const getChipGroupsDoc = (projectId: string) => {
  return doc(firestore, `projects/${projectId}/chipGroups/groups`);
};

export const chipsService = {
  async getChipGroups(projectId: string): Promise<ChipGroup[]> {
    const groupsDoc = getChipGroupsDoc(projectId);
    const snapshot = await getDoc(groupsDoc);
    if (snapshot.exists()) {
      const groups = snapshot.data().groups as ChipGroup[];
      console.log('Fetched groups:', JSON.stringify(groups, null, 2));
      return this.ensureChipIds(groups);
    } else {
      const defaultGroups = this.getDefaultChipGroups();
      await this.updateChipGroups(projectId, defaultGroups);
      console.log('Using default groups:', JSON.stringify(defaultGroups, null, 2));
      return defaultGroups;
    }
  },

  getDefaultChipGroups(): ChipGroup[] {
    return [
      {
        id: 'Group1',
        name: 'Group1',
        chips: [
          { id: uuidv4(), name: 'Issue', color: '#FFB347' },
          { id: uuidv4(), name: 'Enhancement', color: '#87CEEB' }
        ]
      },
      {
        id: 'Group2',
        name: 'Group2',
        chips: [
          { id: uuidv4(), name: 'Blocking', color: '#FFC0CB' },
          { id: uuidv4(), name: 'Not Blocking', color: '#FAFAD2' },
          { id: uuidv4(), name: 'Design', color: '#A9A9A9' },
          { id: uuidv4(), name: 'Functionality', color: '#87CEFA' },
          { id: uuidv4(), name: 'Backend', color: '#DDA0DD' }
        ]
      },
      {
        id: 'Status',
        name: 'Status',
        chips: [
          { id: uuidv4(), name: 'Open', color: '#ADD8E6' },
          { id: uuidv4(), name: 'In Process', color: '#E6E6FA' },
          { id: uuidv4(), name: 'Completed', color: '#98FB98' }
        ]
      }
    ];
  },

  async updateChipGroups(projectId: string, groups: ChipGroup[]): Promise<void> {
    const groupsWithIds = this.ensureChipIds(groups);
    const groupsDoc = getChipGroupsDoc(projectId);
    await setDoc(groupsDoc, { groups: groupsWithIds });
  },

  ensureChipIds(groups: ChipGroup[]): ChipGroup[] {
    return groups.map(group => ({
      ...group,
      chips: group.chips.map(chip => ({
        ...chip,
        id: chip.id || uuidv4()
      }))
    }));
  },

  async addChip(projectId: string, groupId: string, chipName: string, chipColor: string): Promise<void> {
    const groups = await this.getChipGroups(projectId);
    const groupIndex = groups.findIndex(g => g.id === groupId);
    if (groupIndex !== -1) {
      const newChip: Chip = { id: uuidv4(), name: chipName, color: chipColor };
      groups[groupIndex].chips.push(newChip);
      await this.updateChipGroups(projectId, groups);
    }
  },

  async updateChip(projectId: string, groupId: string, chipId: string, updates: Partial<Chip>): Promise<void> {
    const groups = await this.getChipGroups(projectId);
    const groupIndex = groups.findIndex(g => g.id === groupId);
    if (groupIndex !== -1) {
      const chipIndex = groups[groupIndex].chips.findIndex(c => c.id === chipId);
      if (chipIndex !== -1) {
        groups[groupIndex].chips[chipIndex] = { ...groups[groupIndex].chips[chipIndex], ...updates };
        await this.updateChipGroups(projectId, groups);
      }
    }
  },

  async deleteChip(projectId: string, groupId: string, chipId: string): Promise<void> {
    const groups = await this.getChipGroups(projectId);
    const groupIndex = groups.findIndex(g => g.id === groupId);
    if (groupIndex !== -1) {
      // For Status group, ensure at least one chip remains
      if (groupId === 'Status' && groups[groupIndex].chips.length <= 1) {
        throw new Error("Cannot delete the last Status chip");
      }
      groups[groupIndex].chips = groups[groupIndex].chips.filter(c => c.id !== chipId);
      await this.updateChipGroups(projectId, groups);
    }
  },

  async updateGroupName(projectId: string, groupId: string, newName: string): Promise<void> {
    const groups = await this.getChipGroups(projectId);
    const groupIndex = groups.findIndex(g => g.id === groupId);
    if (groupIndex !== -1) {
      groups[groupIndex].name = newName;
      await this.updateChipGroups(projectId, groups);
    }
  }
};