import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Avatar, AvatarGroup, Tooltip } from '@mui/material';
import { Issue, ChipGroup, User } from '../types';
import { getTextColor } from '../utils/colorUtils';

interface IssueCardProps {
  issue: Issue;
  chipGroups: ChipGroup[];
  onClick: () => void;
  creator?: User;
  assignees: User[];
}

const IssueCard: React.FC<IssueCardProps> = ({ 
  issue, 
  chipGroups, 
  onClick, 
  creator,
  assignees,
}) => {
  const stripHtml = (html: string) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  return (
    <Card 
      onClick={onClick} 
      sx={{ 
        width: '100%',
        maxWidth: 250,
        height: 'auto',
        maxHeight: 350,
        mb: 2,
        cursor: 'pointer',
        overflow: 'auto',
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom noWrap>
          {stripHtml(issue.title)}
        </Typography>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            mb: 2,
          }}
        >
          {stripHtml(issue.description)}
        </Typography>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          {creator && (
            <Box display="flex" alignItems="center">
              <Tooltip title={`${creator.firstName} ${creator.lastName}`}>
                <Avatar src={creator.avatarUrl} alt={creator.firstName} sx={{ width: 24, height: 24, mr: 1 }} />
              </Tooltip>
              <Box>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  {`${creator.firstName} ${creator.lastName}`}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {new Date(issue.createdOn).toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
          )}
          <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 24, height: 24 } }}>
            {assignees.map((user) => (
              <Tooltip key={user.userId} title={`${user.firstName} ${user.lastName}`}>
                <Avatar alt={user.firstName} src={user.avatarUrl} />
              </Tooltip>
            ))}
          </AvatarGroup>
        </Box>
        <Box display="flex" flexWrap="wrap" gap={0.5}>
          {chipGroups.map((group) => {
            const chipId = issue.chipGroups?.[group.id];
            const chip = group.chips.find(c => c.id === chipId);
            if (chip) {
              return (
                <Chip
                  key={group.id}
                  label={chip.name}
                  size="small"
                  style={{
                    backgroundColor: chip.color,
                    color: getTextColor(chip.color)
                  }}
                />
              );
            }
            return null;
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

export default IssueCard;