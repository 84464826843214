import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Drawer,
  Snackbar,
  Alert,
  InputAdornment,
  Dialog,
  DialogContent,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Add as AddIcon,
  ViewColumn as ViewColumnIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
  ViewKanban as ViewKanbanIcon,
  FilterList as FilterListIcon,  
  Sort as SortIcon,
  Image as ImageIcon,
  TableChart as TableChartIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  DescriptionOutlined as NotesIcon,
} from '@mui/icons-material';
import IssueList from './IssueList';
import IssueForm from './IssueForm';
import IssueDetails from './IssueDetails';
import KanbanBoard from './KanbanBoard';
import ColumnSelector from './ColumnSelector';
import FilterLightbox from './FilterLightbox';
import SearchIssues from './SearchIssues';
import ImageGalleryLightbox from './ImageGalleryLightbox';
import TableManager from './TableManager';
import { TableData } from '../types';
import NotesLightbox from './NotesLightbox';
import { Issue, User, SortConfig, IssueFormData, Project, ChipGroup } from '../types';
import useLocalStorage from '../hooks/useLocalStorage';
import { useAuth } from '../AppContent';
import ErrorBoundary from './ErrorBoundary';
import { useNavigate } from 'react-router-dom';
import IssueCard from './IssueCard';
import { sortIssues } from '../utils/sortingUtils';
import { canViewPrivateIssue } from '../utils/permissionUtils';

interface IssueListContainerProps {
  issues: Issue[];
  users: User[];
  onUpdateIssue: (updatedIssue: Issue) => Promise<void>;
  onAddIssue: (issue: IssueFormData, files: File[]) => Promise<Issue>;
  currentUser: User;
  projectId: string | null;
  projects: Project[];
  onSelectIssue: (issue: Issue | null) => void;
  allTags: string[];
  selectedIssue: Issue | null;
  chipGroups: ChipGroup[];
}

const IssueListContainer: React.FC<IssueListContainerProps> = ({
  issues,
  users,
  onUpdateIssue,
  onAddIssue,
  currentUser,
  projectId,
  projects,
  onSelectIssue,
  allTags,
  selectedIssue,
  chipGroups,
}) => {
  const [filters, setFilters] = useLocalStorage<{ [key: string]: any }>('issueFilters', {});
  const [sortConfig, setSortConfig] = useLocalStorage<SortConfig>('issueSortConfig', { key: 'createdOn', direction: 'desc' });
  const [viewMode, setViewMode] = useLocalStorage<'list' | 'card' | 'kanban'>('issueViewMode', 'list');

  const [columnSelectorAnchorEl, setColumnSelectorAnchorEl] = useState<null | HTMLElement>(null);
  const [isNewIssueDrawerOpen, setIsNewIssueDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isFilterLightboxOpen, setIsFilterLightboxOpen] = useState(false);
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const { user } = useAuth();
  const [localIssues, setLocalIssues] = useState<Issue[]>(issues);
  const [isTableLightboxOpen, setIsTableLightboxOpen] = useState(false);
  const [isNotesLightboxOpen, setIsNotesLightboxOpen] = useState(false);
  const navigate = useNavigate();
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const statusChipGroup = useMemo(() => chipGroups.find(group => group.id === 'Status'), [chipGroups]);

  const [kanbanColumnOrder, setKanbanColumnOrder] = useLocalStorage<string[]>(
    'kanbanColumnOrder',
    statusChipGroup?.chips.map(chip => chip.name) || []
  );

  const [localVisibleKanbanColumns, setLocalVisibleKanbanColumns] = useLocalStorage<string[]>(
    'visibleKanbanColumns',
    kanbanColumnOrder
  );

  const sortableColumns = useMemo(() => [
    'id', 'title', 'description', 'createdOn', 'lastUpdated', 'tags', 'assignedTo', 'createdBy',
    ...chipGroups.map(group => group.name)
  ], [chipGroups]);

  useEffect(() => {
    setLocalIssues(issues.filter(issue => !issue.isDeleted));
  }, [issues]);

  const handleFilterChange = (key: string, value: any) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value
    }));
  };

  const handleClearAllFilters = () => {
    setFilters({});
  };

  const handleSortIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortMenuAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchorEl(null);
  };

  const handleSortChange = useCallback((column: string) => {
    setSortConfig(prevConfig => {
      const newConfig: SortConfig = {
        key: column,
        direction: prevConfig.key === column && prevConfig.direction === 'desc' ? 'asc' : 'desc'
      };
      console.log('New sort config:', newConfig);
      return newConfig;
    });
    handleSortMenuClose();
  }, [setSortConfig]);

  const handleColumnSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setColumnSelectorAnchorEl(event.currentTarget);
  };

  const handleColumnSelectorClose = () => {
    setColumnSelectorAnchorEl(null);
  };

  const handleToggleColumnVisibility = (columnName: string) => {
    if (viewMode === 'kanban') {
      setLocalVisibleKanbanColumns(prev => {
        if (prev.includes(columnName)) {
          return prev.filter(c => c !== columnName);
        } else {
          const newColumns = [...prev];
          const index = kanbanColumnOrder.indexOf(columnName);
          if (index !== -1) {
            newColumns.splice(index, 0, columnName);
          } else {
            newColumns.push(columnName);
          }
          return newColumns;
        }
      });
    } else {
      setVisibleColumns(prev => 
        prev.includes(columnName) ? prev.filter(c => c !== columnName) : [...prev, columnName]
      );
    }
  };

  const handleColumnReorder = (newOrder: string[]) => {
    if (viewMode === 'kanban') {
      setKanbanColumnOrder(newOrder);
      setLocalVisibleKanbanColumns(prev => {
        return newOrder.filter(column => prev.includes(column));
      });
    } else {
      setVisibleColumns(newOrder);
      setColumnOrder(newOrder);
    }
  };

  const filteredAndSortedIssues = useMemo(() => {
    const filteredIssues = localIssues
      .filter(issue => {
        // Check if the issue is private and if the current user can view it
        if (issue.isPrivate && !canViewPrivateIssue(currentUser, issue)) {
          return false;
        }

        // Add this condition at the beginning of the filter function
        if (searchTerm && !issue.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !issue.id.includes(searchTerm) &&
            !issue.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))) {
          return false;
        }

        // ID filter
        if (filters.id && !issue.id.includes(filters.id)) return false;

        // Title filter
        if (filters.title && !issue.title.toLowerCase().includes(filters.title.toLowerCase())) return false;

        // Description filter
        if (filters.description && !issue.description.toLowerCase().includes(filters.description.toLowerCase())) return false;

        // Created On filter
        if (filters.createdOnFrom || filters.createdOnTo) {
          const createdDate = new Date(issue.createdOn);
          if (filters.createdOnFrom && createdDate < new Date(filters.createdOnFrom)) return false;
          if (filters.createdOnTo && createdDate > new Date(filters.createdOnTo)) return false;
        }

        // Last Updated filter
        if (filters.lastUpdatedFrom || filters.lastUpdatedTo) {
          const updatedDate = new Date(issue.lastUpdated);
          if (filters.lastUpdatedFrom && updatedDate < new Date(filters.lastUpdatedFrom)) return false;
          if (filters.lastUpdatedTo && updatedDate > new Date(filters.lastUpdatedTo)) return false;
        }

        // Tags filter
        if (filters.tags && filters.tags.length > 0) {
          if (!issue.tags || !filters.tags.every((tag: string) => issue.tags.includes(tag))) return false;
        }

        // Assigned To filter
        if (filters.assignedTo && filters.assignedTo.length > 0) {
          if (!issue.assignedTo || !filters.assignedTo.some((userId: string) => issue.assignedTo.includes(userId))) return false;
        }

        // Created By filter
        if (filters.createdBy && issue.createdBy !== filters.createdBy) return false;

        // Chip Groups filters (including Task Type, Issue Level, and Status)
        for (const group of chipGroups) {
          if (filters[group.id] && filters[group.id] !== '') {
            // Check if the issue has the chipGroups property and the specific group
            if (!issue.chipGroups || !issue.chipGroups[group.id]) {
              return false;
            }
            if (issue.chipGroups[group.id] !== filters[group.id]) {
              return false;
            }
          }
        }

        return true;
      });

    return sortIssues(filteredIssues, sortConfig, chipGroups);
  }, [localIssues, filters, sortConfig, chipGroups, searchTerm, currentUser]);

  const handleNewIssueClick = () => {
    if (currentUser.permissions?.canCreateIssues) {
      setIsNewIssueDrawerOpen(true);
    } else {
      showSnackbar('You do not have permission to create issues', 'error');
    }
  };

  const handleNewIssueDrawerClose = () => {
    setIsNewIssueDrawerOpen(false);
    // Reset the form data if needed
    // You might want to add a resetForm function to your IssueForm component
    // and call it here
  };

  const handleAddIssue = async (issue: IssueFormData, files: File[]): Promise<Issue> => {
    console.log('handleAddIssue called with data:', issue);
    try {
      console.log('Attempting to create new issue:', issue);
      const newIssueData: Omit<Issue, 'id' | 'createdOn' | 'lastUpdated'> = {
        ...issue,
        createdBy: currentUser.userId || '',
        isDeleted: false,
        conversations: [],
        lastEditedBy: currentUser.userId || '',
        lastEditedByName: `${currentUser.firstName} ${currentUser.lastName}`,
        createdByName: `${currentUser.firstName} ${currentUser.lastName}`,
        projectId: issue.projectId || 'default-project-id',
        tasks: issue.tasks.map(task => ({
          ...task,
          id: `task-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          createdOn: new Date().toISOString(),
        })),
        attachments: issue.attachments,  // This line should now work without casting
      };
      console.log('Prepared new issue data:', newIssueData);
      const createdIssue = await onAddIssue(newIssueData, files);
      console.log('Issue created successfully:', createdIssue);
      setLocalIssues(prevIssues => [...prevIssues, createdIssue]);
      showSnackbar('Issue created successfully', 'success');
      
      // Close the new issue drawer
      setIsNewIssueDrawerOpen(false);
      
      // Redirect to the newly created issue's details page
      navigate(`/issues/?project=${createdIssue.projectId}&issue=${createdIssue.id}`);
      
      return createdIssue;
    } catch (error) {
      console.error("Error adding issue:", error);
      showSnackbar('Failed to create issue', 'error');
      throw error;
    }
  };

  const handleDuplicateIssue = async (issueData: Omit<Issue, 'id' | 'createdOn' | 'lastUpdated'>) => {
    try {
      const newIssueData: IssueFormData = {
        title: `Copy - ${issueData.title}`,
        description: issueData.description,
        assignedTo: issueData.assignedTo,
        attachments: issueData.attachments as string[],
        projectId: issueData.projectId,
        tasks: issueData.tasks,
        tags: issueData.tags,
        chipGroups: issueData.chipGroups || {},
        createdBy: user?.userId || '',
        createdByName: user ? `${user.firstName} ${user.lastName}` : '',
        lastEditedBy: user?.userId || '',
        lastEditedByName: user ? `${user.firstName} ${user.lastName}` : '',
        isPrivate: issueData.isPrivate,
      };

      console.log('Duplicating issue with data:', newIssueData); // Add this log

      const createdIssue = await onAddIssue(newIssueData, []);
      setLocalIssues(prevIssues => [...prevIssues, createdIssue]);
      showSnackbar('Issue duplicated successfully', 'success');
      return createdIssue;
    } catch (error) {
      console.error('Error duplicating issue:', error);
      showSnackbar('Failed to duplicate issue', 'error');
      throw error;
    }
  };

  const toggleViewMode = () => {
    setViewMode(prevMode => {
      if (prevMode === 'list') return 'card';
      if (prevMode === 'card') return 'kanban';
      return 'list';
    });
  };

  const handleEditIssue = (issue: Issue) => {
    if (currentUser.permissions?.canEditIssues) {
      onSelectIssue(issue);
    } else {
      showSnackbar('You do not have permission to edit issues', 'error');
    }
  };

  const handleCloseEditDrawer = () => {
    onSelectIssue(null);
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const getActiveFilterCount = () => {
    return Object.values(filters).filter(value => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== undefined && value !== '';
    }).length;
  };

  const handleImageGalleryClick = () => {
    setIsImageGalleryOpen(true);
  };

  const handleTableClick = () => {
    if (projectId) {
      setIsTableLightboxOpen(true);
    } else {
      showSnackbar('Please select a project to view tables', 'error');
    }
  };

  const handleNotesClick = () => {
    if (projectId) {
      setIsNotesLightboxOpen(true);
    } else {
      showSnackbar('Please select a project to view notes', 'error');
    }
  };

  const handleUpdateIssue = async (updatedIssue: Issue) => {
    console.log('Updating issue in IssueListContainer:', updatedIssue);
    try {
      const issueWithEditInfo = {
        ...updatedIssue,
        lastEditedBy: currentUser.userId || '',
        lastEditedByName: `${currentUser.firstName} ${currentUser.lastName}`,
      };
      await onUpdateIssue(issueWithEditInfo);
      setLocalIssues(prevIssues => prevIssues.map(issue => 
        issue.id === issueWithEditInfo.id ? issueWithEditInfo : issue
      ));
      console.log('Issue updated successfully');
    } catch (error) {
      console.error("Error updating issue:", error);
    }
  };

  const handleIssueSelect = (issue: Issue) => {
    onSelectIssue(issue);
  };

  const handleSaveTable = async (updatedData: TableData) => {
    try {
      // Implement the logic to save the table data
      // This might involve calling an API or updating the state
      console.log('Saving table data:', updatedData);
      showSnackbar('Table saved successfully', 'success');
    } catch (error) {
      console.error('Error saving table:', error);
      showSnackbar('Failed to save table', 'error');
    }
  };

  const defaultColumns = useMemo(() => [
    'id', 'title', 'description', 'items', 'assignedTo', 'createdBy', 'createdOn', 'lastUpdated', 'tags', 
    ...chipGroups.map(group => group.name)
  ], [chipGroups]);

  const [visibleColumns, setVisibleColumns] = useLocalStorage<(keyof Issue | 'items' | string)[]>(
    'issueVisibleColumns',
    defaultColumns
  );

  const [columnOrder, setColumnOrder] = useLocalStorage<(keyof Issue | 'items' | string)[]>(
    'issueColumnOrder',
    defaultColumns
  );

  useEffect(() => {
    // Update the column order based on the chip groups when the project changes
    const newColumnOrder = [
      'id', 'title', 'description', 'items', 
      ...chipGroups.map(group => group.name), // Use group names
      'assignedTo', 'createdBy', 'createdOn', 'lastUpdated', 'tags'
    ];
    setColumnOrder(newColumnOrder);
  }, [chipGroups]); // Depend on chipGroups to update when it changes

  const renderIssueCard = (issue: Issue) => {
    const creator = users.find(u => u.userId === issue.createdBy);
    const assignees = users.filter(u => issue.assignedTo?.includes(u.userId || ''));

    return (
      <IssueCard
        key={issue.id}
        issue={issue}
        chipGroups={chipGroups}
        onClick={() => handleIssueSelect(issue)}
        creator={creator}
        assignees={assignees}
      />
    );
  };

  const getColumnOrder = () => {
    if (viewMode === 'kanban') {
      return localVisibleKanbanColumns;
    } else {
      return columnOrder;
    }
  };

  return (
    <Box sx={{ width: '100%', overflowX: 'auto', p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
          <IconButton onClick={handleImageGalleryClick} sx={{ mr: 1 }}>
            <ImageIcon />
          </IconButton>
          <IconButton onClick={handleNotesClick} sx={{ mr: 1 }}>
            <NotesIcon />
          </IconButton>
          <IconButton onClick={handleTableClick} sx={{ mr: 1 }}>
            <TableChartIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, mx: 2 }}>
            <SearchIssues 
              onSearch={setSearchTerm} 
              placeholder="Search by Issue ID, Title or Tags"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {currentUser.permissions?.canCreateIssues && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleNewIssueClick}
              sx={{ mr: 2 }}
            >
              New Issue
            </Button>
          )}
          {viewMode !== 'card' && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ViewColumnIcon />}
              onClick={handleColumnSelectorClick}
              sx={{ mr: 2 }}
            >
              Columns
            </Button>
          )}
          <IconButton onClick={() => setIsFilterLightboxOpen(true)} sx={{ mr: 1 }}>
            <FilterListIcon />
          </IconButton>
          <IconButton onClick={toggleViewMode} sx={{ mr: 2 }}>
            {viewMode === 'list' && <ViewModuleIcon />}
            {viewMode === 'card' && <ViewKanbanIcon />}
            {viewMode === 'kanban' && <ViewListIcon />}
          </IconButton>
          <IconButton onClick={handleSortIconClick}>
            <SortIcon />
          </IconButton>
          <Menu
            anchorEl={sortMenuAnchorEl}
            open={Boolean(sortMenuAnchorEl)}
            onClose={handleSortMenuClose}
          >
            {sortableColumns.map((column) => (
              <MenuItem
                key={column}
                onClick={() => handleSortChange(column)}
                selected={sortConfig.key === column}
              >
                {column}
                {sortConfig.key === column && (
                  sortConfig.direction === 'asc' ? ' ↑' : ' ↓'
                )}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>

      {getActiveFilterCount() > 0 && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClearAllFilters}
          sx={{ mb: 2 }}
        >
          Clear All {getActiveFilterCount()} Filters
        </Button>
      )}

      {viewMode === 'list' && (
        <IssueList
          issues={filteredAndSortedIssues}
          visibleColumns={visibleColumns}
          users={users}
          sortConfig={sortConfig}
          onEdit={handleEditIssue}
          columnOrder={columnOrder}
          onIssueSelect={handleIssueSelect}
          chipGroups={chipGroups}
          onSort={handleSortChange}
        />
      )}
      {viewMode === 'card' && (
        <Box sx={{ 
          display: 'flex', 
          flexWrap: 'wrap',
          gap: '16px', 
          justifyContent: 'flex-start',
          '& > *': {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '100%',
            maxWidth: '250px',
            '@media (min-width: 600px)': {
              flexBasis: 'calc(50% - 16px)',
            },
            '@media (min-width: 960px)': {
              flexBasis: 'calc(33.333% - 16px)',
            },
            '@media (min-width: 1280px)': {
              flexBasis: 'calc(25% - 16px)',
            },
          }
        }}>
          {filteredAndSortedIssues.map(renderIssueCard)}
        </Box>
      )}
      {viewMode === 'kanban' && (
        <KanbanBoard
          issues={filteredAndSortedIssues}
          onUpdateIssue={handleUpdateIssue}
          onEditIssue={handleEditIssue}
          users={users}
          visibleColumns={localVisibleKanbanColumns}
          onIssueSelect={handleIssueSelect}
          chipGroups={chipGroups}
        />
      )}

      <ColumnSelector
        open={Boolean(columnSelectorAnchorEl)}
        anchorEl={columnSelectorAnchorEl}
        onClose={handleColumnSelectorClose}
        visibleColumns={viewMode === 'kanban' ? localVisibleKanbanColumns : visibleColumns}
        viewMode={viewMode}
        columnOrder={getColumnOrder()}
        onReorder={handleColumnReorder}
        onToggleVisibility={handleToggleColumnVisibility}
        chipGroups={chipGroups}
        allKanbanColumns={kanbanColumnOrder}
      />

      <FilterLightbox
        open={isFilterLightboxOpen}
        onClose={() => setIsFilterLightboxOpen(false)}
        filters={filters}
        onFilterChange={handleFilterChange}
        onClearAllFilters={handleClearAllFilters}
        users={users}
        allTags={allTags}
        chipGroups={chipGroups}
      />

      <ImageGalleryLightbox
        open={isImageGalleryOpen}
        onClose={() => setIsImageGalleryOpen(false)}
        issues={localIssues}
        currentProject={projects.find(p => p.id === projectId) || null}
        currentUser={user!}
      />

      {projectId && isTableLightboxOpen && (
        <Dialog
          open={isTableLightboxOpen}
          onClose={() => setIsTableLightboxOpen(false)}
          fullScreen
          PaperProps={{
            style: {
              backgroundColor: 'white',
              boxShadow: 'none',
            },
          }}
        >
          <DialogContent sx={{ padding: 0, height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}>
              <IconButton onClick={() => setIsTableLightboxOpen(false)} size="large">
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <ErrorBoundary>
                <TableManager
                  projectId={projectId}
                  currentUser={currentUser}
                  onSaveTable={handleSaveTable}
                />
              </ErrorBoundary>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {projectId && isNotesLightboxOpen && (
        <NotesLightbox
          open={isNotesLightboxOpen}
          onClose={() => setIsNotesLightboxOpen(false)}
          projectId={projectId}
          currentUser={currentUser}
        />
      )}

      <Drawer
        anchor="right"
        open={isNewIssueDrawerOpen}
        onClose={handleNewIssueDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: '70%',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box sx={{ p: 3 }}>
          <IssueForm
            onSubmit={handleAddIssue}
            onCancel={handleNewIssueDrawerClose}
            users={users.filter(user => user.permissions?.canBeAssignedIssues)}
            currentUser={currentUser}
            projects={projects}
            currentProjectId={projectId}
            allTags={allTags}
            chipGroups={chipGroups}
          />
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={Boolean(selectedIssue)}
        onClose={handleCloseEditDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: '70%',
            boxSizing: 'border-box',
          },
        }}
      >
        {selectedIssue && (
          <IssueDetails
            issue={selectedIssue}
            onUpdateIssue={handleUpdateIssue}
            onDuplicateIssue={handleDuplicateIssue}
            users={users.filter(user => user.permissions?.canBeAssignedIssues)}
            currentUser={currentUser}
            onClose={handleCloseEditDrawer}
            projects={projects}
            canEdit={currentUser.permissions?.canEditIssues || false}
            allTags={allTags}
            chipGroups={chipGroups}
          />
        )}
      </Drawer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default IssueListContainer;
