import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { Editor, initTinyMCE } from '../utils/tinymceSetup';
import { Note as NoteType } from '../types';

interface NoteProps {
  note: NoteType;
  onUpdateNote: (updatedNote: NoteType) => void;
}

const Note: React.FC<NoteProps> = ({ note, onUpdateNote }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedNote, setEditedNote] = useState(note);

  useEffect(() => {
    setEditedNote(note);
  }, [note]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedNote(prevNote => ({ ...prevNote, title: event.target.value }));
  };

  const handleContentChange = (content: string) => {
    setEditedNote(prevNote => ({ ...prevNote, content }));
  };

  const handleSave = () => {
    onUpdateNote(editedNote);
    setEditMode(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  return (
    <Box p={2}>
      {editMode ? (
        <>
          <TextField
            name="title"
            label="Title"
            value={editedNote.title}
            onChange={handleTitleChange}
            fullWidth
            margin="normal"
          />
          <Editor
            init={initTinyMCE as any}
            value={editedNote.content}
            onEditorChange={handleContentChange}
          />
          <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
            Save
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 'bold', mb: 2 }}>
            {editedNote.title}
          </Typography>
          <Box sx={{ bgcolor: '#fff9c4', p: 2, borderRadius: 1 }}>
            <div 
              className="tinymce-content"
              dangerouslySetInnerHTML={{ __html: editedNote.content }} 
            />
          </Box>
          <Button variant="outlined" color="primary" onClick={handleEdit} sx={{ mt: 2 }}>
            Edit
          </Button>
        </>
      )}
    </Box>
  );
};

export default Note;