import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Snackbar,
  CircularProgress,
  TextField,
  Chip,
  Dialog as TagDialog,
  DialogTitle,
  DialogContent as TagDialogContent,
  DialogActions,
  Button,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogContentText,
  Dialog as ImageDialog,
  DialogContent as ImageDialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoIcon from '@mui/icons-material/Info';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderIcon from '@mui/icons-material/Folder';
import { useDropzone } from 'react-dropzone';
import { Issue, Project, User, GalleryImage, ProjectGalleryTag, Folder } from '../types';
import { projectGalleryService } from '../projectGalleryService';
import { userService } from '../userService';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { parseSearchQuery } from '../utils/searchParser';
import useLocalStorage from '../hooks/useLocalStorage';

interface ImageGalleryLightboxProps {
  open: boolean;
  onClose: () => void;
  issues: Issue[];
  currentProject: Project | null;
  currentUser: User;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ImageGalleryLightbox: React.FC<ImageGalleryLightboxProps> = ({
  open,
  onClose,
  issues: _issues,
  currentProject,
  currentUser,
}) => {
  const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);
  const [editingImageId, setEditingImageId] = useState<string | null>(null);
  const [editingFileName, setEditingFileName] = useState<string>('');
  const [uploading, setUploading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{open: boolean, message: string, severity: 'success' | 'error'}>({
    open: false,
    message: '',
    severity: 'success'
  });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [tagDialogOpen, setTagDialogOpen] = useState<boolean>(false);
  const [editingTags, setEditingTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState<string>('');
  const [projectTags, setProjectTags] = useState<ProjectGalleryTag[]>([]);
  const [userNames, setUserNames] = useState<{[key: string]: string}>({});
  const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = useState<string | null>(null);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [folderDialogOpen, setFolderDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hideDetails, setHideDetails] = useLocalStorage('hideGalleryDetails', false);
  const [hasGallery, setHasGallery] = useState<boolean>(false);
  const [editingFolder, setEditingFolder] = useState<Folder | null>(null);
  const [renameFolderDialogOpen, setRenameFolderDialogOpen] = useState(false);
  const [newGalleryName, setNewGalleryName] = useState('');
  const [createGalleryDialogOpen, setCreateGalleryDialogOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deletingImageId, setDeletingImageId] = useState<string | null>(null);
  const [deletingFolderId, setDeletingFolderId] = useState<string | null>(null);
  const [deleteFolderConfirmOpen, setDeleteFolderConfirmOpen] = useState(false);
  const [isRenamingFolder, setIsRenamingFolder] = useState(false);
  const [selectedImage, setSelectedImage] = useState<GalleryImage | null>(null);

  const loadProjectGalleryImages = useCallback(async () => {
    if (currentProject && currentFolder !== null) {
      setIsLoading(true);
      setGalleryImages([]); // Clear images before loading new ones
      try {
        const images = await projectGalleryService.getProjectGalleryImages(currentProject.id, currentFolder);
        setGalleryImages(images);
      } catch (error) {
        console.error('Error loading images:', error);
        setSnackbar({open: true, message: 'Error loading images', severity: 'error'});
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentProject, currentFolder]);

  useEffect(() => {
    const checkGalleryAndLoadFolders = async () => {
      if (open && currentProject) {
        setIsLoading(true);
        try {
          const galleryExists = await projectGalleryService.checkGalleryExists(currentProject.id);
          setHasGallery(galleryExists);
          if (galleryExists) {
            const projectFolders = await projectGalleryService.getProjectFolders(currentProject.id);
            setFolders(projectFolders);
          }
        } catch (error) {
          console.error('Error checking gallery or loading folders:', error);
          setSnackbar({open: true, message: 'Error loading gallery', severity: 'error'});
        } finally {
          setIsLoading(false);
        }
      }
    };

    checkGalleryAndLoadFolders();
  }, [open, currentProject]);

  useEffect(() => {
    // Clear images when changing folders
    setGalleryImages([]);
  }, [currentFolder]);

  useEffect(() => {
    const loadProjectTags = async () => {
      if (currentProject) {
        const tags = await projectGalleryService.getProjectGalleryTags(currentProject.id);
        setProjectTags(tags.map(tag => ({
          id: `${currentProject.id}_${tag}`,
          name: tag,
          projectId: currentProject.id
        })));
      }
    };
    loadProjectTags();
  }, [currentProject]);

  const userIdsToFetch = useMemo(() => {
    return [...new Set(galleryImages.map(image => image.addedBy).filter(id => !userNames[id]))];
  }, [galleryImages, userNames]);

  useEffect(() => {
    const fetchUserNames = async () => {
      const newNames: {[key: string]: string} = {};

      for (const userId of userIdsToFetch) {
        newNames[userId] = await userService.getUserName(userId);
      }

      if (Object.keys(newNames).length > 0) {
        setUserNames(prev => ({...prev, ...newNames}));
      }
    };

    if (userIdsToFetch.length > 0) {
      fetchUserNames();
    }
  }, [userIdsToFetch]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!currentProject) return;
    setUploading(true);

    try {
      for (const file of acceptedFiles) {
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const img = new Image();
            img.onload = async () => {
              const newImage: Omit<GalleryImage, 'id'> = {
                url: e.target?.result as string,
                fullSizeUrl: e.target?.result as string,
                fileName: file.name,
                fileSize: file.size,
                dimensions: { width: img.width, height: img.height },
                addedBy: currentUser.userId || '',
                addedOn: new Date().toISOString(),
                projectId: currentProject.id,
                tags: [],
              };
              const addedImage = await projectGalleryService.addProjectGalleryImage(newImage, currentFolder);
              setGalleryImages(prev => [...prev, addedImage]);
            };
            img.src = e.target?.result as string;
          };
          reader.readAsDataURL(file);
        }
      }
      setSnackbar({open: true, message: 'Images uploaded successfully', severity: 'success'});
    } catch (error) {
      console.error('Error uploading images:', error);
      setSnackbar({open: true, message: 'Error uploading images', severity: 'error'});
    } finally {
      setUploading(false);
      loadProjectGalleryImages();
    }
  }, [currentProject, currentUser, currentFolder, loadProjectGalleryImages]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDelete = async (imageId: string) => {
    setDeletingImageId(imageId);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    if (currentProject && deletingImageId) {
      try {
        await projectGalleryService.deleteProjectGalleryImage(currentProject.id, deletingImageId, currentFolder);
        setGalleryImages(prev => prev.filter(img => img.id !== deletingImageId));
        setSnackbar({open: true, message: 'Image deleted successfully', severity: 'success'});
      } catch (error) {
        console.error('Error deleting image:', error);
        setSnackbar({open: true, message: 'Error deleting image', severity: 'error'});
      }
    }
    setDeleteConfirmOpen(false);
    setDeletingImageId(null);
  };

  const handleRename = (imageId: string) => {
    const image = galleryImages.find(img => img.id === imageId);
    if (image) {
      setEditingImageId(imageId);
      setEditingFileName(image.fileName);
      setEditingTags(image.tags || []);
      setTagDialogOpen(true);
    }
  };

  const handleSaveRename = async () => {
    if (editingImageId && currentProject) {
      await projectGalleryService.updateProjectGalleryImage(currentProject.id, editingImageId, { 
        fileName: editingFileName,
        tags: editingTags
      }, currentFolder);
      setGalleryImages(prev => prev.map(img => 
        img.id === editingImageId ? { ...img, fileName: editingFileName, tags: editingTags } : img
      ));
      setEditingImageId(null);
      setTagDialogOpen(false);
    }
  };

  const handleAddTag = async () => {
    if (newTag && !editingTags.includes(newTag) && currentProject) {
      setEditingTags([...editingTags, newTag]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setEditingTags(editingTags.filter(tag => tag !== tagToRemove));
  };

  const handleViewFull = (image: GalleryImage) => {
    setSelectedImage(image);
  };

  const handleDownload = (url: string, fileName: string) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        console.error('Error downloading file:', error);
        setSnackbar({open: true, message: 'Error downloading file', severity: 'error'});
      });
  };

  const handleCreateFolder = async () => {
    if (currentProject && newFolderName && currentUser) {
      try {
        const newFolder = await projectGalleryService.createFolder(currentProject.id, newFolderName, currentUser.userId || '');
        setFolders([...folders, newFolder]);
        setNewFolderName('');
        setFolderDialogOpen(false);
        setSnackbar({open: true, message: 'Folder created successfully', severity: 'success'});
      } catch (error) {
        console.error('Error creating folder:', error);
        setSnackbar({open: true, message: 'Error creating folder', severity: 'error'});
      }
    }
  };

  const handleRenameFolder = async () => {
    if (currentProject && editingFolder && newFolderName) {
      setIsRenamingFolder(true);
      try {
        await projectGalleryService.renameFolder(currentProject.id, editingFolder.id, newFolderName);
        setFolders(folders.map(f => f.id === editingFolder.id ? {...f, name: newFolderName} : f));
        setSnackbar({open: true, message: 'Folder renamed successfully', severity: 'success'});
        
        // Reload images after renaming the folder
        if (currentFolder === editingFolder.id) {
          setCurrentFolder(newFolderName);
        }
        loadProjectGalleryImages();
      } catch (error) {
        console.error('Error renaming folder:', error);
        setSnackbar({open: true, message: 'Error renaming folder', severity: 'error'});
      } finally {
        setIsRenamingFolder(false);
        setEditingFolder(null);
        setNewFolderName('');
        setRenameFolderDialogOpen(false);
      }
    }
  };

  const handleDeleteFolder = async (folderId: string) => {
    setDeletingFolderId(folderId);
    setDeleteFolderConfirmOpen(true);
  };

  const confirmDeleteFolder = async () => {
    if (currentProject && deletingFolderId) {
      try {
        await projectGalleryService.softDeleteFolder(currentProject.id, deletingFolderId);
        // Refresh the folder list after deletion
        const updatedFolders = await projectGalleryService.getProjectFolders(currentProject.id);
        setFolders(updatedFolders);
        if (currentFolder === deletingFolderId) {
          setCurrentFolder(null);
        }
        setSnackbar({open: true, message: 'Folder deleted successfully', severity: 'success'});
      } catch (error) {
        console.error('Error deleting folder:', error);
        setSnackbar({open: true, message: 'Error deleting folder', severity: 'error'});
      }
    }
    setDeleteFolderConfirmOpen(false);
    setDeletingFolderId(null);
  };

  const handleCreateGallery = async () => {
    if (currentProject && newGalleryName) {
      try {
        await projectGalleryService.createProjectGallery(currentProject.id);
        const newFolder = await projectGalleryService.createFolder(currentProject.id, newGalleryName, currentUser.userId || '');
        setFolders([newFolder]);
        setCurrentFolder(newFolder.id);
        setHasGallery(true);
        setCreateGalleryDialogOpen(false);
        setNewGalleryName('');
        // loadProjectGalleryImages will be called automatically due to the change in currentFolder
        setSnackbar({open: true, message: 'Image Gallery created successfully', severity: 'success'});
      } catch (error) {
        console.error('Error creating image gallery:', error);
        setSnackbar({open: true, message: 'Error creating image gallery', severity: 'error'});
      }
    }
  };

  const filteredGalleryImages = useMemo(() => {
    return galleryImages.filter(image => parseSearchQuery(searchTerm, image));
  }, [galleryImages, searchTerm]);

  const handleFolderClick = (folderId: string) => {
    setCurrentFolder(folderId);
    // loadProjectGalleryImages will be called automatically due to the dependency on currentFolder
  };

  useEffect(() => {
    if (currentFolder !== null) {
      loadProjectGalleryImages();
    }
  }, [currentFolder, loadProjectGalleryImages]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
      PaperProps={{
        sx: {
          height: '90vh',
          maxHeight: '90vh',
          width: '90vw',
        },
      }}
      disableEscapeKeyDown
      onBackdropClick={(e) => e.stopPropagation()}
    >
      <DialogContent sx={{ p: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Image Gallery</Typography>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
        
        {!hasGallery ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Button
              variant="contained"
              onClick={() => setCreateGalleryDialogOpen(true)}
              startIcon={<CreateNewFolderIcon />}
            >
              Create an Image Gallery
            </Button>
          </Box>
        ) : (
          <Box display="flex" height="calc(100% - 48px)">
            <Box width="250px" mr={2} borderRight="1px solid #e0e0e0" pr={2}>
              <Button
                variant="contained"
                startIcon={<CreateNewFolderIcon />}
                onClick={() => setFolderDialogOpen(true)}
                fullWidth
                sx={{ mb: 2 }}
              >
                New Folder
              </Button>
              <List>
                {folders.map((folder) => (
                  <ListItem 
                    button 
                    key={folder.id} 
                    onClick={() => handleFolderClick(folder.id)}
                    selected={currentFolder === folder.id}
                    sx={{
                      '&:hover .folder-actions': {
                        opacity: 1,
                      },
                      pr: 8,
                      position: 'relative',
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText 
                      primary={folder.name} 
                      sx={{ 
                        '& .MuiListItemText-primary': { 
                          whiteSpace: 'normal',
                          overflow: 'visible',
                        } 
                      }}
                    />
                    <Box 
                      className="folder-actions"
                      sx={{ 
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'absolute',
                        right: 8,
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingFolder(folder);
                          setNewFolderName(folder.name);
                          setRenameFolderDialogOpen(true);
                        }}
                        size="small"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteFolder(folder.id);
                        }}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box flex={1} ml={2}>
              <Box display="flex" alignItems="center" mb={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search images (e.g., beach #sunset name:vacation)"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <IconButton onClick={() => setInfoDialogOpen(true)} size="small" sx={{ ml: 1 }}>
                  <InfoIcon />
                </IconButton>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hideDetails}
                    onChange={(e) => setHideDetails(e.target.checked)}
                  />
                }
                label="Hide details"
              />

              {currentProject && currentFolder && (
                <Box {...getRootProps()} sx={{ 
                  border: '2px dashed #cccccc', 
                  borderRadius: '4px', 
                  p: 2, 
                  mb: 2, 
                  textAlign: 'center',
                  backgroundColor: isDragActive ? '#f0f0f0' : 'transparent',
                  position: 'relative',
                }}>
                  <input {...getInputProps()} />
                  <Typography>
                    {isDragActive ? 'Drop the images here' : 'Drag and drop images here, or click to select files'}
                  </Typography>
                  {uploading && (
                    <Box sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    }}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              )}

              {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress />
                </Box>
              ) : filteredGalleryImages.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    justifyContent: 'flex-start',
                  }}
                >
                  {filteredGalleryImages.map((image) => (
                    <Box
                      key={image.id}
                      sx={{
                        width: '300px',
                        border: '1px solid #e0e0e0',
                        borderRadius: '4px',
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          width: '300px',
                          height: '200px',
                          '&:hover .image-actions': {
                            opacity: 1,
                          },
                        }}
                      >
                        <img
                          src={image.url}
                          alt={image.fileName}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                        <Box
                          className="image-actions"
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: 0,
                            transition: 'opacity 0.3s',
                          }}
                        >
                          <IconButton onClick={() => handleDelete(image.id)} size="small" sx={{ color: 'white', m: 0.5 }}>
                            <DeleteIcon />
                          </IconButton>
                          <IconButton onClick={() => handleRename(image.id)} size="small" sx={{ color: 'white', m: 0.5 }}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDownload(image.url, image.fileName)} size="small" sx={{ color: 'white', m: 0.5 }}>
                            <DownloadIcon />
                          </IconButton>
                          <IconButton onClick={() => handleViewFull(image)} size="small" sx={{ color: 'white', m: 0.5 }}>
                            <VisibilityIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      {!hideDetails && (
                        <Box sx={{ p: 1 }}>
                          <Typography variant="subtitle2" noWrap>{image.fileName}</Typography>
                          <Typography variant="body2">Size: {(image.fileSize / 1024).toFixed(2)} KB</Typography>
                          <Typography variant="body2">Dimensions: {image.dimensions.width}x{image.dimensions.height}</Typography>
                          <Typography variant="caption">By: {userNames[image.addedBy] || 'Unknown User'}</Typography>
                          <Typography variant="caption" display="block">Added: {new Date(image.addedOn).toLocaleString()}</Typography>
                          {image.tags && image.tags.length > 0 && (
                            <Box sx={{ mt: 1 }}>
                              {image.tags.map((tag, index) => (
                                <Chip key={index} label={tag} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                              ))}
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography variant="body1" textAlign="center" mt={4}>
                  {currentFolder ? "No images found in this folder." : "Please select a folder to view images."}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </DialogContent>

      <TagDialog 
        open={tagDialogOpen} 
        onClose={() => setTagDialogOpen(false)}
        PaperProps={{
          sx: { width: '500px' }
        }}
      >
        <DialogTitle>Edit Image Details</DialogTitle>
        <TagDialogContent>
          <TextField
            fullWidth
            label="File Name"
            value={editingFileName}
            onChange={(e) => setEditingFileName(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              sx: { fontSize: '0.9rem' }
            }}
          />
          <Box sx={{ mb: 2 }}>
            {editingTags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleRemoveTag(tag)}
                sx={{ mr: 0.5, mb: 0.5 }}
              />
            ))}
          </Box>
          <Autocomplete
            freeSolo
            options={projectTags.map(tag => tag.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add Tag"
                fullWidth
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddTag();
                  }
                }}
              />
            )}
            onChange={(_, value) => {
              if (value && !editingTags.includes(value)) {
                setEditingTags([...editingTags, value]);
                setNewTag('');
              }
            }}
          />
        </TagDialogContent>
        <DialogActions>
          <Button onClick={() => setTagDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveRename} variant="contained">Save</Button>
        </DialogActions>
      </TagDialog>
    
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({...snackbar, open: false})}>
        <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)}>
        <DialogTitle>Advanced Search Tips</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            You can use advanced search operators to refine your search:
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Basic Search:</strong>
            <br />
            - Type any word to search in filenames and tags
            <br />
            - Example: beach sunset
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Tag Search:</strong>
            <br />
            - Use # to search for tags
            <br />
            - Example: #summer #beach
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Filename Search:</strong>
            <br />
            - Use name: to search specifically in filenames
            <br />
            - Example: name:vacation
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Date Search:</strong>
            <br />
            - Use date: for exact date, date:&gt; for after, date:&lt; for before
            <br />
            - Example: date:2023-01-01, date:&gt;2023-01-01, date:&lt;2023-01-01
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Combining Searches:</strong>
            <br />
            - Use spaces to combine multiple search terms
            <br />
            - Example: beach #summer name:vacation date:&gt;2023-01-01
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={folderDialogOpen} onClose={() => setFolderDialogOpen(false)}>
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFolderDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateFolder} variant="contained">Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={renameFolderDialogOpen} onClose={() => setRenameFolderDialogOpen(false)}>
        <DialogTitle>Rename Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            disabled={isRenamingFolder}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameFolderDialogOpen(false)} disabled={isRenamingFolder}>Cancel</Button>
          <Button 
            onClick={handleRenameFolder} 
            variant="contained" 
            disabled={isRenamingFolder || !newFolderName.trim()}
          >
            {isRenamingFolder ? <CircularProgress size={24} /> : 'Rename'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={createGalleryDialogOpen} onClose={() => setCreateGalleryDialogOpen(false)}>
        <DialogTitle>Create New Image Gallery</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Gallery Name"
            fullWidth
            value={newGalleryName}
            onChange={(e) => setNewGalleryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateGalleryDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateGallery} variant="contained" disabled={!newGalleryName.trim()}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this image? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteFolderConfirmOpen}
        onClose={() => setDeleteFolderConfirmOpen(false)}
        aria-labelledby="delete-folder-dialog-title"
        aria-describedby="delete-folder-dialog-description"
      >
        <DialogTitle id="delete-folder-dialog-title">{"Confirm Folder Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-folder-dialog-description">
            Are you sure you want to delete this folder? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteFolderConfirmOpen(false)}>Cancel</Button>
          <Button onClick={confirmDeleteFolder} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ImageDialog
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        maxWidth={false}
      >
        <ImageDialogContent sx={{ p: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {selectedImage && (
            <img
              src={selectedImage.fullSizeUrl || selectedImage.url}
              alt={selectedImage.fileName}
              style={{
                maxWidth: '100%',
                maxHeight: '90vh',
                objectFit: 'contain',
              }}
            />
          )}
        </ImageDialogContent>
      </ImageDialog>
    </Dialog>
  );
};

export default ImageGalleryLightbox;