import React, { useState } from 'react';
import { Box, TextField, Button, Avatar, Typography } from '@mui/material';
import { User } from '../types';
import { userService } from '../userService';

interface UserProfileProps {
  user: User;
  onUpdateUser: (updatedUser: User) => void;
}

const UserProfile: React.FC<UserProfileProps> = ({ user, onUpdateUser }) => {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      let avatarUrl = user.avatarUrl;
      if (avatarFile) {
        avatarUrl = await userService.uploadAvatar(avatarFile);
      }

      if (user.userId) {
        const updatedUserData = {
          firstName,
          lastName,
          avatarUrl,
        };
        await userService.updateUserData(user.userId, updatedUserData);
        onUpdateUser({ ...user, ...updatedUserData });

        if (newPassword && currentPassword) {
          await userService.updateUserPassword(user.email, currentPassword, newPassword);
        }

        alert('Profile updated successfully');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setAvatarFile(e.target.files[0]);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>My Profile</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar src={avatarFile ? URL.createObjectURL(avatarFile) : user.avatarUrl} sx={{ width: 100, height: 100, mr: 2 }} />
        <Button variant="contained" component="label">
          Upload Avatar
          <input type="file" hidden onChange={handleAvatarChange} accept="image/*" />
        </Button>
      </Box>
      <TextField
        fullWidth
        margin="normal"
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        type="password"
        label="Current Password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        type="password"
        label="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        type="password"
        label="Confirm New Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Update Profile
      </Button>
    </Box>
  );
};

export default UserProfile;