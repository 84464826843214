import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Lightbox from './Lightbox';

interface CongratulationsLightboxProps {
  onClose: () => void;
}

const CongratulationsLightbox: React.FC<CongratulationsLightboxProps> = ({ onClose }) => {
  return (
    <Lightbox onClose={onClose}>
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <Typography variant="h5" gutterBottom>
          <strong>Congratulations! You Completed All Of The Tasks!</strong>
        </Typography>
        <Box sx={{ my: 2 }}>
          <img src="/congratulations.gif" alt="Congratulations" style={{ maxWidth: '100%' }} />
        </Box>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Lightbox>
  );
};

export default CongratulationsLightbox;