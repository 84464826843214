import { GalleryImage } from '../types';

export function parseSearchQuery(query: string, image: GalleryImage): boolean {
  if (!query.trim()) return true;

  const terms = query.toLowerCase().split(/\s+/);
  return terms.every(term => matchTerm(term, image));
}

function matchTerm(term: string, image: GalleryImage): boolean {
  if (term.startsWith('#')) {
    const tagValue = term.slice(1);
    return image.tags?.some(tag => tag.toLowerCase().includes(tagValue));
  } else if (term.startsWith('name:')) {
    const filenameValue = term.slice(5);
    return image.fileName.toLowerCase().includes(filenameValue);
  } else if (term.startsWith('date:')) {
    return matchDate(term.slice(5), image.addedOn);
  } else {
    return image.fileName.toLowerCase().includes(term) ||
           image.tags?.some(tag => tag.toLowerCase().includes(term)) ||
           false;
  }
}

function matchDate(dateQuery: string, imageDate: string): boolean {
  const date = new Date(imageDate);
  if (dateQuery.startsWith('>')) {
    return date > new Date(dateQuery.slice(1));
  } else if (dateQuery.startsWith('<')) {
    return date < new Date(dateQuery.slice(1));
  } else {
    // Exact date match
    const queryDate = new Date(dateQuery);
    return date.toDateString() === queryDate.toDateString();
  }
}