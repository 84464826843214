import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export const uploadImageForTinyMCE = async (blobInfo: any, progress: (percent: number) => void): Promise<string> => {
  const file = blobInfo.blob();
  const fileName = blobInfo.filename();
  const folderPath = fileName.includes('comment') ? 'tinymce_comments_files/' : 'tinymce_desc_files/';
  const fullPath = `${folderPath}${Date.now()}_${fileName}`;
  const storageRef = ref(storage, fullPath);

  try {
    const snapshot = await uploadBytes(storageRef, file);
    progress(100);
    return await getDownloadURL(snapshot.ref);
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const uploadVideoForTinyMCE = async (blobInfo: any, progress: (percent: number) => void): Promise<string> => {
  const file = blobInfo.blob();
  const fileName = blobInfo.filename();
  const folderPath = 'tinymce_videos/';
  const fullPath = `${folderPath}${Date.now()}_${fileName}`;
  const storageRef = ref(storage, fullPath);

  try {
    const snapshot = await uploadBytes(storageRef, file);
    progress(100);
    return await getDownloadURL(snapshot.ref);
  } catch (error) {
    console.error('Error uploading video:', error);
    throw error;
  }
};

export const uploadImage = async (file: File): Promise<string> => {
  const storageRef = ref(storage, `note_images/${file.name}`);
  await uploadBytes(storageRef, file);
  return getDownloadURL(storageRef);
};