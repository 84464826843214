import React from 'react';
import { TextField, InputAdornment as MuiInputAdornment } from '@mui/material';

interface SearchIssuesProps {
  onSearch: (searchTerm: string) => void;
  placeholder?: string;
  startAdornment?: React.ReactNode;
}

const SearchIssues: React.FC<SearchIssuesProps> = ({ onSearch, placeholder, startAdornment }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      placeholder={placeholder || "Search issues..."}
      onChange={(e) => onSearch(e.target.value)}
      InputProps={{
        startAdornment: startAdornment ? (
          <MuiInputAdornment position="start">
            {startAdornment}
          </MuiInputAdornment>
        ) : undefined,
      }}
    />
  );
};

export default SearchIssues;