import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Tabs, Tab, List, ListItem, Button, Typography, Box, Divider, IconButton, Tooltip, Link } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Notification } from '../types';
import { notificationsService } from '../services/notificationsService';
import { generateIssueLink } from '../utils/linkUtils';

interface NotificationCenterProps {
  open: boolean;
  onClose: () => void;
  notifications: Notification[];
  onDismiss: (notificationId: string) => void;
  userId: string;
}

const NotificationCenter: React.FC<NotificationCenterProps> = ({ open, onClose, notifications, onDismiss, userId }) => {
  const [activeTab, setActiveTab] = useState(0); // Set default to "Current" (index 0)

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const filteredNotifications = () => {
    const filtered = notifications.filter(n => !n.isDeleted);
    switch (activeTab) {
      case 0: // Current
        return filtered.filter(n => n.status === 'current');
      case 1: // Dismissed
        return filtered.filter(n => n.status === 'dismissed');
      case 2: // All
        return filtered;
      default:
        return filtered.filter(n => n.status === 'current');
    }
  };

  const handleDismiss = async (notificationId: string) => {
    await notificationsService.dismissNotification(userId, notificationId);
    onDismiss(notificationId);
  };

  const handleDelete = async (notificationId: string) => {
    await notificationsService.deleteNotification(userId, notificationId);
    onDismiss(notificationId); // Assuming onDismiss can handle both dismissal and deletion
  };

  const handleDismissAll = async () => {
    const currentNotifications = filteredNotifications();
    for (const notification of currentNotifications) {
      await handleDismiss(notification.id);
    }
  };

  const handleDeleteAll = async () => {
    const dismissedNotifications = filteredNotifications();
    for (const notification of dismissedNotifications) {
      await handleDelete(notification.id);
    }
  };

  const currentNotifications = notifications.filter(n => n.status === 'current' && !n.isDeleted);
  const dismissedNotifications = notifications.filter(n => n.status === 'dismissed' && !n.isDeleted);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Notifications</Typography>
          {activeTab === 0 && currentNotifications.length > 0 && (
            <Button
              onClick={handleDismissAll}
              sx={{
                color: 'purple',
                borderColor: 'purple',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(128, 0, 128, 0.1)',
                },
              }}
              variant="outlined"
            >
              Dismiss All
            </Button>
          )}
          {activeTab === 1 && dismissedNotifications.length > 0 && (
            <Button
              onClick={handleDeleteAll}
              sx={{
                color: 'purple',
                borderColor: 'purple',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(128, 0, 128, 0.1)',
                },
              }}
              variant="outlined"
            >
              Delete All
            </Button>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Current" />
          <Tab label="Dismissed" />
          <Tab label="All" />
        </Tabs>
        <List>
          {filteredNotifications().length === 0 ? (
            <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
              No notifications to display.
            </Typography>
          ) : (
            filteredNotifications().map((notification, index, array) => (
              <React.Fragment key={notification.id}>
                <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start', position: 'relative', py: 2 }}>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Link
                      href={generateIssueLink(notification.projectId, notification.issueId)}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ textDecoration: 'none' }}
                    >
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          fontWeight: 500, 
                          color: '#20b2aa',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {notification.issueTitle}
                      </Typography>
                    </Link>
                    <Box>
                      {notification.status === 'current' && (
                        <Button 
                          onClick={() => handleDismiss(notification.id)} 
                          color="secondary"
                          sx={{ mr: 1 }}
                        >
                          Dismiss
                        </Button>
                      )}
                      {notification.status === 'dismissed' && (
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleDelete(notification.id)} size="small">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                  <Typography variant="body2">
                    Project ID: {notification.projectId} | Issue ID: {notification.issueId}
                  </Typography>
                  <Typography variant="body2">
                    Issue updated by: {notification.updatedByUserName}
                  </Typography>
                  <Typography variant="body2">
                    Notification time: {new Date(notification.timestamp).toLocaleString()}
                    {notification.status === 'dismissed' && notification.dismissedAt && (
                      ` | Dismissed on: ${new Date(notification.dismissedAt).toLocaleString()}`
                    )}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    {notification.message}
                  </Typography>
                </ListItem>
                {index < array.length - 1 && <Divider />}
              </React.Fragment>
            ))
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationCenter;