import React, { useState } from 'react';
import { Box, Typography, Chip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ChipGroup } from '../types';
import ChipsEditor from './chipsEditor'; // Change this line to use lowercase 'c'
import { getTextColor } from '../utils/colorUtils';

interface IssueHeaderProps {
  chipGroups: ChipGroup[];
  onChipGroupsUpdate: (updatedChipGroups: ChipGroup[]) => Promise<void>;
  chipCounts: { [groupId: string]: { [chipId: string]: number } };
  projectId: string; // Add this line to include projectId
}

const IssueHeader: React.FC<IssueHeaderProps> = ({
  chipGroups,
  onChipGroupsUpdate,
  chipCounts,
  projectId, // Add this line
}) => {
  const [editingGroupId, setEditingGroupId] = useState<string | null>(null);

  const handleEditClick = (groupId: string) => {
    console.log('Edit clicked for group:', groupId);
    setEditingGroupId(groupId);
  };

  const handleChipGroupUpdate = async (updatedGroup: ChipGroup) => {
    const updatedChipGroups = chipGroups.map(group => 
      group.id === updatedGroup.id ? updatedGroup : group
    );
    await onChipGroupsUpdate(updatedChipGroups);
    setEditingGroupId(null);
  };

  const renderChipGroup = (group: ChipGroup, position: 'left' | 'middle' | 'right') => (
    <Box key={group.id} sx={{ flex: 1, mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography variant="h6">{group.name}</Typography>
        <IconButton size="small" onClick={() => handleEditClick(group.id)}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: position === 'right' ? 'flex-end' : position === 'middle' ? 'center' : 'flex-start' }}>
        {group.chips.map((chip) => (
          <Chip
            key={chip.id}
            label={`${chip.name} (${chipCounts[group.id]?.[chip.id] || 0})`}
            style={{ 
              backgroundColor: chip.color,
              color: getTextColor(chip.color)
            }}
          />
        ))}
      </Box>
      {editingGroupId === group.id && (
        <ChipsEditor
          open={true}
          onClose={() => setEditingGroupId(null)}
          projectId={projectId}
          groupId={group.id}
          onUpdate={handleChipGroupUpdate}
          chipGroup={group}
        />
      )}
    </Box>
  );

  const leftGroup = chipGroups.find(group => group.id === 'Group1');
  const middleGroup = chipGroups.find(group => group.id === 'Group2');
  const rightGroup = chipGroups.find(group => group.id === 'Status');

  return (
    <Box sx={{ mb: 4, p: 2, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {leftGroup && renderChipGroup(leftGroup, 'left')}
        {middleGroup && renderChipGroup(middleGroup, 'middle')}
        {rightGroup && renderChipGroup(rightGroup, 'right')}
      </Box>
      {editingGroupId && (
        <ChipsEditor
          open={true}
          onClose={() => setEditingGroupId(null)}
          projectId={projectId} // Use the projectId prop here
          groupId={editingGroupId}
          onUpdate={handleChipGroupUpdate}
          chipGroup={chipGroups.find(group => group.id === editingGroupId) || null}
        />
      )}
    </Box>
  );
};

export default IssueHeader;