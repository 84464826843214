import React, { useState, useEffect } from 'react';
import { userService } from '../userService';
import { Role } from '../types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';

interface RoleManagementProps {
  onClose: () => void;
}

const RoleManagement: React.FC<RoleManagementProps> = ({ onClose }) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [editingRole, setEditingRole] = useState<Role | null>(null);
  const [newRole, setNewRole] = useState<Omit<Role, 'roleId' | 'createdOn' | 'modifiedOn'>>({
    name: '',
    defaultPermissions: {
      canEditIssues: false,
      canCreateIssues: false,
      canBeAssignedIssues: false,
      canEditProjectSettings: false,
      canViewPrivateIssues: false,
    }
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const fetchedRoles = await userService.getAllRoles();
      setRoles(fetchedRoles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleCreateRole = async () => {
    try {
      await userService.createRole(newRole);
      fetchRoles();
      setIsDialogOpen(false);
      setNewRole({
        name: '',
        defaultPermissions: {
          canEditIssues: false,
          canCreateIssues: false,
          canBeAssignedIssues: false,
          canEditProjectSettings: false,
          canViewPrivateIssues: false,
        }
      });
    } catch (error) {
      console.error('Error creating role:', error);
    }
  };

  const handleUpdateRole = async () => {
    if (editingRole) {
      try {
        await userService.updateRole(editingRole.roleId!, editingRole);
        fetchRoles();
        setIsDialogOpen(false);
        setEditingRole(null);
      } catch (error) {
        console.error('Error updating role:', error);
      }
    }
  };

  const handleDeleteRole = async (roleId: string) => {
    try {
      await userService.deleteRole(roleId);
      fetchRoles();
    } catch (error) {
      console.error('Error deleting role:', error);
    }
  };

  const renderPermissionCheckboxes = (role: Role | Omit<Role, 'roleId' | 'createdOn' | 'modifiedOn'>) => {
    return Object.entries(role.defaultPermissions).map(([key, value]) => (
      <FormControlLabel
        key={key}
        control={
          <Checkbox
            checked={value}
            onChange={(e) => {
              const updatedPermissions = { ...role.defaultPermissions, [key]: e.target.checked };
              if ('roleId' in role) {
                setEditingRole({ ...role, defaultPermissions: updatedPermissions });
              } else {
                setNewRole({ ...role, defaultPermissions: updatedPermissions });
              }
            }}
          />
        }
        label={key.split(/(?=[A-Z])/).join(' ')}
      />
    ));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Role Management</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsDialogOpen(true)}
        sx={{ mb: 2 }}
      >
        Add New Role
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role) => (
              <TableRow key={role.roleId}>
                <TableCell>{role.name}</TableCell>
                <TableCell>
                  <IconButton onClick={() => {
                    setEditingRole(role);
                    setIsDialogOpen(true);
                  }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteRole(role.roleId!)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>{editingRole ? 'Edit Role' : 'Create New Role'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Role Name"
            fullWidth
            value={editingRole ? editingRole.name : newRole.name}
            onChange={(e) => editingRole 
              ? setEditingRole({...editingRole, name: e.target.value})
              : setNewRole({...newRole, name: e.target.value})
            }
          />
          {renderPermissionCheckboxes(editingRole || newRole)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button onClick={editingRole ? handleUpdateRole : handleCreateRole}>
            {editingRole ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RoleManagement;
