import { db } from '../firebaseConfig';
import { collection, doc, setDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { TableData, User } from '../types';
import { v4 as uuidv4 } from 'uuid';

const TABLES_COLLECTION = 'tables';

export const tableService = {
  async getProjectTables(projectId: string): Promise<TableData[]> {
    const tablesQuery = query(
      collection(db, TABLES_COLLECTION),
      where('projectId', '==', projectId),
      where('isDeleted', '==', false)
    );
    const querySnapshot = await getDocs(tablesQuery);
    return querySnapshot.docs.map(doc => doc.data() as TableData);
  },

  async createTable(projectId: string, tableName: string, rows: number, columns: number, currentUser: User): Promise<TableData> {
    const tableId = uuidv4();
    const columnOrder = Array.from({ length: columns }, (_, i) => `Column ${i + 1}`);
    const data = Array.from({ length: rows }, () => 
      Object.fromEntries(columnOrder.map(col => [col, '']))
    );
    const newTableData: TableData = {
      tableId,
      tableName,
      projectId,
      data,
      columnOrder,
      columnWidths: Object.fromEntries(columnOrder.map(col => [col, 150])),
      columnTypes: Object.fromEntries(columnOrder.map(col => [col, 'Text'])), // Add this line
      columnFormulas: {}, // Add this line
      columnTextWrap: Object.fromEntries(columnOrder.map(col => [col, false])), // Add this line
      createdBy: currentUser.userId || '',
      createdByName: `${currentUser.firstName} ${currentUser.lastName}`,
      createdOn: new Date().toISOString(),
      isDeleted: false,
    };
    await setDoc(doc(collection(db, TABLES_COLLECTION), tableId), newTableData);
    return newTableData;
  },

  async updateTable(tableId: string, updatedData: Partial<TableData>): Promise<void> {
    const tableDoc = doc(collection(db, TABLES_COLLECTION), tableId);
    await updateDoc(tableDoc, updatedData);
  },

  async deleteTable(tableId: string): Promise<void> {
    const tableDoc = doc(collection(db, TABLES_COLLECTION), tableId);
    await updateDoc(tableDoc, { 
      isDeleted: true, 
      deletedAt: new Date().toISOString() 
    });
  },
};

export const projectTableService = tableService;