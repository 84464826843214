import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Delete as DeleteIcon, InsertDriveFile as FileIcon } from '@mui/icons-material';

interface FilePreviewProps {
  file: File | string;
  onRemove?: () => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file, onRemove }) => {
  const isFileObject = file instanceof File;
  const fileName = isFileObject ? file.name : file.split('/').pop() || 'Unknown file';
  const fileType = isFileObject ? file.type : fileName.split('.').pop()?.toLowerCase();

  const handleFileClick = () => {
    if (!isFileObject) {
      window.open(file, '_blank');
    }
  };

  const renderPreview = () => {
    if (fileType?.startsWith('image/') || /\.(jpg|jpeg|png|gif)$/i.test(fileName)) {
      return (
        <img 
          src={isFileObject ? URL.createObjectURL(file) : file as string} 
          alt={fileName} 
          style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }} 
          onClick={handleFileClick}
        />
      );
    } else {
      return <FileIcon style={{ fontSize: 48, cursor: 'pointer' }} onClick={handleFileClick} />;
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" margin={1}>
      {renderPreview()}
      <Typography variant="caption" align="center" sx={{ mt: 1, maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {fileName}
      </Typography>
      {onRemove && (
        <IconButton onClick={onRemove} size="small" sx={{ mt: 1 }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default FilePreview;