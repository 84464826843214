import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IssueHeader from './components/IssueHeader';
import IssueListContainer from './components/IssueListContainer';
import UserManagement from './components/UserManagement';
import RoleManagement from './components/RoleManagement';
import { Issue, User, Role, Project, ChipGroup, IssueFormData } from './types';
import { getChipCounts } from './utils';
import { issueService } from './issueService';
import { userService } from './userService';
import { Box, Drawer, Snackbar, Alert, Container } from '@mui/material';
import { projectService } from './projectService';
import IssueDetails from './components/IssueDetails';
import { useAuth } from './AppContent';
import { chipsService } from './services/chipsService';
import { fileService } from './fileService';
import { canViewPrivateIssue } from './utils/permissionUtils';

interface IssuePageProps {
  selectedProject: Project | null;
  selectedIssueId: string | null;
  onIssueSelect: (issueId: string | null) => void;
}

const IssuePage: React.FC<IssuePageProps> = ({ selectedProject, selectedIssueId, onIssueSelect }) => {
  const [issues, setIssues] = useState<Issue[]>([]);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [showUserManagement, setShowUserManagement] = useState(false);
  const [showRoleManagement, setShowRoleManagement] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedIssue, setSelectedIssue] = useState<Issue | null>(null);
  const [allTags, setAllTags] = useState<string[]>([]);
  const { user } = useAuth();
  const [chipGroups, setChipGroups] = useState<ChipGroup[]>([]);

  // Add this function to handle chip groups update
  const handleChipGroupsUpdate = async (updatedChipGroups: ChipGroup[]) => {
    setChipGroups(updatedChipGroups);
    if (selectedProject?.id) {
      try {
        await chipsService.updateChipGroups(selectedProject.id, updatedChipGroups);
        setSnackbar({ open: true, message: 'Chip groups updated successfully', severity: 'success' });
      } catch (error) {
        console.error('Error updating chip groups:', error);
        setSnackbar({ open: true, message: 'Failed to update chip groups', severity: 'error' });
      }
    }
  };

  useEffect(() => {
    const fetchChipGroups = async () => {
      if (selectedProject?.id) {
        try {
          const groups = await chipsService.getChipGroups(selectedProject.id);
          console.log('Fetched chip groups in IssuePage:', JSON.stringify(groups, null, 2));
          setChipGroups(groups);
        } catch (error) {
          console.error('Error fetching chip groups:', error);
          setSnackbar({ open: true, message: 'Failed to fetch chip groups', severity: 'error' });
        }
      }
    };
    fetchChipGroups();
  }, [selectedProject]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = JSON.parse(localStorage.getItem('user') || 'null');
      if (!user) {
        navigate('/login');
      } else {
        const updatedUser = await userService.getUserByEmail(user.email);
        if (updatedUser) {
          setCurrentUser(updatedUser);
          localStorage.setItem('user', JSON.stringify(updatedUser));
        } else {
          setCurrentUser(user);
        }
        fetchUsers();
        fetchRoles();
        fetchIssues(selectedProject?.id || null);
      }
    };
    fetchCurrentUser();
  }, [navigate, selectedProject]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const fetchedProjects = await projectService.getProjects();
        setProjects(fetchedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setSnackbar({ open: true, message: 'Failed to fetch projects', severity: 'error' });
      }
    };
    fetchProjects();
  }, []);

  const fetchIssues = async (projectId: string | null) => {
    try {
      const fetchedIssues = projectId 
        ? await issueService.getIssuesByProject(projectId)
        : await issueService.getIssues();
      // Filter out deleted issues
      const activeIssues = fetchedIssues.filter(issue => !issue.isDeleted);
      setIssues(activeIssues);

      // Extract all unique tags from the fetched issues
      const tags = Array.from(new Set(activeIssues.flatMap(issue => issue.tags || [])));
      setAllTags(tags);
    } catch (error) {
      console.error("Error fetching issues:", error);
      setSnackbar({ 
        open: true, 
        message: error instanceof Error ? error.message : 'Failed to fetch issues. Please try again.', 
        severity: 'error' 
      });
    }
  };

  const fetchRoles = async () => {
    try {
      const fetchedRoles = await userService.getAllRoles();
      setRoles(fetchedRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setSnackbar({ open: true, message: 'Failed to fetch roles. Please try again.', severity: 'error' });
    }
  };

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await userService.getAllUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
      setSnackbar({ open: true, message: 'Failed to fetch users. Please try again.', severity: 'error' });
    }
  };

  const handleUpdateIssue = async (updatedIssue: Issue) => {
    try {
      const issueWithEditInfo = {
        ...updatedIssue,
        lastEditedBy: currentUser?.userId || '',
        lastEditedByName: currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : 'Unknown',
        createdByName: updatedIssue.createdByName || 'Unknown', // Add this line for backward compatibility
      };
      await issueService.updateIssue(issueWithEditInfo);
      if (issueWithEditInfo.isDeleted) {
        setIssues(prevIssues => prevIssues.filter(issue => issue.id !== issueWithEditInfo.id));
        setSnackbar({ open: true, message: 'Issue deleted successfully', severity: 'success' });
      } else {
        setIssues(prevIssues => prevIssues.map(issue => 
          issue.id === issueWithEditInfo.id ? issueWithEditInfo : issue
        ));
      }
      if (selectedIssue && selectedIssue.id === issueWithEditInfo.id) {
        setSelectedIssue(issueWithEditInfo);
      }
    } catch (error) {
      console.error("Error updating issue:", error);
      setSnackbar({ open: true, message: 'Failed to update issue', severity: 'error' });
    }
  };

  const handleAddIssue = async (issueData: IssueFormData, files: File[]): Promise<Issue> => {
    try {
      console.log('Received issue data:', issueData);

      const newIssueData: Omit<Issue, 'id' | 'createdOn' | 'lastUpdated'> = {
        ...issueData,
        conversations: [],
        isDeleted: false,
        createdBy: user?.userId || '',
        createdByName: `${user?.firstName} ${user?.lastName}`,
        lastEditedBy: user?.userId || '',
        lastEditedByName: `${user?.firstName} ${user?.lastName}`,
      };

      console.log('New issue data before adding:', newIssueData);

      const createdIssue = await issueService.addIssue(newIssueData as IssueFormData);

      if (files.length > 0) {
        const uploadedFileUrls = await Promise.all(
          files.map(file => fileService.uploadFile(file, createdIssue.id))
        );
        createdIssue.attachments = [...createdIssue.attachments, ...uploadedFileUrls];
        await issueService.updateIssue({
          ...createdIssue,
          attachments: createdIssue.attachments
        });
      }

      setIssues(prevIssues => [...prevIssues, createdIssue]);
      return createdIssue;
    } catch (error) {
      console.error('Error creating issue:', error);
      throw error;
    }
  };

  const handleDuplicateIssue = async (issueData: Omit<Issue, 'id' | 'createdOn' | 'lastUpdated'>) => {
    try {
      const newIssueData: IssueFormData = {
        title: `Copy - ${issueData.title}`,
        description: issueData.description,
        assignedTo: issueData.assignedTo,
        attachments: issueData.attachments as string[],
        projectId: issueData.projectId,
        tasks: issueData.tasks,
        tags: issueData.tags,
        chipGroups: issueData.chipGroups || {},
        createdBy: user?.userId || '',
        createdByName: user ? `${user.firstName} ${user.lastName}` : '',
        lastEditedBy: user?.userId || '',
        lastEditedByName: user ? `${user.firstName} ${user.lastName}` : '',
        isPrivate: issueData.isPrivate,
      };

      console.log('Duplicating issue with data:', newIssueData);

      const createdIssue = await issueService.addIssue(newIssueData);
      setIssues(prevIssues => [...prevIssues, createdIssue]);
      setSnackbar({ open: true, message: 'Issue duplicated successfully', severity: 'success' });
      return createdIssue;
    } catch (error) {
      console.error('Error duplicating issue:', error);
      setSnackbar({ open: true, message: 'Failed to duplicate issue', severity: 'error' });
      throw error;
    }
  };

  useEffect(() => {
    const fetchSelectedIssue = async () => {
      if (selectedIssueId && currentUser) {  // Add a check for currentUser
        try {
          const issue = await issueService.getIssue(selectedIssueId);
          if (issue.isPrivate && !canViewPrivateIssue(currentUser, issue)) {
            setSnackbar({ open: true, message: 'You do not have permission to view this private issue', severity: 'error' });
            setSelectedIssue(null);
            onIssueSelect(null);
          } else {
            setSelectedIssue(issue);
          }
        } catch (error) {
          console.error("Error fetching issue:", error);
          setSnackbar({ open: true, message: 'Failed to fetch issue', severity: 'error' });
        }
      } else {
        setSelectedIssue(null);
      }
    };

    fetchSelectedIssue();
  }, [selectedIssueId, currentUser]);  // Add currentUser to the dependency array

  const handleSelectIssue = (issue: Issue | null) => {
    setSelectedIssue(issue);
    onIssueSelect(issue ? issue.id : null);
  };

  const handleUserUpdate = async (updatedUser: User) => {
    if (currentUser && updatedUser.userId === currentUser.userId) {
      const refreshedUser = await userService.getUserByEmail(updatedUser.email);
      if (refreshedUser) {
        setCurrentUser(refreshedUser);
        localStorage.setItem('user', JSON.stringify(refreshedUser));
      }
    }
    setUsers(prevUsers => prevUsers.map(user => user.userId === updatedUser.userId ? updatedUser : user));
  };

  const handleApproveUser = async (userId: string): Promise<void> => {
    try {
      await userService.approveUser(userId, ''); // TODO: Replace empty string with appropriate role
      fetchUsers();
    } catch (error) {
      console.error('Error approving user:', error);
      throw error;
    }
  };

  return (
    <Box sx={{ 
      minHeight: '100vh',
      backgroundColor: '#fff7fbc9',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Container maxWidth={false} sx={{ width: '100%', p: 0, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <IssueHeader 
          chipGroups={chipGroups}
          onChipGroupsUpdate={handleChipGroupsUpdate}
          chipCounts={getChipCounts(issues, chipGroups)}
          projectId={selectedProject?.id || ''}
        />
        
        {currentUser && (
          <IssueListContainer
            issues={issues}
            users={users}
            onUpdateIssue={handleUpdateIssue}
            onAddIssue={handleAddIssue}
            currentUser={currentUser}
            projectId={selectedProject?.id || null}
            projects={projects}
            onSelectIssue={handleSelectIssue}
            allTags={allTags}
            selectedIssue={selectedIssue}
            chipGroups={chipGroups}
          />
        )}

        {selectedIssue && (
          <Drawer
            anchor="right"
            open={!!selectedIssue}
            onClose={() => handleSelectIssue(null)}
            sx={{
              '& .MuiDrawer-paper': {
                width: '70%',
                boxSizing: 'border-box',
              },
            }}
          >
            <IssueDetails
              issue={selectedIssue}
              onUpdateIssue={handleUpdateIssue}
              onDuplicateIssue={handleDuplicateIssue}
              users={users}
              currentUser={user!}
              onClose={() => handleSelectIssue(null)}
              canEdit={user?.permissions?.canEditIssues || false}
              projects={projects}
              allTags={allTags}
              chipGroups={chipGroups}
            />
          </Drawer>
        )}

        <Drawer
          anchor="right"
          open={showUserManagement}
          onClose={() => setShowUserManagement(false)}
          sx={{
            '& .MuiDrawer-paper': {
              width: '70%',
              boxSizing: 'border-box',
            },
          }}
        >
          <UserManagement 
            onClose={() => setShowUserManagement(false)} 
            roles={roles} 
            onUserUpdate={handleUserUpdate}
            onUserApprove={handleApproveUser}
            currentUser={currentUser}
          />
        </Drawer>

        <Drawer
          anchor="right"
          open={showRoleManagement}
          onClose={() => setShowRoleManagement(false)}
          sx={{
            '& .MuiDrawer-paper': {
              width: '70%',
              boxSizing: 'border-box',
            },
          }}
        >
          <RoleManagement onClose={() => setShowRoleManagement(false)} />
        </Drawer>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default IssuePage;
