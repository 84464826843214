import { firestore, storage } from './firebase';
import { collection, addDoc, getDocs, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Project } from './types';

const COLLECTION_NAME = 'projects';
const DEFAULT_PROJECT_ID = 'default-project-id';

export const projectService = {
  async getProjects(): Promise<Project[]> {
    const projectsCol = collection(firestore, COLLECTION_NAME);
    const projectSnapshot = await getDocs(projectsCol);
    const projects = projectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Project));
    
    // Check if default project exists in the fetched projects
    const defaultProject = projects.find(p => p.id === DEFAULT_PROJECT_ID);
    if (!defaultProject) {
      // If not, add it to the list
      const defaultProjectData = await this.getDefaultProject();
      projects.unshift(defaultProjectData);
    }
    
    return projects;
  },

  async addProject(project: Omit<Project, 'id'>): Promise<Project> {
    const docRef = await addDoc(collection(firestore, COLLECTION_NAME), { ...project, isArchived: false }); // Added isArchived
    return { id: docRef.id, ...project, isArchived: false }; // Added isArchived
  },

  async getProjectById(id: string): Promise<Project | null> {
    if (id === DEFAULT_PROJECT_ID) {
      return this.getDefaultProject();
    }
    const projectDoc = await getDoc(doc(firestore, COLLECTION_NAME, id));
    if (!projectDoc.exists()) {
      return null;
    }
    return { id: projectDoc.id, ...projectDoc.data() } as Project;
  },

  async updateProjectName(id: string, newName: string): Promise<void> {
    if (id === DEFAULT_PROJECT_ID) {
      throw new Error("Cannot update default project name");
    }
    const projectRef = doc(firestore, COLLECTION_NAME, id);
    await updateDoc(projectRef, { name: newName });
  },

  async updateProjectServiceProvider(id: string, serviceProvider: string): Promise<void> {
    if (id === DEFAULT_PROJECT_ID) {
      throw new Error("Cannot update default project service provider");
    }
    const projectRef = doc(firestore, COLLECTION_NAME, id);
    await updateDoc(projectRef, { serviceProvider: serviceProvider });
  },

  async getDefaultProject(): Promise<Project> {
    const defaultProjectRef = doc(firestore, COLLECTION_NAME, DEFAULT_PROJECT_ID);
    const defaultProjectDoc = await getDoc(defaultProjectRef);
    
    if (!defaultProjectDoc.exists()) {
      const defaultProject: Project = {
        id: DEFAULT_PROJECT_ID,
        name: "Default (No Project)",
        createdBy: "system",
        createdById: "system", // Add this line
        createdOn: new Date().toISOString(),
        isArchived: false // Added isArchived
      };
      await setDoc(defaultProjectRef, defaultProject);
      return defaultProject;
    }
    
    return { id: DEFAULT_PROJECT_ID, ...defaultProjectDoc.data() } as Project;
  },

  async ensureDefaultProjectExists(): Promise<void> {
    await this.getDefaultProject();
  },

  async archiveProject(projectId: string, archivedById: string, archivedBy: string): Promise<void> {
    const projectRef = doc(firestore, 'projects', projectId);
    await updateDoc(projectRef, {
      isArchived: true,
      archivedAt: new Date().toISOString(),
      archivedBy,
      archivedById,
    });
  },

  async createProject(name: string, userId: string, userName: string): Promise<Project> {
    const projectsRef = collection(firestore, 'projects');
    const newProject: Omit<Project, 'id'> = {
      name,
      createdBy: userName,
      createdById: userId,
      createdOn: new Date().toISOString(),
      isArchived: false,
    };
    const docRef = await addDoc(projectsRef, newProject);
    return { ...newProject, id: docRef.id };
  },

  async uploadProjectIcon(projectId: string, file: File): Promise<string> {
    const storageRef = ref(storage, `files/projects/icons/${projectId}_${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);

    // Update the project document with the icon URL
    const projectRef = doc(firestore, COLLECTION_NAME, projectId);
    await updateDoc(projectRef, { icon: downloadURL });

    return downloadURL;
  },

  async updateProjectIcon(projectId: string, iconUrl: string): Promise<void> {
    const projectRef = doc(firestore, COLLECTION_NAME, projectId);
    await updateDoc(projectRef, { icon: iconUrl });
  },
};

export default projectService;