import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Chip,
  Badge,
  AvatarGroup,
  Avatar,
  Tooltip,
} from '@mui/material';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Attachment as AttachmentIcon,
  Comment as CommentIcon,
} from '@mui/icons-material';
import { Issue, User, SortConfig, ChipGroup } from '../types';
import '../styles/IssueList.css';
import { getTextColor } from '../utils/colorUtils';

interface IssueListProps {
  issues: Issue[];
  visibleColumns: (keyof Issue | 'items' | string)[];
  users: User[];
  sortConfig: SortConfig;
  onEdit: (issue: Issue) => void;
  columnOrder: (keyof Issue | 'items' | string)[];
  onIssueSelect: (issue: Issue) => void;
  chipGroups: ChipGroup[];
  onSort: (column: string) => void;
}

const IssueList: React.FC<IssueListProps> = ({
  issues,
  visibleColumns,
  users,
  sortConfig,
  onEdit,
  columnOrder,
  onIssueSelect,
  chipGroups,
  onSort,
}) => {
  const stripHtml = (html: string) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const renderCell = (issue: Issue, column: keyof Issue | 'items' | string): React.ReactNode => {
    if (column === 'items') {
      return (
        <Box display="flex" alignItems="center">
          {issue.attachments && issue.attachments.length > 0 && (
            <Badge
              badgeContent={issue.attachments.length}
              color="warning"
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: 'orange',
                  color: 'white',
                },
              }}
            >
              <AttachmentIcon sx={{ color: 'gray' }} />
            </Badge>
          )}
          {issue.conversations && issue.conversations.length > 0 && (
            <Badge
              badgeContent={issue.conversations.length}
              color="primary"
              sx={{
                ml: 1,
                '& .MuiBadge-badge': {
                  backgroundColor: '#5595d4',
                  color: 'white',
                },
              }}
            >
              <CommentIcon sx={{ color: 'gray' }} />
            </Badge>
          )}
        </Box>
      );
    }

    // Render chips under their respective group columns
    const chipGroup = chipGroups.find(group => group.name === column);
    if (chipGroup) {
      const chipValue = issue.chipGroups ? issue.chipGroups[chipGroup.id] : undefined;
      const chip = chipGroup.chips.find(c => c.id === chipValue);
      if (chip) {
        const textColor = getTextColor(chip.color);
        return (
          <Chip 
            label={chip.name} 
            style={{ backgroundColor: chip.color, color: textColor }} 
          />
        );
      }
      return null;
    }

    switch (column) {
      case 'title':
        return <Typography fontWeight="bold">{issue.title}</Typography>;
      case 'description':
        return <Typography>{truncateText(stripHtml(issue.description || ''), 110)}</Typography>;
      case 'assignedTo':
        return (
          <AvatarGroup max={3} sx={{ justifyContent: 'flex-start' }}>
            {(issue.assignedTo || []).map(userId => {
              const user = users.find(u => u.userId === userId);
              return user ? (
                <Tooltip key={user.userId} title={`${user.firstName} ${user.lastName}`}>
                  <Avatar alt={user.firstName} src={user.avatarUrl} />
                </Tooltip>
              ) : null;
            })}
          </AvatarGroup>
        );
      case 'createdBy':
        {
          const user = users.find(u => u.userId === issue[column]);
          return user ? (
            <Tooltip title={`${user.firstName} ${user.lastName}`}>
              <Avatar src={user.avatarUrl} alt={user.firstName} />
            </Tooltip>
          ) : (
            <Avatar>?</Avatar>
          );
        }
      case 'createdOn':
      case 'lastUpdated':
        return new Date(issue[column] || '').toLocaleDateString();
      case 'tags':
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {(issue.tags || []).map((tag, index) => (
              <Chip key={index} label={tag} size="small" />
            ))}
          </Box>
        );
      default:
        return String((issue as any)[column] || '');
    }
  };

  const getColumnHeader = (column: keyof Issue | 'items' | string): string => {
    const headers: { [key: string]: string } = {
      id: 'ID',
      title: 'Title',
      description: 'Description',
      items: 'Items',
      type: 'Type',
      status: 'Status',
      level: 'Level',
      assignedTo: 'Assigned To',
      createdBy: 'Created By',
      createdOn: 'Created On',
      lastUpdated: 'Last Updated',
      tags: 'Tags',
    };
    return headers[column] || column;
  };

  const renderColumnHeader = (column: keyof Issue | 'items' | string) => {
    const header = getColumnHeader(column);
    const isSorted = sortConfig.key === column;

    return (
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => onSort(column)}
      >
        <Typography variant="subtitle2" component="span">
          {header}
        </Typography>
        {isSorted && (
          <Box sx={{ ml: 1 }}>
            {sortConfig.direction === 'asc' ? 
              <ArrowUpwardIcon fontSize="small" color="primary" /> : 
              <ArrowDownwardIcon fontSize="small" color="primary" />
            }
          </Box>
        )}
      </Box>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '48px' }}></TableCell>
            {columnOrder.filter(column => visibleColumns.includes(column)).map((column) => (
              <TableCell key={column}>
                {renderColumnHeader(column)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {issues.map((issue) => (
            <TableRow key={issue.id} onClick={() => onIssueSelect(issue)}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(issue);
                  }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </TableCell>
              {columnOrder.filter(column => visibleColumns.includes(column)).map((column) => (
                <TableCell key={column}>
                  {renderCell(issue, column)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IssueList;