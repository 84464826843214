import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { SketchPicker } from 'react-color';
import { ChipGroup, Chip } from '../types';
import { chipsService } from '../services/chipsService';
import { getTextColor } from '../utils/colorUtils';

interface ChipsEditorProps {
  open: boolean;
  onClose: () => void;
  projectId: string;
  groupId: string;
  onUpdate: (updatedGroup: ChipGroup) => void;
  chipGroup: ChipGroup | null;
}

const ChipsEditor: React.FC<ChipsEditorProps> = ({ open, onClose, projectId, groupId, onUpdate, chipGroup }) => {
  const [group, setGroup] = useState<ChipGroup | null>(null);
  const [newChipName, setNewChipName] = useState('');
  const [editingChip, setEditingChip] = useState<Chip | null>(null);
  const [editingColor, setEditingColor] = useState<string | null>(null);

  useEffect(() => {
    if (chipGroup && chipGroup.id === groupId) {
      setGroup(chipGroup);
    } else {
      fetchGroup();
    }
  }, [chipGroup, groupId, projectId]);

  const fetchGroup = async () => {
    try {
      const groups = await chipsService.getChipGroups(projectId);
      const correctGroup = groups.find(g => g.id === groupId);
      if (correctGroup) {
        setGroup(correctGroup);
      } else {
        console.error(`No group found with id ${groupId}`);
        onClose();
      }
    } catch (error) {
      console.error('Error fetching chip groups:', error);
      onClose();
    }
  };

  const handleSave = async () => {
    if (group) {
      await chipsService.updateChipGroups(projectId, [group]);
      onUpdate(group);
    }
    onClose();
  };

  const handleAddChip = async () => {
    if (group && newChipName.trim()) {
      try {
        await chipsService.addChip(projectId, group.id, newChipName.trim(), '#000000');
        await fetchGroup();
        setNewChipName('');
      } catch (error) {
        console.error('Error adding chip:', error);
      }
    }
  };

  const handleEditChip = (chip: Chip) => {
    setEditingChip(chip);
    setEditingColor(chip.color);
  };

  const handleUpdateChip = async () => {
    if (group && editingChip && editingColor) {
      try {
        await chipsService.updateChip(projectId, group.id, editingChip.id, { ...editingChip, color: editingColor });
        await fetchGroup();
        setEditingChip(null);
        setEditingColor(null);
      } catch (error) {
        console.error('Error updating chip:', error);
      }
    }
  };

  const handleDeleteChip = async (chipId: string) => {
    if (group) {
      try {
        await chipsService.deleteChip(projectId, group.id, chipId);
        await fetchGroup();
      } catch (error) {
        console.error('Error deleting chip:', error);
        if (error instanceof Error) {
          alert(error.message);
        }
      }
    }
  };

  const handleColorChange = (color: any) => {
    setEditingColor(color.hex);
  };

  if (!group) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Edit {group.name}
        {group.id !== 'Status' && (
          <TextField
            value={group.name}
            onChange={(e) => setGroup({ ...group, name: e.target.value })}
            fullWidth
            margin="normal"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <List>
          {group.chips.map((chip) => (
            <ListItem key={chip.id}>
              <ListItemText 
                primary={chip.name} 
                style={{ 
                  backgroundColor: chip.color,
                  color: getTextColor(chip.color),
                  padding: '5px 10px',
                  borderRadius: '4px'
                }}
              />
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: chip.color,
                  marginRight: 2,
                }}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleEditChip(chip)}>
                  <EditIcon />
                </IconButton>
                {(group.id !== 'Status' || group.chips.length > 1) && (
                  <IconButton edge="end" onClick={() => handleDeleteChip(chip.id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Box display="flex" alignItems="center" mt={2}>
          <TextField
            value={newChipName}
            onChange={(e) => setNewChipName(e.target.value)}
            placeholder="New chip name"
            fullWidth
          />
          <IconButton onClick={handleAddChip}>
            <AddIcon />
          </IconButton>
        </Box>
        {editingChip && (
          <Box mt={2}>
            <TextField
              value={editingChip.name}
              onChange={(e) => setEditingChip({ ...editingChip, name: e.target.value })}
              fullWidth
              margin="normal"
            />
            <SketchPicker
              color={editingColor || editingChip.color}
              onChangeComplete={handleColorChange}
            />
            <Button onClick={handleUpdateChip} variant="contained" color="primary" sx={{ mt: 2 }}>
              Update Chip
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChipsEditor;