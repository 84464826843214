import React, { useState, useEffect, useRef } from 'react';
import { Box, List, ListItem, ListItemText, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Snackbar, Alert, Typography, InputAdornment, Collapse, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Search as SearchIcon, Sort as SortIcon, ChevronLeft, ChevronRight, FileDownload as FileDownloadIcon } from '@mui/icons-material';
import TableComponent from './Table';
import { projectTableService } from '../services/tableService';
import { TableData, User, SortConfig } from '../types';

interface TableManagerProps {
  projectId: string;
  currentUser: User;
  initialTableData?: TableData | null;
  onSaveTable: (updatedData: TableData) => Promise<void>;
}

const TableManager: React.FC<TableManagerProps> = ({ projectId, currentUser, initialTableData, onSaveTable }) => {
  const [tables, setTables] = useState<TableData[]>([]);
  const [selectedTable, setSelectedTable] = useState<TableData | null>(initialTableData || null);
  const [isNewTableDialogOpen, setIsNewTableDialogOpen] = useState(false);
  const [newTableName, setNewTableName] = useState('');
  const [newTableColumns, setNewTableColumns] = useState('');
  const [newTableRows, setNewTableRows] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [editingTableName, setEditingTableName] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
  const [isLeftColumnCollapsed, setIsLeftColumnCollapsed] = useState(false);
  const [newTableMethod, setNewTableMethod] = useState<'upload' | 'create'>('create');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    loadTables();
  }, [projectId]);

  useEffect(() => {
    if (tables.length > 0 && !selectedTable) {
      setSelectedTable(tables[0]);
    }
  }, [tables]);

  const loadTables = async () => {
    const projectTables = await projectTableService.getProjectTables(projectId);
    setTables(projectTables);
  };

  const handleTableSelect = (table: TableData) => {
    setSelectedTable(table);
  };

  const handleUpdateTable = async (updatedData: TableData) => {
    try {
      // Remove any undefined values
      const cleanedData = JSON.parse(JSON.stringify(updatedData));
      await projectTableService.updateTable(updatedData.tableId, cleanedData);
      setSelectedTable(cleanedData);
      setTables(prevTables => prevTables.map(t => t.tableId === cleanedData.tableId ? cleanedData : t));
      showSnackbar('Table updated successfully', 'success');
      if (onSaveTable) {
        await onSaveTable(cleanedData);
      }
    } catch (error) {
      console.error('Failed to update table:', error);
      showSnackbar('Failed to update table. Please try again.', 'error');
    }
  };

  const handleCreateNewTable = async () => {
    if (newTableName && newTableColumns && newTableRows) {
      try {
        const newTable = await projectTableService.createTable(
          projectId,
          newTableName,
          parseInt(newTableRows),
          parseInt(newTableColumns),
          currentUser
        );
        setTables([...tables, newTable]);
        setIsNewTableDialogOpen(false);
        setNewTableName('');
        setNewTableColumns('');
        setNewTableRows('');
        handleTableSelect(newTable);
        showSnackbar('Table created successfully', 'success');
      } catch (error) {
        console.error('Failed to create table:', error);
        showSnackbar('Failed to create table. Please try again.', 'error');
      }
    }
  };

  const handleDeleteTable = async (tableId: string) => {
    try {
      await projectTableService.deleteTable(tableId);
      setTables(tables.filter(t => t.tableId !== tableId));
      if (selectedTable?.tableId === tableId) {
        setSelectedTable(null);
      }
      showSnackbar('Table deleted successfully', 'success');
    } catch (error) {
      console.error('Failed to delete table:', error);
      showSnackbar('Failed to delete table. Please try again.', 'error');
    }
  };

  const handleEditTableName = async (tableId: string, newName: string) => {
    try {
      await projectTableService.updateTable(tableId, { tableName: newName });
      setTables(prevTables => prevTables.map(t => t.tableId === tableId ? { ...t, tableName: newName } : t));
      setEditingTableName(null);
      showSnackbar('Table name updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update table name:', error);
      showSnackbar('Failed to update table name. Please try again.', 'error');
    }
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSortMenuOpen = () => {
    setSortConfig((prevConfig: SortConfig) => ({
      key: prevConfig.key || (selectedTable?.columnOrder[0] || ''),
      direction: prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const filteredRows = selectedTable?.data.filter(row =>
    Object.values(row).some(value =>
      value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  ) || [];

  const sortedRows = filteredRows.slice().sort((a, b) => {
    const key = sortConfig.key;
    if (key) {
      const aValue = a[key];
      const bValue = b[key];
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleCollapseToggle = () => {
    setIsLeftColumnCollapsed(prev => !prev);
  };

  const handleDownload = () => {
    if (selectedTable) {
      const headers = selectedTable.columnOrder.join('\t');
      const rows = selectedTable.data.map(row => 
        selectedTable.columnOrder.map(col => row[col]).join('\t')
      );
      const tsvContent = [headers, ...rows].join('\n');
      
      const blob = new Blob([tsvContent], { type: 'text/tab-separated-values' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${selectedTable.tableName}.tsv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        const lines = content.split('\n').filter(line => line.trim() !== '');
        const delimiter = file.name.endsWith('.tsv') ? '\t' : ',';
        const headers = lines[0].split(delimiter).map(header => header.trim());
        const rows = lines.slice(1).map(line => 
          line.split(delimiter).map(cell => cell.trim())
        );

        createTableFromUpload(headers, rows);
      };
      reader.readAsText(file);
    }
  };

  const createTableFromUpload = async (headers: string[], rows: string[][]) => {
    try {
      const newTable = await projectTableService.createTable(
        projectId,
        newTableName,
        rows.length,
        headers.length,
        currentUser
      );

      const updatedTable: TableData = {
        ...newTable,
        columnOrder: headers,
        data: rows.map(row => Object.fromEntries(headers.map((header, index) => [header, row[index] || '']))),
        columnTypes: Object.fromEntries(headers.map(header => [header, 'Text'])),
        columnWidths: Object.fromEntries(headers.map(header => [header, 150])),
      };

      await projectTableService.updateTable(newTable.tableId, updatedTable);

      setTables([...tables, updatedTable]);
      setIsNewTableDialogOpen(false);
      setNewTableName('');
      handleTableSelect(updatedTable);
      showSnackbar('Table created successfully', 'success');
    } catch (error) {
      console.error('Failed to create table:', error);
      showSnackbar('Failed to create table. Please try again.', 'error');
    }
  };

  const handleUpload = () => {
    if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
      handleFileUpload({ target: { files: fileInputRef.current.files } } as React.ChangeEvent<HTMLInputElement>);
    } else {
      showSnackbar('Please select a file to upload', 'error');
    }
  };

  const content = (
    <Box display="flex" sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <Collapse in={!isLeftColumnCollapsed} orientation="horizontal">
        <Box width={250} borderRight={1} borderColor="divider" height="100%" display="flex" flexDirection="column" flexShrink={0}>
          <Box p={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setIsNewTableDialogOpen(true)}
              sx={{ mb: 2, bgcolor: 'primary.dark', color: 'white', '&:hover': { bgcolor: 'primary.main' } }}
              fullWidth
            >
              Create New Table
            </Button>
          </Box>
          <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {tables.map(table => (
              <ListItem
                button
                key={table.tableId}
                selected={table.tableId === selectedTable?.tableId}
                onClick={() => handleTableSelect(table)}
                sx={{ '&:hover .table-actions': { opacity: 1 } }}
              >
                {editingTableName === table.tableId ? (
                  <TextField
                    value={table.tableName}
                    onChange={(e) => setTables(prevTables => prevTables.map(t => t.tableId === table.tableId ? { ...t, tableName: e.target.value } : t))}
                    onBlur={(e) => handleEditTableName(table.tableId, e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleEditTableName(table.tableId, (e.target as HTMLInputElement).value);
                      }
                    }}
                    autoFocus
                    fullWidth
                  />
                ) : (
                  <ListItemText primary={table.tableName} />
                )}
                <Box className="table-actions" sx={{ opacity: 0, transition: 'opacity 0.2s' }}>
                  <IconButton onClick={() => setEditingTableName(table.tableId)} size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteTable(table.tableId)} size="small">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      </Collapse>
      <Box flex={1} display="flex" flexDirection="column" height="100%" overflow="hidden">
        <Box display="flex" alignItems="center" p={2} borderBottom={1} borderColor="divider">
          <Box display="flex" alignItems="center" width="250px">
            <IconButton onClick={handleCollapseToggle} size="small" sx={{ mr: 1 }}>
              {isLeftColumnCollapsed ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
            <Typography variant="h6" noWrap>
              {selectedTable?.tableName || "No table selected"}
            </Typography>
          </Box>
          <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            {selectedTable && (
              <>
                <TextField
                  placeholder="Search table..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{ width: '450px', mr: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleSortMenuOpen} size="small">
                          <SortIcon />
                        </IconButton>
                        <IconButton onClick={handleDownload} size="small" title="Download as TSV">
                          <FileDownloadIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </Box>
          <Box width="250px" /> {/* This empty box balances the layout */}
        </Box>
        <Box flexGrow={1} overflow="hidden">
          {selectedTable ? (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                <TableComponent
                  tableData={{
                    ...selectedTable,
                    data: sortedRows,
                    columnOrder: selectedTable.columnOrder,
                    columnWidths: selectedTable.columnWidths,
                    columnTypes: selectedTable.columnTypes,
                    columnFormulas: selectedTable.columnFormulas,
                  }}
                  onUpdateTable={handleUpdateTable}
                  sortConfig={sortConfig}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, borderTop: 1, borderColor: 'divider' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (selectedTable) {
                      const updatedTable = { ...selectedTable };
                      updatedTable.data.push(Object.fromEntries(updatedTable.columnOrder.map(col => [col, ''])));
                      handleUpdateTable(updatedTable);
                    }
                  }}
                  sx={{ mr: 2 }}
                >
                  Add Row
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (selectedTable) {
                      const updatedTable = { ...selectedTable };
                      const newColumnId = `Column ${updatedTable.columnOrder.length + 1}`;
                      updatedTable.columnOrder.push(newColumnId);
                      updatedTable.data = updatedTable.data.map(row => ({ ...row, [newColumnId]: '' }));
                      updatedTable.columnWidths[newColumnId] = 150;
                      updatedTable.columnTypes[newColumnId] = 'Text';
                      handleUpdateTable(updatedTable);
                    }
                  }}
                >
                  Add Column
                </Button>
              </Box>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              {tables.length === 0 ? (
                <Button variant="contained" onClick={() => setIsNewTableDialogOpen(true)}>
                  Create your first table
                </Button>
              ) : (
                <p>Select a table from the list</p>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Dialog open={isNewTableDialogOpen} onClose={() => setIsNewTableDialogOpen(false)}>
        <DialogTitle>Create New Table</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Table Name"
            fullWidth
            value={newTableName}
            onChange={(e) => setNewTableName(e.target.value)}
          />
          <RadioGroup
            aria-label="new-table-method"
            name="new-table-method"
            value={newTableMethod}
            onChange={(e) => setNewTableMethod(e.target.value as 'upload' | 'create')}
          >
            <FormControlLabel value="upload" control={<Radio />} label="Upload File" />
            <FormControlLabel value="create" control={<Radio />} label="Create New" />
          </RadioGroup>
          {newTableMethod === 'upload' ? (
            <Button
              variant="contained"
              component="label"
              fullWidth
              sx={{ mt: 2 }}
            >
              Select File
              <input
                type="file"
                hidden
                accept=".csv,.tsv"
                ref={fileInputRef}
                onChange={(e) => {
                  setNewTableName(e.target.files?.[0]?.name.split('.')[0] || '');
                }}
              />
            </Button>
          ) : (
            <>
              <TextField
                margin="dense"
                label="Number of Columns"
                fullWidth
                type="number"
                value={newTableColumns}
                onChange={(e) => setNewTableColumns(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Number of Rows"
                fullWidth
                type="number"
                value={newTableRows}
                onChange={(e) => setNewTableRows(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewTableDialogOpen(false)}>Cancel</Button>
          <Button onClick={newTableMethod === 'create' ? handleCreateNewTable : handleUpload}>
            {newTableMethod === 'create' ? 'Create' : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );

  return content;
};

export default TableManager;