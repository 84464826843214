import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Card,
  CardContent,
  Autocomplete,
  Chip,
  FormControlLabel
} from '@mui/material';
import { Issue, User, Project, Task, ChipGroup, IssueFormData } from '../types';
import FileUploadArea from './FileUploadArea';
import FilePreview from './FilePreview';
import { Editor, initTinyMCE } from '../utils/tinymceSetup';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { fileService } from '../fileService';
import { notificationsService } from '../services/notificationsService';
import { getTextColor } from '../utils/colorUtils';

interface IssueFormProps {
  issue?: Partial<Issue>;
  onSubmit: (issue: IssueFormData, files: File[]) => Promise<Issue>;
  onCancel: () => void;
  users: User[];
  currentUser: User;
  projects: Project[];
  currentProjectId: string | null;
  allTags: string[];
  chipGroups: ChipGroup[];
}

const IssueForm: React.FC<IssueFormProps> = ({ 
  issue, 
  onSubmit, 
  onCancel, 
  users,
  currentUser,
  projects,
  currentProjectId,
  allTags,
  chipGroups
}) => {
  const [formData, setFormData] = useState<IssueFormData>({
    title: '',
    description: '',
    assignedTo: [],
    attachments: [],
    projectId: currentProjectId || 'default-project-id',
    tasks: [],
    tags: [],
    chipGroups: chipGroups.reduce((acc, group) => ({ ...acc, [group.id]: '' }), {}),
    isPrivate: false,
  });
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [newTask, setNewTask] = useState('');

  useEffect(() => {
    if (issue) {
      setFormData(prevState => ({
        ...prevState,
        ...issue,
        attachments: (issue.attachments || []) as string[],
        projectId: issue.projectId || currentProjectId || 'default-project-id',
        tasks: issue.tasks || [],
        tags: issue.tags || [],
        chipGroups: issue.chipGroups || {},
      }));
      setTasks(issue.tasks || []);
    } else {
      resetForm();
    }
  }, [issue, currentProjectId]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string | string[]>
  ) => {
    const { name, value } = e.target;
    console.log('Form field changed:', name, value);  // Add this line
    setFormData(prev => ({ 
      ...prev, 
      [name]: Array.isArray(value) ? value : value 
    }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleEditorChange = (content: string) => {
    setFormData(prev => ({ ...prev, description: content }));
    setErrors(prev => ({ ...prev, description: '' }));
  };

  const handleFileUpload = async (uploadedFiles: File[]) => {
    const newFiles = [...files, ...uploadedFiles];
    setFiles(newFiles);
  };

  const handleRemoveFile = async (index: number) => {
    const fileToRemove = files[index];
    if (fileToRemove instanceof File) {
      setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    } else {
      try {
        await fileService.deleteFile(fileToRemove as string);
        setFormData(prev => ({
          ...prev,
          attachments: prev.attachments.filter((_, i) => i !== index)
        }));
      } catch (error) {
        console.error('Error removing file:', error);
        // Handle error (e.g., show a snackbar)
      }
    }
  };

  const handleAddTask = () => {
    if (newTask.trim()) {
      setTasks(prev => [
        ...prev,
        {
          id: Date.now().toString(),
          text: newTask.trim(),
          completed: false,
          createdOn: new Date().toISOString(),
          completedOn: null,  // Change this from undefined to null
          editedAt: null  // Change this from undefined to null
        }
      ]);
      setNewTask('');
    }
  };

  const handleRemoveTask = (taskId: string) => {
    setTasks(prev => prev.filter(task => task.id !== taskId));
  };

  const handleToggleTask = (taskId: string) => {
    setTasks(prev => prev.map(task => 
      task.id === taskId ? { ...task, completed: !task.completed } : task
    ));
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    let isValid = true;

    console.log('Validating form data:', formData);

    if (!formData.title.trim()) {
      newErrors.title = 'Title is required';
      isValid = false;
      console.log('Title validation failed');
    }
    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
      isValid = false;
      console.log('Description validation failed');
    }
    
    // Check if all required chip groups are selected
    chipGroups.forEach(group => {
      if (!formData.chipGroups || !formData.chipGroups[group.id]) {
        newErrors[group.id] = `${group.name} is required`;
        isValid = false;
        console.log(`${group.name} validation failed`);
      }
    });

    setErrors(newErrors);
    console.log('Validation result:', isValid ? 'Valid' : 'Invalid', newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    // Add this block to use validateForm
    if (!validateForm()) {
      console.log('Form validation failed');
      return;
    }

    setIsSubmitting(true);

    try {
      const submissionData: IssueFormData = {
        title: formData.title,
        description: formData.description,
        assignedTo: formData.assignedTo,
        attachments: formData.attachments,
        projectId: formData.projectId,
        tasks: tasks.map(task => ({
          ...task,
          completedOn: task.completedOn || null,
          editedAt: task.editedAt || null
        })),
        tags: formData.tags,
        chipGroups: formData.chipGroups,
        createdBy: currentUser.userId,
        createdByName: `${currentUser.firstName} ${currentUser.lastName}`,
        isPrivate: formData.isPrivate,
      };

      console.log('Submitting issue form data:', submissionData);
      const issueId = issue?.id || `new-issue-${Date.now()}`;
      const uploadedFileUrls = await Promise.all(
        files.map(file => fileService.uploadFile(file, issueId))
      );

      const issueData: IssueFormData = {
        ...submissionData,
        attachments: [...submissionData.attachments, ...uploadedFileUrls],
        lastEditedBy: currentUser.userId || '',
        lastEditedByName: `${currentUser.firstName} ${currentUser.lastName}`,
        createdByName: `${currentUser.firstName} ${currentUser.lastName}`,
      };

      console.log('Prepared issue data for submission:', issueData);
      const createdIssue = await onSubmit(issueData, files);
      console.log('Issue created successfully:', createdIssue);

      // Create notification for new issue
      if (!issue) {
        await notificationsService.createIssueUpdateNotification(
          createdIssue.id,
          createdIssue.projectId,
          currentUser.userId || '',
          `${currentUser.firstName} ${currentUser.lastName}`,
          createdIssue.title,
          'New issue created'
        );
      }

      setIsSubmitting(false);
      setFiles([]);
      
      return createdIssue;
    } catch (error) {
      console.error('Error submitting form:', error);
      setIsSubmitting(false);
      throw error;
    }
  };

  const handleTagChange = (_: React.SyntheticEvent, newValue: string[]) => {
    setFormData(prev => ({ ...prev, tags: newValue }));
  };

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      assignedTo: [],
      attachments: [],
      projectId: currentProjectId || 'default-project-id',
      tasks: [],
      tags: [],
      chipGroups: chipGroups.reduce((acc, group) => ({ ...acc, [group.id]: '' }), {}),
      isPrivate: false,
    });
    setFiles([]);
    setTasks([]);
    setErrors({});
  };

  const activeProjects = projects.filter(project => !project.isArchived);

  const handleChipGroupChange = (groupId: string, chipId: string) => {
    console.log('Chip group changed:', groupId, chipId);
    setFormData(prev => ({
      ...prev,
      chipGroups: {
        ...(prev.chipGroups || {}),
        [groupId]: chipId
      }
    }));
  };

  // Add this useEffect to log formData changes
  useEffect(() => {
    console.log('FormData updated:', formData);
  }, [formData]);

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
          <Avatar src={currentUser.avatarUrl} alt={currentUser.firstName} sx={{ mr: 2 }} />
            <Box>
              <Typography variant="subtitle1">
                Created by: {`${currentUser.firstName} ${currentUser.lastName}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {new Date().toLocaleString()}
              </Typography>
            </Box>
          </Box>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="project-label">Project</InputLabel>
            <Select
              labelId="project-label"
              id="projectId"
              name="projectId"
              value={formData.projectId}
              onChange={handleChange}
              label="Project"
            >
              <MenuItem value="default-project-id">Default (No Project)</MenuItem>
              {activeProjects.filter(project => project.id !== 'default-project-id').map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="title"
            label="Title"
            value={formData.title}
            onChange={handleChange}
            required
            error={!!errors.title}
            helperText={errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Description</Typography>
          <Editor
            init={initTinyMCE}
            value={formData.description}
            onEditorChange={handleEditorChange}
          />
          {errors.description && (
            <Typography color="error" variant="caption">
              {errors.description}
            </Typography>
          )}
        </Grid>
        {chipGroups.map((group) => (
          <Grid item xs={4} key={group.id}>
            <FormControl fullWidth>
              <InputLabel id={`${group.id}-label`}>{group.name}</InputLabel>
              <Select
                labelId={`${group.id}-label`}
                id={group.id}
                name={group.id}
                value={formData.chipGroups?.[group.id] || ''}  // Use optional chaining here
                onChange={(e) => handleChipGroupChange(group.id, e.target.value as string)}
                label={group.name}
              >
                {group.chips.map((chip) => (
                  <MenuItem key={chip.id} value={chip.id}>
                    <Chip
                      label={chip.name}
                      style={{
                        backgroundColor: chip.color,
                        color: getTextColor(chip.color)
                      }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Attachments</Typography>
          <FileUploadArea onUpload={handleFileUpload} />
          <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
            {[...formData.attachments, ...files].map((file, index) => (
              <FilePreview
                key={index}
                file={file}
                onRemove={() => handleRemoveFile(index)}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={3} sx={{ mb: 2, bgcolor: 'white' }}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>Sub Tasks</Typography>
              <List>
                {tasks.map((task) => (
                  <ListItem
                    key={task.id}
                    disablePadding
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveTask(task.id)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      <DragIndicatorIcon />
                    </ListItemIcon>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={task.completed}
                        onChange={() => handleToggleTask(task.id)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={task.text}
                      sx={{ textDecoration: task.completed ? 'line-through' : 'none' }}
                    />
                  </ListItem>
                ))}
              </List>
              <Box display="flex" alignItems="center" mt={2}>
                <TextField
                  fullWidth
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                  placeholder="Type task"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddTask();
                    }
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddTask}
                  sx={{ ml: 1, whiteSpace: 'nowrap' }}
                >
                  Add Tasks
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="assigned-to-label">Assigned To</InputLabel>
            <Select
              labelId="assigned-to-label"
              id="assignedTo"
              name="assignedTo"
              multiple
              value={formData.assignedTo}
              onChange={handleChange}
              label="Assigned To"
            >
              {users.map((user) => (
                <MenuItem key={user.userId} value={user.userId}>
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="tags"
            options={allTags}
            freeSolo
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tags"
                placeholder="Add tags"
              />
            )}
            value={formData.tags}
            onChange={handleTagChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isPrivate}
                onChange={(e) => setFormData(prev => ({ ...prev, isPrivate: e.target.checked }))}
                name="isPrivate"
              />
            }
            label="Private (Only visible to creator)"
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button onClick={onCancel} variant="outlined" disabled={isSubmitting}>
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              disabled={isSubmitting}
              onClick={() => console.log('Submit button clicked')}  // Add this line
            >
              {isSubmitting ? 'Submitting...' : (issue ? 'Update Issue' : 'Create Issue')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IssueForm;
