import { auth, firestore, storage } from './firebase';
import { collection, doc, setDoc, getDocs, getDoc, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut,
  UserCredential,
  updatePassword,
  updateEmail,
} from 'firebase/auth';
import { User, Role, Permissions as UserPermissions } from './types';
import bcrypt from 'bcryptjs';

const usersCollection = collection(firestore, 'users');
const rolesCollection = collection(firestore, 'roles');

export const userService = {
  async createUser(user: Omit<User, 'userId'>, password: string): Promise<void> {
    try {
      const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, user.email, password);
      const uid = userCredential.user.uid;
      const hashedPassword = await bcrypt.hash(password, 10);
      await setDoc(doc(firestore, 'users', uid), { 
        ...user, 
        userId: uid, 
        hashedPassword,
        role: '', // Ensure role is blank for new users
        status: 'Pending', // Ensure status is 'Pending' for new users
        createdOn: new Date().toISOString(),
        modifiedOn: new Date().toISOString(),
      });
      // Sign out the user immediately after creation
      await signOut(auth);
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  },

  async login(email: string, password: string): Promise<User> {
    try {
      const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(firestore, 'users', userCredential.user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data() as User;
        if (userData.status === 'Pending' || userData.status === 'Inactive') {
          await signOut(auth); // Sign out the user if their account is not active
          throw new Error('Account is not active. Please contact an administrator.');
        }
        return userData;
      } else {
        throw new Error("User document not found");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  async logout(): Promise<void> {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out:", error);
      throw error;
    }
  },

  async getUserByEmail(email: string): Promise<User | null> {
    try {
      const q = query(usersCollection, where('email', '==', email));
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const userData = snapshot.docs[0].data() as User;
        return { ...userData, userId: snapshot.docs[0].id };
      }
      return null;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  },

  async updateUserData(userId: string, userData: Partial<User>): Promise<void> {
    try {
      const userRef = doc(firestore, 'users', userId);
      await updateDoc(userRef, { ...userData, modifiedOn: new Date().toISOString() });
    } catch (error) {
      console.error("Error updating user data:", error);
      throw error;
    }
  },

  async updateUserEmail(userId: string, newEmail: string): Promise<void> {
    try {
      const user = auth.currentUser;
      if (user) {
        await updateEmail(user, newEmail);
        const userRef = doc(firestore, 'users', userId);
        await updateDoc(userRef, { email: newEmail });
      } else {
        throw new Error("No authenticated user found");
      }
    } catch (error) {
      console.error('Error updating user email:', error);
      throw error;
    }
  },

  async updateUserPassword(email: string, currentPassword: string, newPassword: string): Promise<void> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("No authenticated user");

      // Re-authenticate user
      await signInWithEmailAndPassword(auth, email, currentPassword);
      
      // Update password
      await updatePassword(user, newPassword);

      // Update hashed password in Firestore
      const hashedPassword = await bcrypt.hash(newPassword, 10);
      const userRef = doc(firestore, 'users', user.uid);
      await updateDoc(userRef, { 
        hashedPassword: hashedPassword,
        modifiedOn: new Date().toISOString()
      });
    } catch (error) {
      console.error("Error updating password:", error);
      throw error;
    }
  },

  async resetUserPassword(userId: string, newPassword: string): Promise<void> {
    try {
      const userRecord = await this.getUserById(userId);
      
      if (!userRecord || !userRecord.email) {
        throw new Error("User not found or email is missing");
      }

      // Update hashed password in Firestore
      const hashedPassword = await bcrypt.hash(newPassword, 10);
      const userRef = doc(firestore, 'users', userId);
      await updateDoc(userRef, { 
        hashedPassword: hashedPassword,
        modifiedOn: new Date().toISOString()
      });

      // If the user is currently logged in, update their password in Firebase Auth as well
      const currentUser = auth.currentUser;
      if (currentUser && currentUser.email === userRecord.email) {
        await updatePassword(currentUser, newPassword);
      }

      console.log("Password reset successful");
    } catch (error) {
      console.error("Error resetting user password:", error);
      throw error;
    }
  },

  async getUserById(userId: string): Promise<User | null> {
    try {
      const userDoc = await getDoc(doc(firestore, 'users', userId));
      if (userDoc.exists()) {
        return { ...userDoc.data(), userId: userDoc.id } as User;
      }
      return null;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  },

  async getAllUsers(includeArchived: boolean = false): Promise<User[]> {
    try {
      let q;
      if (!includeArchived) {
        q = query(usersCollection, where('status', '!=', 'Archived'));
      } else {
        q = usersCollection;
      }
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ ...(doc.data() as User), userId: doc.id }));
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  },

  async archiveUser(userId: string): Promise<void> {
    try {
      const userRef = doc(firestore, 'users', userId);
      await updateDoc(userRef, { 
        status: 'Archived',
        modifiedOn: new Date().toISOString()
      });
    } catch (error) {
      console.error("Error archiving user:", error);
      throw error;
    }
  },

  async uploadAvatar(file: File): Promise<string> {
    try {
      const storageRef = ref(storage, `avatars/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      return await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error("Error uploading avatar:", error);
      throw error;
    }
  },

  async getAllRoles(): Promise<Role[]> {
    try {
      const snapshot = await getDocs(rolesCollection);
      return snapshot.docs.map(doc => ({ ...doc.data(), roleId: doc.id } as Role));
    } catch (error) {
      console.error("Error fetching roles:", error);
      throw error;
    }
  },

  async createRole(role: Omit<Role, 'roleId'>): Promise<Role> {
    try {
      const newRoleRef = doc(rolesCollection);
      await setDoc(newRoleRef, role);
      return { ...role, roleId: newRoleRef.id };
    } catch (error) {
      console.error("Error creating role:", error);
      throw error;
    }
  },
  

  async updateRole(roleId: string, roleData: Partial<Role>): Promise<void> {
    try {
      const roleRef = doc(firestore, 'roles', roleId);
      await updateDoc(roleRef, roleData);
      
      // Update permissions for all users with this role
      if (roleData.defaultPermissions) {
        await this.updateUsersWithRole(roleId, roleData.defaultPermissions);
      }
    } catch (error) {
      console.error("Error updating role:", error);
      throw error;
    }
  },

  async updateUsersWithRole(roleId: string, newPermissions: UserPermissions): Promise<void> {
    try {
      const q = query(usersCollection, where('role', '==', roleId));
      const snapshot = await getDocs(q);
      
      const updatePromises = snapshot.docs.map(async (userDoc) => {
        const userRef = doc(firestore, 'users', userDoc.id);
        await updateDoc(userRef, { 
          permissions: newPermissions,
          modifiedOn: new Date().toISOString()
        });
      });

      await Promise.all(updatePromises);
    } catch (error) {
      console.error("Error updating users with role:", error);
      throw error;
    }
  },

  async deleteRole(roleId: string): Promise<void> {
    try {
      await deleteDoc(doc(firestore, 'roles', roleId));
    } catch (error) {
      console.error("Error deleting role:", error);
      throw error;
    }
  },

  async getPendingUsers(): Promise<User[]> {
    try {
      const q = query(usersCollection, where('status', '==', 'Pending'));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ ...doc.data(), userId: doc.id } as User));
    } catch (error) {
      console.error("Error fetching pending users:", error);
      throw error;
    }
  },
  async approveUser(userId: string, role: string): Promise<void> {
    try {
      const userRef = doc(firestore, 'users', userId);
      await updateDoc(userRef, { 
        status: 'Active',
        role: role,
        modifiedOn: new Date().toISOString()
      });
    } catch (error) {
      console.error("Error approving user:", error);
      throw error;
    }
  },

  async getPendingUsersCount(): Promise<number> {
    try {
      const q = query(usersCollection, where('status', '==', 'Pending'));
      const snapshot = await getDocs(q);
      return snapshot.size;
    } catch (error) {
      console.error("Error fetching pending users count:", error);
      throw error;
    }
  },

  async getWhatsNewContent(): Promise<string> {
    try {
      const whatsNewDoc = await getDoc(doc(firestore, 'settings', 'whatsNew'));
      return whatsNewDoc.exists() ? whatsNewDoc.data().content : '';
    } catch (error) {
      console.error('Error fetching What\'s New content:', error);
      throw error;
    }
  },

  async updateWhatsNewContent(content: string): Promise<void> {
    try {
      await setDoc(doc(firestore, 'settings', 'whatsNew'), { content });
    } catch (error) {
      console.error('Error updating What\'s New content:', error);
      throw error;
    }
  },

  async getUserName(userId: string): Promise<string> {
    try {
      const userDoc = await getDoc(doc(firestore, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data() as User;
        return userData.firstName || 'Unknown User'; // Changed from userData.name to userData.firstName
      }
      return 'Unknown User';
    } catch (error) {
      console.error("Error fetching user name:", error);
      return 'Unknown User';
    }
  },
};

export default userService;